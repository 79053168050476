<div class="setheight">
    <!-- <h5>
        Manage Version
    </h5> -->

<div class="version-details-content">
    <nz-list nzHeader="{{data.documentTitle}}">
        <nz-list-item class="d-flex justify-content-between align-items-center mb-4" *ngFor="let version of documentVersionsArr; let first = first"> 
            <div class="d-flex align-items-center">
                <div class="thumbImg">
                    <img *ngIf="!USE_AWS_S3"
                    id="thumb_{{version.version_id}}"
                    src="{{apiUrl}}/document/getThumb/{{version.created_by}}/{{version.document_id}}/{{version.version_id}}">
                </div>
                <h5 class="top-front ml-3 setContent mt-4">Version {{version.version_no}}
                    <p><small>{{version.created_on * 1000 | date:'dd MMM yyyy HH:mm'}}</small></p>
                </h5>
            </div>        
            
            <!-- <span class="top-front">{{formatBytes(version.file_size)}}</span> -->
    <div>
        <ul nz-list-item-actions class="d-flex align-items-center mb-0">
            <nz-list-item-action>
                <div class="d-flex align-items-center">
                    <h5 class="mr-3 mb-0">{{formatBytes(version.file_size)}}</h5>
                    <button type="button" class="ant-btn action-btn " *ngIf="version.file_size!=0 && !version.cancelDownload" (click)="downloadElement(version);">
                        <i class="fe fe-download font-size-18 p-1"></i>
                    </button>
                    <div class="w-10" >
                        <span nz-tooltip *ngIf="version.cancelDownload" nzTooltipTitle="Cancel download" nzTooltipPlacement="bottom" (click)="cancelDownload(version)"  class="cursor-pointer cancel-download-icon"><i class="fe fe-x font-size-18 p-1"></i></span>
                    </div>
                </div>
                <div class="w-90">
                    <nz-progress *ngIf="version.progressValue>0 && version.cancelDownload" nzStrokeColor="#4b7cf3" [nzPercent]="version.progressValue" [nzShowInfo]="false"></nz-progress> 
                </div>
            </nz-list-item-action>
            <nz-list-item-action *ngIf="!first">
              
                    <button type="button" 
                    class="ant-btn action-btn" 
                    [ngClass]="{'opacity':version.is_active==0}"
                    [disabled]="version.is_active==0"
                    (click)="version.is_active!==0 && deleteVersion(version);" 
                    [style.color]="version.is_active==0?'red':''"
                    nzTooltipTitle="version.is_active == 0 ? 'Version disabled' : 'Disable this version'">
                        <i class="fe fe-trash font-size-18 p-1"></i>
                </button>
              
            </nz-list-item-action>
        </ul>
    </div>
            
            
        </nz-list-item>
        <div class="d-flex w-100">
            
           
        </div>

    </nz-list>
</div>

<div class="text-right mt-3">
    <button type="button" class="ant-btn ant-btn-secondary mb-3" (click)="modal.destroy(false)">Cancel</button>
</div>
</div>