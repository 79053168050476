<!-- <div>
    <h5>
        Change Status
    </h5>
</div> -->
<div class="mt-0">
    <nz-select class="w-100" [(ngModel)]="selectedWorkflowStatus" [ngModelOptions]="{standalone: true}"  >
        <nz-option *ngFor="let status of workflowStatus;" [nzValue]="status" [nzLabel]="status.status_name"></nz-option>
    </nz-select>
</div>

<div class="text-right mt-3">
    <button mat-stroked-button class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
    <button class="ant-btn ant-btn-primary" (window:keypress)="onChangeAction($event)" (click)="modal.destroy(selectedWorkflowStatus)"
        [disabled]="!selectedWorkflowStatus">Change</button>
</div>

