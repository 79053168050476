import { Component, Input, OnInit, Inject } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { GlobalService } from 'src/app/services/common/global.service'
import { DataService } from 'src/app/services/data.service'
import { FolderService } from 'src/app/services/folder.service'
import { environment } from '../../../../../environments/environment'
import { DocumentService } from 'src/app/services/document.service'
import { RemoveFileComponent } from '../remove-file/remove-file.component'
import { NzModalService } from 'ng-zorro-antd/modal'
import { DownloadService } from 'src/app/services/common/download.service'

@Component({
  selector: 'qr-version-details',
  templateUrl: './version-details.component.html',
  styleUrls: ['./version-details.component.scss'],
})
export class VersionDetailsComponent implements OnInit {
  @Input() data: any
  apiUrl: string = environment.apiUrl
  USE_AWS_S3: boolean = environment.useawss3
  documentVersionsArr: any = []
  progressValue: number = 0
  cancelDownloading = false
  showCancelDownload = false
  controller = new AbortController()
  signal: any = this.controller.signal
  constructor(
    public modal: NzModalRef,
    private _folderService: FolderService,
    private globalService: GlobalService,
    private notification: NzNotificationService,
    private dataService: DataService,
    private _docService: DocumentService,
    private modalService: NzModalService,
    private downloadService: DownloadService,
  ) { }

  ngOnInit() {
    this.globalService.documentVersionsArr.subscribe(
      documentVersionsArr => (this.documentVersionsArr = documentVersionsArr),
    )
  }

  submitName() {
    if (!this.data.name) {
      return
    }
    const folderObj = {
      folderId: 0,
      folderName: this.data.name,
      isWorkspace: 0,
      workspaceId: this.data.type === 'workspace' ? 1 : 0,
    }

    let res: any = this._folderService.mkFolder(folderObj)
    if (this.data.type === 'workspace') {
      this.globalService.newFolderAdded.next(res)
    } else {
      this.globalService.newDocumentAdded.next(res)
    }
    this.modal.destroy(true)
    const message = this.data.type === 'workspace' ? 'Workspace Created.' : 'Folder Created.'
    this.notification.create('success', null, message, { nzPlacement: 'bottomRight' });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  async downloadElement(element: any) {
    let index = this.documentVersionsArr.findIndex(id => id.version_id == element.version_id)
    if (index > -1) this.documentVersionsArr[index].cancelDownload = true
    this.downloadService.downloadElementVesion(element)
  }

  cancelDownload(element: any) {
    let index = this.documentVersionsArr.findIndex(id => id.version_id == element.version_id)
    if (index > -1) this.documentVersionsArr[index].cancelDownload = false
    this.downloadService.cancelDownloadVersion(element)
  }

  deleteVersion(element: any) {
    const addWorkspaceFolderRef = this.modalService.create({
      nzTitle: null,
      nzContent: RemoveFileComponent,
      nzComponentParams: {
        data: {
          title: 'Disable Version',
          content: `This is an irreversible action. Are you sure you want to disable this version?\n\nVersions can only be disabled and not deleted. If you disable this version, it will still be listed but inaccessible.`,
        },
      },
      nzFooter: null,
      nzWidth: 500,
    })
    addWorkspaceFolderRef.afterClose.subscribe(result => {
      if (result) {
        let docObj = { versionId: element.version_id }
        this._docService.deleteVersion(docObj).subscribe(
          res => {
            this.documentVersionsArr.map(v => {
              if (element.version_id == v.version_id) return (v.is_active = 0)
            })
            // this.snackBar.open(res.status, '', { duration: 3000 });
            this.notification.create('success', null, res.status, { nzPlacement: 'bottomRight' })
          },
          err => {
            console.log(err)
          },
        )
      }
    })
  }
}
