import { NgModule } from '@angular/core'
import { SharedModule } from '../shared.module'
// import { ComponentsModule } from '../@vb/components/components.module'

import { LayoutAuthComponent } from './Auth/auth.component'
import { LayoutMainComponent } from './Main/main.component'
import { LayoutPublicComponent } from './Public/public.component'
import { NotificationModalComponent } from '../pages/notification-modal/notification-modal.component'
import { VariantsComponent } from './Main/layout-components/variants/variants.component'
import { SidebarComponent } from './Main/layout-components/sidebar/sidebar.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { MenuLeftComponent } from './Main/layout-components/MenuClassic/menu-left/menu-left.component'
import { MenuTopComponent } from './Main/layout-components/MenuClassic/menu-top/menu-top.component'
import { FileUploadModule } from 'ng2-file-upload'
import { FlyoutMenuTopComponent } from './Main/layout-components/MenuFlyout/menu-top/menu-top.component'
import { FlyoutMenuLeftComponent } from './Main/layout-components/MenuFlyout/menu-left/menu-left.component'
import { TopbarComponent } from './Main/layout-components/topbar/topbar.component'
import { TopbarSearchComponent } from './Main/layout-components/topbar/topbar-components/topbar-search/topbar-search.component'
import { TopbarActionsComponent } from './Main/layout-components/topbar/topbar-components/topbar-actions/topbar-actions.component'
import { TopbarLanguageSwitcherComponent } from './Main/layout-components/topbar/topbar-components/topbar-language-switcher/topbar-language-switcher.component'
import { TopbarUserMenuComponent } from './Main/layout-components/topbar/topbar-components/topbar-user-menu/topbar-user-menu.component'
import { AnnouncementComponent } from './Main/layout-components/topbar/topbar-components/announcement/announcement.component'
import { BreadcrumbsComponent } from './Main/layout-components/breadcrumbs/breadcrumbs.component'
import { MaterialModule } from '../material.module'

const COMPONENTS = [LayoutAuthComponent, LayoutMainComponent, LayoutPublicComponent]

@NgModule({
  imports: [
    SharedModule,
    // ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    FileUploadModule,
    MaterialModule,
  ],
  declarations: [
    ...COMPONENTS,
    NotificationModalComponent,
    VariantsComponent,
    SidebarComponent,
    MenuLeftComponent,
    MenuTopComponent,
    FlyoutMenuTopComponent,
    FlyoutMenuLeftComponent,
    TopbarComponent,
    TopbarSearchComponent,
    TopbarActionsComponent,
    TopbarLanguageSwitcherComponent,
    TopbarUserMenuComponent,
    AnnouncementComponent,
    BreadcrumbsComponent,
  ],
  exports: [...COMPONENTS],
})
export class LayoutsModule {}
