import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'

// basic acl
// import { ACLComponent } from 'src/app/@vb/components/ACL/acl.component'

// antd components module
import { AntdModule } from 'src/app/antd.module'
import { LoaderComponent } from './pages/loader/loader.component'
import { UpgradeStorageDialogComponent } from './pages/upgrade-storage-dialog/upgrade-storage-dialog.component'
import { UserListComponent } from './pages/user-list/user-list.component'
import { FolderTreeComponent } from './pages/folder-tree/folder-tree.component'
import { StorageComponent } from './pages/storage/storage.component'
import { FormsModule } from '@angular/forms'
import { TreeModule } from '@circlon/angular-tree-component'
import { PipeModule } from './Pipe/pipe/pipe.module'
import { MaterialModule } from './material.module'

const MODULES = [
  CommonModule,
  RouterModule,
  AntdModule,
  TranslateModule,
  FormsModule,
  TreeModule,
  PipeModule,
  MaterialModule
]
export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'DD MMM YYYY HH:mm',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
}
@NgModule({
  imports: [...MODULES],
  declarations: [
    LoaderComponent,
    UserListComponent,
    UpgradeStorageDialogComponent,
    FolderTreeComponent,
    StorageComponent
  ],
  exports: [
    ...MODULES,
    LoaderComponent,
    UserListComponent,
    UpgradeStorageDialogComponent,
    FolderTreeComponent,
    StorageComponent
 
  ],
})
export class SharedModule {}
