import { Component, HostListener, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import qs from 'qs'
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
  RouterOutlet,
} from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter, map, mergeMap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import store from 'store'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'

import english from './locales/en-US'
import french from './locales/fr-FR'
import russian from './locales/ru-RU'
import chinese from './locales/zh-CN'
import { GlobalService } from './services/common/global.service'
import { environment } from 'src/environments/environment'
import { slideFadeinRight } from './layouts/router-animations'
import { AuthService } from './services/common/auth.service'
import { ThemeService } from './services/theme.service'
import { DataService } from './services/data.service'

const locales = {
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
}

@Component({
  selector: 'app-root',
  template: `
    <div [@slideFadeinRight]="routeAnimation(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
      <div></div>
    </div>
  `,
  animations: [slideFadeinRight],
})
export class AppComponent implements OnInit {
  _locale: String
  _theme: String
  _version: String
  logo: String
  pageTitle: String = ''
  firstTouch: boolean = false
  isCustomUrl: boolean = false
  isCustomUrlOwner: boolean = true
  docInfo: Array<any>;
  teamName = ''

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private store: Store<any>,
    translate: TranslateService,
    private globalService: GlobalService,
    private _authService: AuthService,
    private _themeService: ThemeService,
    private _dataService:  DataService
  ) {
    Object.keys(locales).forEach(locale => {
      translate.setTranslation(locale, locales[locale])
    })
    translate.setDefaultLang('en-US')

    // localization && theme listener
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      if (this._locale !== state.locale) {
        translate.use(state.locale)
      }
      if (this._theme !== state.theme) {
        this.setTheme(state.theme)
      }
      if (this._version !== state.version) {
        this.setVersion(state.version)
      }
      this._locale = state.locale
      this._theme = state.theme
      this.logo = state.logo
      // this.setTitle()
    })
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  //Used for iframing this app.. from here {
  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (!e.origin || !e.data || !e.data.message) return
    if (e.origin.indexOf('quickreviewer.com') > 0) {
      return false
    }
    if (e.data.message == 'iFramed' || e.data.message == 'getToken') {
      this.globalService.iFramed = true;
      window.parent.postMessage(
        { iFramed: localStorage.getItem('qrtoken'), userId: localStorage.getItem('userId') },
        '*',
      )
      if (window.location.href.indexOf('/dashboard/documents') > -1)
        window.parent.postMessage({ selectedFolder: { folder_id: 0 } }, '*')
    } else if (e.data.message == 'newUploaded') {
      this.docInfo.splice(0, 0, e.data.data)
      this.globalService.docInfo.next(this.docInfo)
    } else if (e.data.message == 'versionUploaded') {
      let docInfoIndex = this.docInfo.findIndex(v => {
        return v.documentId == e.data.data.documentId
      })
      this.docInfo.splice(docInfoIndex, 1, e.data.data)
      this.globalService.docInfo.next(this.docInfo)
    } else if (e.data.message == 'reload') {
      location.reload()
    }
  }
  //till here }

  routeAnimation(outlet: RouterOutlet) {
    return outlet.isActivated && outlet.activatedRoute.routeConfig.path
  }

  ngOnInit() {
    
    this.globalService.isCustomUrl.next(true)
    // if (environment.notChangeLoginPageDomain.length > 0) {
    //   // environment.notChangeLoginPageDomain.forEach(element => {
    //   //   if (window.location.host.includes(element)) {
    //   //     this.globalService.isCustomUrl.next(false)
    //   //   }
    //   // })
    //   if(environment.notChangeLoginPageDomain.indexOf(window.location.host) != -1) {
    //     this.globalService.isCustomUrl.next(false);
    //   }
    // } else {
      if (
        window.location.host.includes('localhost') ||
        window.location.host.includes('quickreviewer.com') ||
        window.location.host.includes('clavistechco.com')
      ) {
        this.globalService.isCustomUrl.next(false)
      }
    // }
    this.globalService.isCustomUrl.subscribe(isCustomUrl => {
      this.isCustomUrl = isCustomUrl
    })
    this.globalService.teamName.subscribe(teamName => {
      this.teamName = teamName
    })
    if(!this.isCustomUrl && window.location.host.includes('.quickreviewer.com') && environment.AppUrl.indexOf(window.location.host) == -1) {
      this.teamName = window.location.host.split('.quickreviewer.com')[0]
      this.globalService.teamName.next(this.teamName)
    } else if(!this.isCustomUrl && window.location.host.includes('.clavistechco.com') && environment.AppUrl.indexOf(window.location.host) == -1) {
      this.teamName = window.location.host.split('.clavistechco.com')[0]
      this.globalService.teamName.next(this.teamName)
    }
    this.getAppSettings()
    this.globalService.updateAppSettings.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.getAppSettings()
      }
    })
    this.globalService.updateUserPermission.subscribe(isTrue => {
      if (isTrue) {
        this._authService.getUserPermission().subscribe(servicesData => {
          this.globalService.userPermission.next(servicesData)
        })
      }
    })
    this.globalService.isCustomUrlOwner.subscribe(isCustomUrlOwner => {
      this.isCustomUrlOwner = isCustomUrlOwner
    })
    // set page title from router data variable
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        this.pageTitle = event['title']
        // this.setTitle()
      })

    // listen url query params and set them to ngrx store
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const queryString = event.url.match(/\?(.*)/)
        if (queryString) {
          const queryParams = qs.parse(queryString[1])
          const keys = Object.keys(queryParams)
          if (keys.length) {
            keys.forEach(key => {
              let value
              switch (queryParams[key]) {
                case 'false':
                  value = false
                  break
                case 'true':
                  value = true
                  break
                default:
                  value = queryParams[key]
                  break
              }
              this.store.dispatch(
                new SettingsActions.SetStateAction({
                  [key]: value,
                }),
              )
            })
          }
        }
      })

    // detecting & set mobile/tablet/desktop viewports
    const setViewPort = (isMobileView: any = false, isTabletView: any = false) => {
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          isMobileView,
        }),
      )
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          isTabletView,
        }),
      )
    }
    const detectViewPort = (load = false) => {
      const _isMobileView = window.innerWidth < 768
      const _isTabletView = window.innerWidth < 992
      const _isDesktopView = !_isMobileView && !_isTabletView
      const isMobileView = store.get('app.settings.isMobileView')
      const isTabletView = store.get('app.settings.isTabletView')
      const isDesktopView = !isMobileView && !isTabletView
      if (_isDesktopView && (_isDesktopView !== isDesktopView || load)) {
        setViewPort(false, false)
      }
      if (_isTabletView && !_isMobileView && (_isTabletView !== isTabletView || load)) {
        setViewPort(false, true)
        this.store.dispatch(
          new SettingsActions.SetStateAction({
            isMenuCollapsed: true,
          }),
        )
      }
      if (_isMobileView && (_isMobileView !== isMobileView || load)) {
        setViewPort(true, false)
      }
    }
    detectViewPort(true)
    window.addEventListener('resize', () => {
      detectViewPort()
    })

    // set primary color on app load
    const primaryColor = () => {
      const color = store.get('app.settings.primaryColor')
      if (color) {
        const addStyles = () => {
          const styleElement = document.querySelector('#primaryColor')
          if (styleElement) {
            styleElement.remove()
          }
          const body = document.querySelector('body')
          const styleEl = document.createElement('style')
          const css = document.createTextNode(`:root { --vb-color-primary: ${color};}`)
          styleEl.setAttribute('id', 'primaryColor')
          styleEl.appendChild(css)
          body.appendChild(styleEl)
        }
        addStyles()
        this.store.dispatch(
          new SettingsActions.SetStateAction({
            primaryColor: color,
          }),
        )
      }
    }
    primaryColor()
    this.globalService.docInfo.subscribe(docInfo => {
      this.docInfo = docInfo
    })
  }

  // set title
  // setTitle = () => {
  //   console.log(this.pageTitle, this.logo)

  //   this.titleService.setTitle(`${this.logo} | ${this.pageTitle}`)
  // }

  // set version
  setVersion = version => {
    document.querySelector('html').setAttribute('data-vb-version', version)
  }

  // set theme
  setTheme = theme => {
    // if (this.firstTouch) {
    document.querySelector('html').setAttribute('data-vb-theme', theme)
    if (theme === 'default') {
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          menuColor: 'white',
        }),
      )
    }
    if (theme === 'dark') {
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          menuColor: 'dark',
        }),
      )
    }
    // }
    this.firstTouch = true
  }

  getAppSettings() {
    // if (this.isCustomUrl) {
      this._authService.getAppSettings({ cname: ((this.isCustomUrl) ? window.location.host : window.location.host), team: this.teamName }).subscribe(
        setting => {
          this.globalService.customAppSetting.next(setting)
          if((setting.appUrlType == 'team' || setting.appUrlType == 'cname') && (setting.loginType == "team member" || setting.loginType == "reviewer" || setting.loginType == null)) {
            this.globalService.isTeamUrl.next(true)
            this.globalService.isReviewer.next((setting.loginType == 'reviewer' ? true : false))
          } else {
            this.globalService.isTeamUrl.next(false)
            this.globalService.isReviewer.next(false)
          }
          this.globalService.teamAllowedSpace.next(setting.allowedSpace)
          if((setting.appUrlType == 'team' || setting.appUrlType == 'cname') && (setting.appUrlOwnerId == 0 || setting.appUrlOwnerId == null)) {
            this._dataService.showAlertMessage(
              'Subdomain not valid',
              'file',
              'This subdomain is not valid. It may have changed. You will be redirected to the default domain in 5 seconds.',
            )
            setTimeout(function() {
              window.location.href = "https://app.quickreviewer.com/#/auth/login"
            }, 5000)
            
          }
          this._themeService.updateTheme(setting)
          // if (
          //   setting.site_name &&
          //   setting.site_name != undefined &&
          //   setting.site_name != null &&
          //   setting.site_name != 'null' &&
          //   setting.site_name != 'undefined'
          // ) {
          //   // this.titleService.setTitle(setting.site_name)
          // }

          if (setting.authZero != '' && setting.authZero != null) {
            localStorage.setItem('auth0', JSON.stringify(setting.authZero))
          }
          // if (localStorage.userId != undefined && !this.isCustomUrl && this.teamName!='') {
          //   this._authService.getSetting().subscribe(userSetting => {
          //     this.globalService.userSetting.next(userSetting)
          //     if (userSetting && userSetting.user_id == setting.user_id) {
          //       this.globalService.isCustomUrlOwner.next(true)
          //     } else {
          //       this.globalService.isCustomUrlOwner.next(false)
          //     }
          //   })
          // }
        },
        err => {
          console.log(err)
          this.titleService.setTitle('QuickReviewer')
        },
      )
    // } else {
    //   this._authService.getAppSettings({ cname: '', team: this.teamName }).subscribe(setting => {
    //     this.globalService.customAppSetting.next(setting)
    //     if(setting.appUrlType == 'team' && (setting.loginType == "team member" || setting.loginType == null)) {
    //       this.globalService.isTeamUrl.next(true)
    //     } else {
    //       this.globalService.isTeamUrl.next(false)
    //     }
    //     this.globalService.teamAllowedSpace.next(setting.allowedSpace)
    //     if(setting.appUrlType == 'team' && setting.appUrlOwnerId == 0) {
    //       window.location.href = "https://app.quickreviewer.com/#/auth/login"
    //     }
    //   })
    //   this.globalService.isCustomUrlOwner.next(true)
    // }
  }
}
