import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'

// import { WidgetsComponentsModule } from 'src/app/@vb/widgets/widgets-components.module'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'
import { AuthGuard } from './guards/auth.guard'
import { LayoutPublicComponent } from './layouts/Public/public.component'
import { OktaAuthGuard } from '@okta/okta-angular'
import { SamloktaComponent } from './layouts/samlokta/samlokta.component'

// pages
// VB:REPLACE-START:ROUTER-IMPORTS
// import { DashboardComponent } from './pages/dashboard/dashboard.component'

// VB:REPLACE-END:ROUTER-IMPORTS

const routes: Routes = [
  { path: 'login', redirectTo: '/auth/login' },
  { path: 'signup', redirectTo: '/auth/signup' },
  { path: 'register', redirectTo: '/auth/register' },
  { path: 'forgot-password', redirectTo: '/auth/forgot-password' },
  { path: 'reset-password/:token', redirectTo: '/auth/reset-password/:token' },
  { path: 'proof/:token', redirectTo: '/auth/proof/:token' },

  { path: '', redirectTo: '/dashboard/documents', pathMatch: 'full' },
  { path: 'saml', component: SamloktaComponent, },
  {
    path: 'dashboard',
    component: LayoutMainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
    ],
  },
  // {
  //   path: 'youtube',
  //   component: LayoutMainComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./pages/youtube/youtube.module').then(m => m.YoutubeModule),
  //     },
  //   ],
  // },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: 'folder',
    component: LayoutPublicComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/folder/folder.module').then(m => m.FolderModule),
      },
    ],
  },
  {
    path: 'reviewer',
    component: LayoutPublicComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/reviewer/reviewer.module').then(m => m.ReviewerModule),
      },
    ],
  },
  {
    path: 'workflow',
    component: LayoutMainComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/workflow/workflow.module').then(m => m.WorkflowModule),
      },
    ],
  },
  // { path: 'login', loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule), },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
    }),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: AppPreloader
}),
    LayoutsModule,
    // WidgetsComponentsModule,
  ],
  declarations: [
    // VB:REPLACE-START:ROUTER-DECLARATIONS
    // DashboardComponent,
    // VB:REPLACE-END:ROUTER-DECLARATIONS
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {}
