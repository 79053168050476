<div class="mt-0">
    <nz-select class="w-100" [(ngModel)]="wfValue">
        <nz-option nzValue="none" nzDisabled="true" nzLabel="Select workflow"></nz-option>
        <nz-option *ngFor="let data of workflowList;" [nzDisabled]="wfValue==data.id" [nzValue]="data.id" [nzLabel]="data.name"></nz-option>
    </nz-select>
</div>
<div class="text-right mt-3">
<button *ngIf="wfValue !== 'none'" type="button"  class="ant-btn ant-btn-dangerous mr-3"  (click)="modal.destroy({workflowId : wfValue})">Remove</button>
    <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
    <button type="button"  class="ant-btn ant-btn-primary"  (click)="modal.destroy(wfValue)">Apply</button>
</div>