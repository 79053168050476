import { Component, Input, OnInit } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { GlobalService } from 'src/app/services/common/global.service'
import { DocumentService } from 'src/app/services/document.service'
import { RemoveFileComponent } from '../remove-file/remove-file.component'

@Component({
  selector: 'qr-create-webproof',
  templateUrl: './create-webproof.component.html',
  styleUrls: ['./create-webproof.component.scss'],
})
export class CreateWebproofComponent implements OnInit {
  @Input() data: { value: string }
  selected: string = 'http://'
  urlInvalid: boolean = false
  urlhasprotocol: boolean = false
  urlResponseError: boolean = false
  processing: boolean = false
  selectedFolderObj: any
  docInfo: Array<any>
  webProof: string
  isDisabled = false
  constructor(
    private _docService: DocumentService,
    private globalService: GlobalService,
    private notification: NzNotificationService,
    private modalService: NzModalService,
    public modal: NzModalRef,
  ) {}

  ngOnInit(): void {
    this.globalService.selectedFolderObj.subscribe(selectedFolderObj => {
      this.selectedFolderObj = selectedFolderObj
    })
    this.globalService.docInfo.subscribe(docInfo => {
      this.docInfo = docInfo
    })
  }

  createWebproof() {
    if (this.isDisabled) {
      return
    }
    this.globalService.showLoader.next(true)
    this.urlInvalid = false
    this.urlResponseError = false
    this.urlhasprotocol = false
    this.processing = true
    this.isDisabled = true

    const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
    const regex = new RegExp(expression)

    if (!this.webProof) {
      this.urlInvalid = true
      this.processing = false
      return
    }

    if (this.webProof.match(/https?:\/\//)) {
      this.urlhasprotocol = true
      this.processing = false
      this.globalService.showLoader.next(false)
      return
    }
    if (!this.webProof.match(regex)) {
      // this.urlInvalid = true;
      // this.processing = false;
      // return;
    }

    const docObj = { urltocheck: this.data.value + this.webProof }
    this._docService.getUrlExists(docObj).subscribe(async response => {
      this.globalService.showLoader.next(false)
      if (!response) {
        const removeWorkspaceFolderRef = this.modalService.create({
          nzTitle: null,
          nzMaskClosable: false,
          nzClosable: false,
          nzFooter: null,
          nzContent: RemoveFileComponent,
          nzComponentParams: {
            data: {
              title: 'Attention',
              content: `This link did not return a response. \n\n This could be due to settings on the URL that may prevent us from pinging the URL. \n\n If you are sure the link is correct, you can continue and the link will still be processed but there will be no preview.`,
            },
          },
        })

        removeWorkspaceFolderRef.afterClose.toPromise().then(result => {
          if (!result || result.cancel) {
            this.processing = false
            return
          } else {
            this.processing = true
            this.processWebproof(this.webProof, false)
          }
          return Promise.resolve(result) // will return a Promise here
        })
      } else {
        this.processWebproof(this.webProof, true)
      }
    })
  }

  processWebproof(urltocheck, generatePreview) {
    const webproofObj = {
      folderId: this.selectedFolderObj.folderId,
      documentContentType: 'webproof',
      documentType: 'webproof',
      documentTitle: this.data.value + urltocheck,
      documentUrl: this.data.value + urltocheck,
      uploadSource: '',
      workspaceId: this.selectedFolderObj.workspaceId,
      versionNo: 1,
      generatePreview: generatePreview,
    }

    this.processing = true
    this.modal.destroy(true)
    this._docService.createWeblink(webproofObj).subscribe(
      async res => {
        this.docInfo.splice(0, 0, res[0])
        this.globalService.docInfo.next(this.docInfo)
        this.notification.create('success', null, 'Website created.', {
          nzPlacement: 'bottomRight',
        })
      },
      err => {
        this.urlResponseError = true
        this.processing = false
      },
    )
  }
}
