<ul class="treeComponent list-unstyled">
  <li>
    <span class="has-child">
      <span (click)="reset()" (contextmenu)="openContextDialog($event, menuContext); selectedFolder = 'all'" class="w-auto all-docs" >My Documents</span>
      <span class="ml-auto mw-auto">
        <ng-template #indicatorTemplate>
          <div role="status" class="spinner-border text-primary"><span class="sr-only">Loading...</span></div>
      </ng-template>
      <nz-spin *ngIf="menuLoader" style="display: inline-block; position: relative; top: -4px;" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
        <i *ngIf="menus.length" data-bs-target="#menu1" data-bs-toggle="collapse" 
        (click)="collapsingMenu($event, {id:0, name:'My Documents'})"
        [attr.aria-expanded]="isDocsOpened ? 'true' : 'false'" class="fe fe-chevron-down" aria-hidden="true"></i>
        <i class="fe fe-folder ml-1"></i>
      </span>
    </span>
    <ul *ngIf="menus.length" class="collapse list-unstyled" id="menu1" [ngClass]="{'show':isDocsOpened}">
      <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"></ng-container>
      <ng-template #menuTpl let-menus>
        <ng-container *ngFor="let menu of menus">
          <li *ngIf="!menu.children">
            <span 
            (contextmenu)="openContextDialog($event, menuContext); selectedFolder = menu" 
            [ngClass]="{'active': selectedFolderObj?.folderId == menu.id, 'approved':menu.isAllApproved, 'rejected':menu.isAllRejected, 'mixed':menu.isMixedStaus && (!menu.isAllRejected  || !menu.isAllApproved)}" 
            class="d-block pe-4 menu-name" 
            (click)="loadDocuments($event,menu)">{{ menu.name }}</span>
          </li>
          <li *ngIf="menu.children">
            <span class="has-child">
              <span
              class="menu-name"
              (contextmenu)="openContextDialog($event, menuContext); selectedFolder = menu" 
              [ngClass]="{'active': selectedFolderObj?.folderId == menu.id, 'approved':menu.isAllApproved, 'rejected':menu.isAllRejected, 'mixed':menu.isMixedStaus && (!menu.isAllRejected  || !menu.isAllApproved)}" 
              (click)="loadDocuments($event,menu)">{{ menu.name }} </span>
              <i (click)="collapsingMenu($event, menu)" [attr.data-bs-target]="'#item-'+menu.id" data-bs-toggle="collapse" 
              [attr.aria-expanded]="menu.isOpened ? 'true' : 'false'" class="fe fe-chevron-down" aria-hidden="true"></i></span>
            <ul [ngClass]="{'show':menu.isOpened}" class="collapse list-unstyled" [id]="'item-'+menu.id">
              <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"></ng-container>
            </ul>
          </li>
        </ng-container>
      </ng-template>
    </ul>
  </li>
</ul>
<!-- <tree-root #tree [options]="options" [nodes]="nodes" (moveNode)="onMoveNode($event)">
  <tree-root #tree [options]="options" [nodes]="nodes" (moveNode)="onMoveNode($event)">
    <ng-template #treeNodeTemplate let-node>
      <span (contextmenu)="!isReviewer ? openContextDialog($event, menu) : ''" (click)="toggleMobileMenu()" [ngClass]="(node.data.id==0 && currentPage=='dashboard') ? 'node-content-wrapper-active': ''" id="dir_{{node.data.id}}">
        <span [class.nodeName]="isMobileView" class="node-name">{{ node.data.name }}</span>
      </span>
      <i *ngIf="node.data.isAllRejected" class="fa fa-circle  icon-red"></i>
      <i *ngIf="node.data.isMixedStaus && (!node.data.isAllRejected  || !node.data.isAllApproved)" class="fa fa-circle  icon-yellow"></i>
      <i *ngIf="node.data.isAllApproved" class="fa fa-circle  icon-green"></i>
    </ng-template>
  </tree-root>

</tree-root> -->
  <nz-dropdown-menu #menuContext="nzDropdownMenu">
  <ul nz-menu >
    <li (click)="addFolder()" nz-menu-item><i class="fe fe-folder-plus mr-3"></i>New Folder</li>
    <li (click)="editFolder()" nz-menu-item
      *ngIf="selectedFolder ? selectedFolder.id!=0 && (selectedFolder.userId==nodes[0].userId || (selectedFolder.userId!=nodes[0].userId && selectedFolder.isWorkspace==0)) : false">
      <i class="fe fe-edit-2 mr-3"></i>
      <ng-container *ngIf="selectedFolder.isWorkspace == 0">Rename Folder</ng-container>
      <ng-container *ngIf="selectedFolder.isWorkspace == 1">Rename Workspace</ng-container>
    </li>
  </ul>
</nz-dropdown-menu>
