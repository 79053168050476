import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FilterPipe } from '../filter.pipe'
import { AllFilterPipe } from '../all-filter.pipe'
import { FolderObjPipe } from '../folder-obj.pipe'

@NgModule({
  declarations: [FilterPipe, AllFilterPipe, FolderObjPipe],
  imports: [CommonModule],
  exports: [FilterPipe, AllFilterPipe, FolderObjPipe],
})
export class PipeModule {}
