import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { GlobalService } from 'src/app/services/common/global.service';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'qr-workflow-modal',
  templateUrl: './workflow-modal.component.html',
  styleUrls: ['./workflow-modal.component.scss']
})
export class WorkflowModalComponent implements OnInit {

  constructor(public modal: NzModalRef,private _docService: DocumentService,
    private globalService : GlobalService) { }
  @Input() data: any
  wfValue  ="none";
  workflowList: any;
  currentSelectedItem: any = null;
  ngOnInit(): void { 
    this.globalService.currentSelectedItem.subscribe(currentSelectedItem => this.currentSelectedItem = currentSelectedItem);
    this.getWorkFlowList();
  }

  getWorkFlowList() {
    const workflowObj = {
      sort: "name",
      order: "asc",
      page: 1,
      // search: this.searchItem,
    }
   this._docService.workflowList(workflowObj).subscribe(async res => {
      this.workflowList = await res
      console.log(res);
    })
    if(this.currentSelectedItem == "Document"){
      this._docService.getworkflowIdBydocId({documentId:this.data.documentId}).subscribe(res=>{
        if(res.workflow_id){
          this.wfValue=res.workflow_id
        }
      })
    }else{
      this._docService.getworkflowIdByfolderId({folderId:this.data.id}).subscribe(res=>{
        if(res.workflow_id){
          this.wfValue=res.workflow_id
        }
      })
    }
  }

}
