import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from './common/global.service';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from './common/alert.service';
import { LoaderService } from './loader.service';
import { AuthService } from './common/auth.service';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class OktaService {
  cookieExpireTime = environment.cookieExpireTime
  userInfo: any

  constructor(  
    private _router: Router,
    private _auth: AuthService,
    private globalService: GlobalService,
    private _cookieService: CookieService,
    private _alertService: AlertService,
    public loaderService: LoaderService,) { }

    public makeRandom() {
      let lengthOfCode = 20
      let possible = '1234567890'
      let text = ''
      for (let i = 0; i < lengthOfCode; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
      return text
    }
    public authoriseOkta(data) {

    }
  public parseAccessToken(authResult) {
      let providerId = this.makeRandom()
      let userObj = {
        userName: authResult.tokens.idToken.claims.name ? authResult.tokens.idToken.claims.name : authResult._embedded.user.profile.firstName,
        userEmail: authResult.tokens.idToken.claims.email?authResult.tokens.idToken.claims.email: authResult._embedded.user.profile.login,
        provider: 'okta',
        providerId: providerId,
      }
      this._auth.socialLogin(userObj).subscribe(async res => {
        this.globalService.userInfo.next(res)
        localStorage.setItem('qrtoken', res.accessToken)
        localStorage.setItem('userEmail', res.userEmail)
        localStorage.setItem('userPassword', res.userPassword)
        localStorage.setItem('userId', res.userId)
        localStorage.setItem('apiKey',res.apiKey);
        if (res.view == 'grid') {
          this.globalService.gridView.next(true)
          this.globalService.listView.next(false)
        } else {
          this.globalService.gridView.next(false)
          this.globalService.listView.next(true)
        }
        var d = new Date()
        d.setTime(d.getTime() + this.cookieExpireTime * 24 * 60 * 60 * 1000)
        this._cookieService.set('token', res.accessToken, d)
        this.globalService.isLoading.next(false)
        let sId = localStorage.getItem('sharedDocId')
        localStorage.removeItem('sharedDirId')
        this.globalService.sharedDocId.next(parseInt(sId))
        let page = localStorage.getItem('redirectPage')
        localStorage.removeItem('redirectPage')
        if (page && page == 'm') {
          let userId = localStorage.getItem('userId')
          let emailId = localStorage.getItem('userEmail')
          let password = localStorage.getItem('userPassword')
          let membershipId = localStorage.getItem('membershipId')
          localStorage.removeItem('membershipId')
          window.open(
            window.location.origin +
              '/membership/prev.php?user_id=' +
              userId +
              '&email=' +
              emailId +
              '&password=' +
              password +
              '&autopay=true&membership_id=' +
              membershipId,
            '_self',
          )
        } else if (page && (page == 's' || page == 'f' || page == 'd')) {
          this._router.navigate(['/dashboard/shared'])
        } else {
          if (this.globalService.iFramed == true && this.userInfo.membershipId == 1) {
            this._alertService.error('You will need to sign up for a paid plan to use this feature.')
          } else {
            this._router.navigate(['/dashboard/documents'])
          }
        }
      })

  }
  
}
