import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as mime from 'mime-types'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Observable, throwError } from 'rxjs'
import { catchError, delay, finalize, map } from 'rxjs/operators'
import { AuthService } from './common/auth.service'
import { GlobalService } from './common/global.service'
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService {
  // cookieMsg = `We use cookies to secure your interaction with QuickReviewer. It seems your browser is blocking cookies and hence you are not able to login. To allow cookies, <a target="_blank" href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/chrome">read here</a>`

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private notification: NzNotificationService,
    private globalService:GlobalService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = this._authService.getToken()
    let apiKey :string =this._authService.apiKey()
    if (
      token && apiKey &&
      !request.clone().url.includes('https://api.vimeo.com') &&
      !request.clone().url.includes('googleapis.com') &&
      !request.clone().url.includes('youtube.com/watch') && 
      !request.clone().url.includes('api.box.com')
    ){
      request = request.clone({ headers: request.headers.set('Authorization', 'AUTH ' + token) })
      request = request.clone({ headers: request.headers.set('apiKey',  apiKey) })
    }
      
    if (!request.headers.has('Content-Type'))
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') })
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') })
    let mType = mime.lookup(request.url)
    let wfFlag = request.url.substr(request.url.indexOf('wfstatus'))
    if (
      !request.url.includes('folder/item-path') &&
      !request.url.includes('signup') &&
      !request.url.includes('login') &&
      !request.url.includes('token') &&
      !request.url.includes('folder/log') &&
      !request.url.includes('folder/folder-detail') &&
      !request.url.includes('document/log') &&
      !request.url.includes('document/document-detail') &&
      wfFlag != 'wfstatus' &&
      !mType
    ) {
      // this.loaderService.show(); //This was causing flickering, so commented it out
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event
      }),
      delay(1000),
      // finalize(() => { this.loaderService.hide(); }),
      catchError((err: HttpErrorResponse) => {
        // this.loaderService.hide();
        const error = err.error.failed || err.statusText
        if (err.status == 403 && !request.url.includes('googleapis.com')  ) {
          // if (request.url.includes('/dropbox-access-token')) {
          //   this.notification.blank(null, this.cookieMsg, { nzPlacement: 'bottomRight' })
          // swal({
          // 	html: this.cookieMsg,
          // 	allowOutsideClick: false
          // })
          // }
          if((this.globalService.currentPage.value=='reviewer') || localStorage.getItem('checkUser')){
            this.globalService.isConnected.next(false)
           }else{
            this._authService.logout(false)
           }
          // if(this.globalService.currentPage.value!='iframe'){
           
          // }else{
          //   this.globalService.isConnected.next(false)
          // }
          
          // if (location.hash != '') {
          //   if (window.location.hash.includes('#/auth/login?')) {
          //     window.location.href =
          //       window.location.origin + window.location.pathname + window.location.hash
          //   } else {
          // this._router.navigate(['auth/login'])
          //   }
          // } else {
          //   this._router.navigate(['auth/login'])
          // }
          
        }
        return throwError(error)
      }),
    )
  }
}
