import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/common/auth.service'
import { environment } from '../../../environments/environment'
import { GlobalService } from '../../services/common/global.service'
import { UntypedFormGroup, FormControl, Validators } from '@angular/forms'
import { AlertService } from '../../services/common/alert.service'
import { DataService } from '../../services/data.service'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { SubscriptionAlertComponent } from '../dashboard/modal-components/subscription-alert/subscription-alert.component'

@Component({
  selector: 'qr-upgrade-storage-dialog',
  templateUrl: './upgrade-storage-dialog.component.html',
  styleUrls: ['./upgrade-storage-dialog.component.css'],
})
export class UpgradeStorageDialogComponent implements OnInit {
  plansArr = []
  topupsArr = []
  plansArrMon = []
  isYearly = true
  membershipId: any
  currentMembership = []
  currentTopup = []
  currentUser: any
  currentUserPromoCodes: any
  protoCol: string
  hostName: string
  color: string
  isLoader: boolean = true
  planUpgradeForm: UntypedFormGroup
  onSubmitLoader: boolean = false
  showPromoCode: boolean = true
  isPaidMember: boolean = false
  myTopupIds: any
  userPermission: any

  constructor(
    private modalService: NzModalService,
    private _authService: AuthService,
    public modal: NzModalRef,
    private globalService: GlobalService,
    private _alertService: AlertService,
    private _dataService: DataService,
  ) {}

  ngOnInit() {
    this.globalService.userInfo.subscribe(userInfo => {
      this.currentUser = userInfo
      this.currentUserPromoCodes = this.currentUser.promoCode.split(',').length
    })

    this.getMembershipPlan()
    this.getTopupPlan()
    this.getCurrentMemberhsip()

    this.globalService.userPermission.subscribe(
      userPermission => (this.userPermission = userPermission),
    )
    // this.getUserPermission()
  }

  // getUserPermission() {
  //   let usetId = localStorage.getItem('userId')
  //   this._authService.getUserPermission().subscribe(servicesData => {
  //     if (servicesData.membership == 'paid') {
  //       this.isPaidMember = true
  //     } else {
  //       this.isPaidMember = false
  //     }
  //   })
  // }
  destroyModal(): void {
    this.modal.destroy(false)
  }
  getMembershipPlan() {
    this._authService.getMembershipPlan().subscribe(
      memberships => {
        const monthArrary = memberships.filter(membership => membership.period == 'M')
        const yearArray = memberships.filter(membership => membership.period == 'Y')
        this.plansArr = yearArray.sort((a, b) => a.price - b.price)
        this.plansArrMon = monthArrary.sort((a, b) => a.price - b.price)
        this.isLoader = false
      },
      err => console.log('err', err),
    )
  }

  getTopupPlan() {
    this._authService.getTopupPlan().subscribe(
      res => {
        this.topupsArr = res
        for (let i = 0; i < this.topupsArr.length; i++) {
          this.topupsArr[i].IsSelected = false
        }
        this.isLoader = false
      },
      err => console.log('err', err),
    )
  }

 setmembershipid(event) {
  this.membershipId = event
  if (this.currentUser.membershipId < 2) {
    this.upgradeMembershipPlan()
  } else {
    this.membershipId = event
    const confirmationDialog = this.modalService.create({
      nzTitle: '',
      nzCloseIcon: 'false',
      nzContent: SubscriptionAlertComponent,
      nzFooter: null,
    })
    confirmationDialog.afterClose.subscribe(result => {
      if (result) this.upgradeMembershipPlan()
    })
  }
  }

  getCurrentMemberhsip() {
    this._authService.getCurrentMemberhsip().subscribe(
      res => {
        if (res.space_in.toLowerCase() == 'mb') res.alloted_space = res.alloted_space / 1024
        this.currentMembership = res
        this.myTopupIds = res.myTopupIds
        for (let i = 0; i < this.topupsArr.length; i++) {
          for (let j = 0; j < this.myTopupIds.length; j++) {
            if (this.topupsArr[i].id == this.myTopupIds[j]) {
              this.topupsArr[i].IsSelected = true
            }
          }
        }
        console.log(this.topupsArr)
        if (res.mytopups) this.currentTopup = res.mytopups.split(',')
      },
      err => console.log('err', err),
    )
  }

  upgradeMembershipPlan() {
    let userId = localStorage.getItem('userId')
    let emailId = localStorage.getItem('userEmail')
    let password = localStorage.getItem('userPassword')
    this.protoCol = window.location.protocol
    this.hostName = window.location.hostname
    // console.log(this.membershipId.includes('TOP'))
    if (this.membershipId !== undefined && this.membershipId.toString().includes('TOP')) {
      let topId = this.membershipId.split('_')
      if (topId[1] > 0)
        window.open(
          'https://app.quickreviewer.com/membership/prev.php?user_id=' +
            userId +
            '&email=' +
            emailId +
            '&password=' +
            password +
            '&autopay=true&topup_id=' +
            topId[1],
          '_blank',
        )
    }
    if (this.membershipId > 0)
      window.open(
        'https://app.quickreviewer.com/membership/prev.php?user_id=' +
          userId +
          '&email=' +
          emailId +
          '&password=' +
          password +
          '&autopay=true&membership_id=' +
          this.membershipId,
        '_blank',
      )
    this.modal.destroy(true)
  }

  get f() {
    return this.planUpgradeForm.controls
  }

  onSubmit() {
    this.onSubmitLoader = true
    if (this.planUpgradeForm.invalid) {
      this.showPromoCode = false
      this.onSubmitLoader = false
      return
    }

    let userObj = {
      promo_code: this.planUpgradeForm.value.promoCode,
      user_type: this.currentUser.userType,
    }

    this._authService.upgradeUserPlan(userObj).subscribe(
      res => {
        this.currentUser.membershipId = res.membership_id
        this.currentUser.promoCode = res.promo_code
        this.globalService.userInfo.next(this.currentUser)
        this.onSubmitLoader = false
        // this._dataService.storagedocs()
        window.location.reload()
      },
      err => {
        this._alertService.error(err)
        this.onSubmitLoader = false
      },
    )
    this.modal.destroy(true)
  }
  switchMembershipPlan() {
    this.isYearly = !this.isYearly
  }

  updateTeam() {
    const confirmationDialog = this.modalService.create({
      nzTitle: '',
      nzContent: SubscriptionAlertComponent,
      nzFooter: null,
    })
    confirmationDialog.afterClose.subscribe(result => {
      if (result) {
      }
    })
  }
}
