import configall from '../../../configall-dev.json'
export const environment = {
  production: true,
  authenticated: false,
  hmr: false,
  apiUrl: configall.API_URL,
  rmUrl: window.location.origin + '/',
  proofUrl: window.location.origin + '/proof',
  socUrl: configall.SOCKET_URL,
  fileUploadUrl: configall.FILE_UPLOAD_URL,
  maxFileUploadSize: eval(configall.MAX_FILE_UPLOAD_SIZE),
  cookieExpireTime: configall.cookieExpireTime,
  GoogleClientId: configall.GoogleClientId,
  recaptchaSiteKey: configall.recaptchaSiteKey,
  dropBoxKey: configall.dropBoxKey,
  debug: configall.DEBUG,
  docExt: configall.docExt,
  apiPort: configall.apiPort,
  useawss3: configall.USE_AWS_S3,
  DROPBOX_ALLOWED_FILE: configall.DROPBOX_ALLOWED_FILE,
  notChangeLoginPageDomain: configall.notChangeLoginPageDomain,
  AppUrl: configall.APP_URL,
  youTubeAPIKey : configall.youtubeApiKey,
  youTubeProjectId : configall.youtubeProjectId,
  youTubeClientID : configall.youtubeClientId,
  youTubeClientSecret : configall.youtubeClientSecret,
  boxClientId: configall.boxClientId,
  boxClientSecret: configall.boxClientSecret,
  boxRedirectUri: configall.boxRedirectUri,
  boxURL : configall.boxURL
}
