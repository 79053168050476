<div class="share-wrap">
  <div class="share-left">
    <div class="share-view-box">
      <div class="mr-3">
        <div class="copy-link" *ngIf="shareableLink && allowAnonymousLink">
          <div class="shareListItem">
            <div class="pl-1">
              <span class="shareableLink">Anyone with this shareable link can</span>
              <nz-select class="setWidth ml-2 mobile-view-shareType" [(ngModel)]="selectedVal"
                (ngModelChange)="changeAction($event)">
                <nz-option nzLabel="Comment" nzValue="Comment"></nz-option>
                <nz-option nzLabel="View" nzValue="View"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="d-flex mt-3">
            <input [disabled]="disableCopyLink" nz-input class="formControl w-75-new" id="shareCopyUrl"
              [value]="shareUrl" (click)="copyToClipBoard()" readonly>
            <button type="button" [disabled]="shareUrl == '' || disableCopyLink" (click)="copyToClipBoard()"
              class="ant-btn ant-btn-primary ml-2">Copy
              Link</button>
          </div>
      <div class="d-flex mt-3" *ngIf="data.hasOwnProperty('documentType')">
        <label nz-checkbox style="margin: auto 0;" [(ngModel)]="isPasswordChecked" (ngModelChange)="togglePassword()"  
          class="checkbox-margin">
          Add Password</label>
        <input nz-input [ngClass]="{disabled : !isPasswordChecked}" class="formControl input w-75 marginLeft"
          type="text" [(ngModel)]="documentPassword" (ngModelChange)="handleUpdatePasswordWithoutShare()" [value]="documentPassword">

      </div>
        <div class="passwordValidation" *ngIf="passwordValidation">
          Password is required.
        </div>
        <div class="passwordValidation" *ngIf="checkPasswordLength">
          Password must be at least 6 characters long.
        </div>

      
    </div>
  </div>
      <div class="mr-3 mt-3">
        <div mat-dialog-content class="share-dialog">
          <form [formGroup]="shareDocForm" #formDir="ngForm">
            <section>
              <h3 mat-subheader>Share with
                <small class="text-danger ml-3" *ngIf="invalidEmail || maxEmailsReached">
                  <ng-container *ngIf="invalidEmail">Some email IDs are not correct.</ng-container>
                  <ng-container *ngIf="maxEmailsReached && !invalidEmail">You can only add up to 10 emails.</ng-container>
                </small>
              </h3>
              <div *ngIf="!updateChange && !deleteAction" class="share-people mt-3">
                <mat-form-field class="shareField ant-input" floatLabel="never">
                  <mat-chip-list #chipList>
                    <mat-chip *ngFor="let emailTag of emailTags" class="selected-chips" [selectable]="selectable"
                      [removable]="removable" (removed)="remove(emailTag)">
                      {{emailTag}}
                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Enter names or email address..." #emailTagsInput [formControl]="userEmailCtrl"
                      [matAutocomplete]="auto" [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="add($event)" (click)="onFocus()">
                  </mat-chip-list>

                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let emailTag of filteredEmailTags | async" [value]="emailTag">
                      {{emailTag}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <nz-select *ngIf="!isWorkspace" class="setWidth ml-2 mobile-view-select" #select
                  formControlName="shareAction" (ngModelChange)="onSelectAction($event.value)">
                  <nz-option nzLabel="Commenter" nzValue="Comment"></nz-option>
                  <nz-option nzLabel="Viewer" nzValue="View"></nz-option>
                </nz-select>
              </div>
              <mat-list class="shareListItem mat-list-item">
                <mat-list-item>
                  <p class="ml-1" mat-line class="shareSection">
                    <i class="fe fe-user"></i>
                    {{currentUser.userEmail}}
                  </p>
                  <div nz-tooltip nzTooltipTitle="Manage advanced permissions and delete files" class="isOwner">Owner
                  </div>
                </mat-list-item>
                <mat-list-item *ngFor="let row of sharedEmail;let i=index;"
                  [ngClass]="{'disabled':selectedSharedEmail.indexOf(i)!=-1}">
                  <p class="ml-1" mat-line class="shareSection">
                    <i class="fe fe-user"></i>
                    {{row.shareEmail}}
                  </p>
                  <button *ngIf="!row.isOwner && !isWorkspace" class="commentBtnStyle">
                    <span nz-dropdown nzTrigger="click" class="" [nzDropdownMenu]="menu"
                      [ngClass]="{'cursorPointer':deleteAction}">
                      <span class="shareSection" *ngIf="row.shareAction == 'Comment'" nz-tooltip
                        nzTooltipTitle="Only make comments or suggestions">
                        Commenter
                      </span>
                      <span class="shareSection" *ngIf="row.shareAction == 'View'" nz-tooltip
                        nzTooltipTitle="View the files">
                        Viewer
                      </span>
                      <i nz-icon nzType="down" class="ml-3"></i>
                    </span>
                    <nz-dropdown-menu #menu="nzDropdownMenu" name="row.shareAction">
                      <ul nz-menu class="setWidth">
                        <li nz-menu-item (click)="onUpdateShare('Comment',row.shareId);row.shareAction='Comment'">
                          Comment</li>
                        <li nz-menu-item (click)="onUpdateShare('View',row.shareId);row.shareAction='View'">View</li>
                      </ul>
                    </nz-dropdown-menu>
                  </button>

                  <button *ngIf="!row.isOwner" nz-button nzType="link"
                    (click)="onDeleteShare(row.shareId,i,row.shareEmail)" [disabled]="updateChange">
                    <i class="fe fe-x ml-2 " aria-hidden="true"></i>
                  </button>
                  <div *ngIf="row.isOwner" nz-tooltip nzTooltipTitle="Manage advanced permissions and delete files"
                    class="isOwner">
                    owner
                    <button nz-button nzType="link" (click)="onDeleteShare(row.shareId,i)" [disabled]="updateChange">
                      <i class="fe fe-x ml-2 " aria-hidden="true"></i>
                    </button>
                  </div>
                </mat-list-item>
              </mat-list>
            </section>

            <div class="noteBox mt-3">
              <h3 mat-subheader class="mb-0">Include a note</h3>
              <textarea nz-input formControlName="note" class="form-control mt-3"
                [nzAutosize]="{ minRows: 2, maxRows: 5 }"></textarea>
            </div>
            <section class="mt-4 cheackbox" *ngIf="!isWorkspace">
              <label nz-checkbox class="checkbox-margin" formControlName="download"
                *ngIf="!deleteAction && (currentSelectedItem == 'Document' || currentSelectedItem == 'Folder' )"
                [(ngModel)]="downloadVal" (ngModelChange)="handleCallApi()">
                Disable download for everyone</label>
              <div class="" style="margin-top: 9px;">
                <label nz-checkbox class="checkbox-margin" formControlName="anybody"
                  *ngIf="!deleteAction && (currentSelectedItem == 'Document' || currentSelectedItem == 'Folder' ) && allowAnonymousLink"
                  [(ngModel)]="anybodyVal" (ngModelChange)="handleCallApi()">
                  Allow comment without login</label>
              </div>
              <div class="setViewMode" *ngIf="isPdf">
                <label nz-checkbox class="checkbox-margin" formControlName="isSetView">
                  Set View
                </label>
                <div *ngIf="isSetView">
                  <nz-select class="pageFitView" formControlName="pageFit"
                    (ngModelChange)="changeViewMode($event)">
                    <nz-option *ngFor="let mode of proofViewMode" nzLabel="{{mode.label}}" nzValue="{{mode.value}}"></nz-option>
                  </nz-select>
                  <nz-select class="spreadView" formControlName="spreadMode"
                    (ngModelChange)="changeViewMode($event)">
                    <nz-option nzLabel="Single Page" nzValue="spreadSingle"></nz-option>
                    <nz-option nzLabel="Odd Spreads" nzValue="spreadOdd"></nz-option>
                    <nz-option nzLabel="Even Spreads" nzValue="spreadEven"></nz-option>
                  </nz-select>
                </div>
              </div>
            </section>
            <!-- <div class="text-right">
              <section class="btn-group btns share mt-3" *ngIf="show">
                <button type="button" class="ant-btn ant-btn-secondary ng-star-inserted mr-3"
                  (click)="cancelShare()">Cancel</button>
                <button class="ant-btn ant-btn-primary" [class.isloader]="onSharebtnLoader"
                  (click)="saveShared($event)"><span>Share</span>
                </button>
              </section>
              <section class="share text-right mt-3" *ngIf="!show && !updateChange && !deleteAction">
                <button class="ant-btn ant-btn-primary"
                  (click)="onChangeAnybody(anybodyVal, downloadVal);modal.destroy({downloadVal:downloadVal,anybodyVal:anybodyVal,sharedEmail:sharedEmail,emailTagsAll:emailTagsAll,proofSetting : this.proofSetting})">Done</button>
              </section>
            </div> -->
            <div class="text-right">
              <section class="btn-group btns share mt-3" *ngIf="show || checked == true">
                <button type="button" class="ant-btn ant-btn-secondary ng-star-inserted mr-3"
                  (click)="cancelShare()">Cancel</button>
                <button class="ant-btn ant-btn-primary" [class.isloader]="onSharebtnLoader"
                  (click)="saveShared($event)"><span>Share</span>
                </button>
              </section>
              <section class="share text-right mt-3"
                *ngIf="!show && !updateChange && !deleteAction && checked == false">
                <button class="ant-btn ant-btn-primary" [disabled]="disableClose == true"
                  (click)="onChangeAnybody(anybodyVal, downloadVal);onUpdate()">Done</button>
                <!-- <section class="share text-right mt-3" *ngIf=" checked == true">
                  <button class="ant-btn ant-btn-primary" [disabled]="disableClose == true">Save password</button>
                </section> -->
              </section>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="share-right pl-3">
    <div class="card-right">
      <h3 mat-subheader class="right-panel-header">Include</h3>
      <div class="inclu-box">
        <div class="email-right">
          <div class="chip-container">
            <div class="d-flex align-items-center cursor-pointer setColor mt-3"
              *ngFor="let aeT of allEmailTags;let i=index;" (click)="selectedOpt(aeT)">
              <i class="fe fe-user mr-2" *ngIf="validateEmail(aeT)"></i>
              <i class="fe fe-users mr-2" *ngIf="!validateEmail(aeT)"></i>
              <span nz-tooltip nzTooltipTitle="{{aeT}}" class="email-temp-text">{{ (aeT.length>30)? (aeT |
                slice:0:20)+'..':(aeT) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="mt-4">
  <section class="btn-group btns share d-flex justify-content-end" *ngIf="updateChange">
    <button type="button" class="ant-btn ant-btn-secondary ng-star-inserted mr-3"
      (click)="cancelUpdateShare()">Cancel</button>
    <button class="ant-btn ant-btn-primary" (click)="updateShared()">Save Changes</button>
  </section>
  <section class="btn-group btns share cancelShare  d-flex justify-content-end" *ngIf="deleteAction">
    <button type="button" class="ant-btn ant-btn-secondary ng-star-inserted mr-3"
      (click)="cancelDeleteShare()">Cancel</button>
    <button type="submit" class="ant-btn ant-btn-primary " (click)="deleteShared()">Save Changes</button>
  </section>
</div>
