<div class="text-dark">
    <!-- <h5>New Website</h5> -->
    <div class="d-flex w-100">
        <nz-select class="host-dropdown" [(ngModel)]="data.value">
            <!-- <nz-option nzValue="http://" nzLabel="http://"></nz-option> -->
            <nz-option nzValue="https://" nzLabel="https://"></nz-option>
        </nz-select>

        <input nz-input placeholder="Enter website URL" [(ngModel)]="webProof" (ngModelChange)="isDisabled = false" (click)="$event.target.select()"
            (keydown.enter)="createWebproof()" />
    </div>
    <div *ngIf="urlhasprotocol" class="text-danger mt-2">
        The words http or https are not permitted in the link.
    </div>
    <div *ngIf="urlInvalid" class="text-danger mt-2">
        This link is not valid.
    </div>
    <div *ngIf="urlResponseError" class="text-danger mt-2">
        This link returned an error. Check the link and try again.
    </div>

    <p class="mt-3">Select HTTP or HTTPS and enter the web URL. If you are not sure which one to choose, it is more
        likely http://</p>
    <p>If your website is a WordPress site, please make sure you have added the <a
            href="https://wordpress.org/plugins/quickreviewer-html-review/" target="_blank">WordPress plugin</a> to your
        site.
        If it is plain HTML, please ensure you have included the code snippet as <a
            href="https://www.quickreviewer.com/knowledgebase/how-can-i-review-live-websites/" target="_blank">detailed
            here</a></p>
</div>
<div class="text-right mt-3">
    <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
    <button type="button" class="ant-btn ant-btn-primary" [disabled]="isDisabled || !webProof?.trim()" (click)="createWebproof()">Create</button>
</div>