import { Component, OnInit, ViewChild, Inject, Input } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import {
  ITreeOptions,
  // TREE_ACTIONS,
  TreeNode,
  TreeModel,
  TreeComponent,
} from '@circlon/angular-tree-component'
// import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../../services/data.service'
import { GlobalService } from '../../services/common/global.service'
import { DocumentService } from '../../services/document.service'
import { FolderService } from '../../services/folder.service'
// import { Router } from '@angular/router'
import { orderBy } from 'natural-orderby'
import { PouchDataService } from '../../services/pouch-data.service'

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown'
import { RemoveFileComponent } from '../dashboard/modal-components/remove-file/remove-file.component'
import { CreateFolderComponent } from '../dashboard/modal-components/create-folder/create-folder.component'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { RenameFolderComponent } from '../dashboard/modal-components/rename-folder/rename-folder.component'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import * as SettingsActions from 'src/app/store/settings/actions'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
@Component({
  selector: 'qr-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.css'],
})
export class FolderTreeComponent implements OnInit {
  @ViewChild(TreeComponent)
  private tree: TreeComponent
  modalRef
  folderForm: UntypedFormGroup
  weblinkForm: UntypedFormGroup
  webproofForm: UntypedFormGroup
  editFolderForm: UntypedFormGroup
  private formSumitAttempt: boolean
  addNewView = 'off'
  folderTreeContextMenu: { node: TreeNode; x: number; y: number } = null
  sourceNode: TreeNode = null
  editNode: TreeNode = null
  doCut = false
  newNode = []
  //position = 0;
  folderObj: Array<any>
  // folderBreadCrumb: boolean = false;
  folderBCObj: any = []
  public active
  public action
  selectedFolderObj: any
  currentNode: any
  activeDocumentsCancel: Subscription
  getChildrenCancel: Subscription
  public href: string = ''
  url: string = ''
  order: boolean = false
  nameOrder: boolean = false
  dateOrder: boolean = true
  folderIdBeforeLoading = null
  isMobileMenuOpen: boolean
  isReviewer: boolean
  menu: NzDropdownMenuComponent
  options: ITreeOptions = {
    getChildren: this.getChildren.bind(this),
    useCheckbox: false,
    actionMapping: {
      mouse: {
        contextMenu: async (treeModel: TreeModel, treeNode: TreeNode, e: MouseEvent) => {
          e.preventDefault()
          if (this.folderTreeContextMenu && treeNode === this.folderTreeContextMenu.node) {
            return this.closeMenu()
          }

          if (this.globalService.currentPage.value != 'dashboard') {
            this._dataService.dashboardDocument()
            this.globalService.currentPage.next('dashboard')
          }
          let lineage: any = await this.getPath(treeNode)
          let pathArr: any = await Promise.all(
            lineage.reverse().map((v, i) => {
              if (i > 1) {
                return {
                  folderId: v.id,
                  folderName: v.name,
                }
              }
            }),
          )
          pathArr = pathArr.filter(function(element) {
            return element !== undefined
          })
          this.globalService.folderBCObj.next(pathArr)
          if (pathArr.length == 1) this.globalService.folderBreadCrumb.next(false)
          else this.globalService.folderBreadCrumb.next(true)
          this.getChildrenOnSelect(treeNode)

          this.folderTreeContextMenu = {
            node: treeNode,
            x: e.pageX,
            y: e.pageY,
          }
          this.clickOutsideCard()
          let workspaceId =
            treeNode.data.isWorkspace == 1 ? treeNode.data.id : treeNode.data.workspaceId
          if (!treeNode.isCollapsed) {
            this.globalService.selectedFolderObj.next({
              folderId: treeNode.data.id,
              folderName: treeNode.data.name,
              workspaceId: workspaceId == undefined ? 0 : workspaceId,
              workspaceUserId:
                treeNode.data.workspaceId > 0 || treeNode.data.isWorkspace == 1
                  ? treeNode.data.userId
                  : 0,
            })
          }
        },
        click: async (treeModel: TreeModel, treeNode: TreeNode, e: MouseEvent) => {
          if (treeNode.data.name == 'My Documents') {
            // return this._dataService.dashboardDocument()
          }
          this.globalService.currentPage.next('dashboard')
          let lineage: any = await this.getPath(treeNode)
          let pathArr: any = await Promise.all(
            lineage.reverse().map((v, i) => {
              if (i > 1) {
                return {
                  folderId: v.id,
                  folderName: v.name,
                }
              }
            }),
          )
          pathArr = pathArr.filter(function(element) {
            return element !== undefined
          })
          this.globalService.folderBCObj.next(pathArr)
          if (pathArr.length == 1) this.globalService.folderBreadCrumb.next(false)
          else this.globalService.folderBreadCrumb.next(true)

          this.getChildrenOnSelect(treeNode)
          this.closeMenu()
          this.getData(treeNode)
        },
        expanderClick: async (treeModel, treeNode, e) => {
          e.preventDefault()
          this.globalService.currentPage.next('dashboard')
          await this.getChildren(treeNode)
          treeNode.data.children = this.newNode
          treeNode.treeModel.update()
          this.tree.treeModel.update()
          this.closeMenu()
          treeNode.toggleExpanded()
          this.getData(treeNode)
        },
      },
    },
    allowDrag: node => false,
    allowDrop: (element, { parent, index }) => {
      // return true / false based on element, to.parent, to.index. e.g.
      return parent.hasChildren
    },
    allowDragoverStyling: true,
  }

  nodes: any[] = []
  updateLocalData: any
  isMobileView: boolean
  currentPage: any
  menus: any = []
  breadcrumbs: any = []
  menusData
  selectedFolder
  documents: any = []
  selectedMenu: any = []
  isDocsOpened: boolean = false
  menuLoader: boolean = false

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public _dataService: DataService,
    private _docService: DocumentService,
    private globalService: GlobalService,
    private _folderService: FolderService,
    private _pouchDataService: PouchDataService,
    private nzContextMenuService: NzContextMenuService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private store: Store<any>,
    private router: Router,
  ) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.isMobileView = state.isMobileView
      this.isMobileMenuOpen = state.isMobileMenuOpen
    })
  }
  activeDocumentsUnsubscribe() {
    this.activeDocumentsCancel.unsubscribe()
  }
  getChildrenUnsubscribe() {
    this.getChildrenCancel.unsubscribe()
  }
  ngOnInit() {
    this.globalService.isReviewer.subscribe(isReviewer => (this.isReviewer = isReviewer))
    this.globalService.folderObj.subscribe(folderObj => {
      this.folderObj = folderObj
    })
    this.globalService.folderTreeContextMenu.subscribe(resp => {
      this.folderTreeContextMenu = resp
    })
    this.globalService.newNode.subscribe(newNode => {
      this.newNode = newNode
    })
    this.globalService.folderBCObj.subscribe(folderBCObj => {
      this.folderBCObj = folderBCObj
    })
    this.globalService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage
    })
    // this.globalService.folderBreadCrumb.subscribe(
    //   (folderBreadCrumb) => {
    //     this.folderBreadCrumb = folderBreadCrumb;
    //   }
    // )
    this.globalService.selectedFolderObj.subscribe(selectedFolderObj => {
      this.selectedFolderObj = selectedFolderObj
    })
    this.globalService.treeObj.next(this.tree)

    this.folderForm = new UntypedFormGroup({
      folderName: new UntypedFormControl('Untitled folder', [Validators.required]),
    })
    this.editFolderForm = new UntypedFormGroup({
      folderName: new UntypedFormControl('', [Validators.required]),
    })
    this.weblinkForm = new UntypedFormGroup({
      weblinkName: new UntypedFormControl('https://', [Validators.required]),
    })
    this.webproofForm = new UntypedFormGroup({
      webproofName: new UntypedFormControl('https://', [Validators.required]),
    })

    // this.globalService.selectedFolderObj.next({
    //   folderId: 0,
    //   folderName: 'My Documents',
    //   workspaceId: 0,
    //   workspaceUserId: 0,
    // })
    this.nodes = [
      {
        id: 0,
        name: 'My Documents',
        hasChildren: true,
        isExpanded: false,
        lastModified: null,
        userName: null,
        userId: parseInt(localStorage.getItem('userId')),
        star: 0,
        parent: 0,
        data: [],
      },
    ]

    // this.globalService.currentNode.next(this.nodes[0]);
    this.globalService.updateLocalData.subscribe(
      updateLocalData => (this.updateLocalData = updateLocalData),
    )
    this.globalService.selectedMenu.subscribe(res => (this.selectedMenu = res))
    this.menuLoader = true
    this.globalService.menus.subscribe(res => {
      setTimeout(() => {
        if (!res.length) this.menuLoader = false
      }, 1000)
      if (res && this.selectedMenu.length) {
        this.isDocsOpened = this.selectedMenu.find(el => el.id == 0)
        for (let index = 0; index < this.selectedMenu.length; index++) {
          const element = this.selectedMenu[index]
          res.forEach(el => {
            if (el.id === element.id) el.isOpened = true
          })
        }
      }
      if (res) this.formatMenus(res)
    })
  }

  collapsingMenu(event, menu) {
    if (event.target.className.includes('collapsed')) {
      this.selectedMenu = this.selectedMenu.filter(el => el.id !== menu.id)
    } else this.selectedMenu.push(menu)
    this.globalService.selectedMenu.next(this.selectedMenu)
  }

  formatMenus(res) {
    if (!res.length) {
      this.menus = []
      return
    }
    this.menusData = res
    function findParent(menuItems, parentId) {
      return menuItems.filter(item => item.parent === parentId)
    }

    function populateChildren(menuItems, parent) {
      let parentId = parent.id
      let children = findParent(menuItems, parentId)

      if (children.length > 0) {
        parent.children = children

        for (let i = 0; i < children.length; i++) {
          if (children[i].hasChildren) {
            children[i].children = []
            populateChildren(menuItems, children[i])
          }
        }
      }
    }

    this.menus = res.filter(el => el.parent === 0)

    for (let i = 0; i < this.menus.length; i++) {
      populateChildren(res, this.menus[i])
    }
    this._pouchDataService
      .getAllDocumentDataFromPouchDB()
      .then(files => {
        if (files) {
          this.documents = files
          this.folderStatus(this.menus)
        }
      })
      .catch(error => {})
    this.menuLoader = false
  }

  folderStatus(menus) {
    for (let i = 0; i < menus.length; i++) {
      const folder = menus[i]
      const matchingFiles = this.documents.filter(file => file.folderId === folder.id)
      if (matchingFiles.length > 0) {
        const approvedFiles = matchingFiles.filter(file => file.wfStatusName === 'Approved')
        const reworkFiles = matchingFiles.filter(file => file.wfStatusName === 'Rework')
        folder.isAllApproved = approvedFiles.length === matchingFiles.length
        folder.isAllRejected = reworkFiles.length === matchingFiles.length
        folder.isMixedStaus =
          (!folder.isAllApproved && approvedFiles.length > 0) ||
          (!folder.isAllRejected && reworkFiles.length > 0)
      }
      if (folder.children) this.folderStatus(folder.children)
    }
  }

  findParent(node) {
    let data = this.menusData.find((el: any) => el.id == node.parent)
    this.breadcrumbs.unshift({ folderId: data.id, folderName: data.name })
    if (data.parent > 0) this.findParent(data)
  }

  reset() {
    this.router.navigateByUrl('/dashboard/documents')
    this._dataService.dashboardDocument()
  }

  async loadDocumentsOld(event, node) {
    this.globalService.currentNode.next(node)
    let workspaceId = node.isWorkspace == 1 ? node.id : node.workspaceId
    this.globalService.selectedFolderObj.next({
      folderId: node.id,
      folderName: node.name,
      workspaceId: workspaceId == undefined ? 0 : workspaceId,
      workspaceUserId: node.workspaceId > 0 || node.isWorkspace == 1 ? node.userId : 0,
    })
    this.router.navigateByUrl('/dashboard/documents')
    this.breadcrumbs = []
    this.breadcrumbs.unshift({ folderId: node.id, folderName: node.name })
    if (node.parent > 0) this.findParent(node)
    this.globalService.folderBCObj.next(this.breadcrumbs)

    this.globalService.showLoader.next(true)
    this.globalService.searchItemPathArr.next([])
    this.globalService.searchItemName.next(null)
    this.globalService.searchFlag.next(false)
    if (document.querySelector('#searchText') != null) {
      let searchText = <HTMLInputElement>document.querySelector('#searchText')
      searchText.value = ''
    }
    if (
      (node.id > 0 && node.parent == 0 && node.isWorkspace == 1) ||
      (node.workspaceId > 0 && localStorage.getItem('isWorkspace') == undefined)
    ) {
      localStorage.setItem('isWorkspace', '1')
      localStorage.setItem('workspaceId', node.workspaceId > 0 ? node.workspaceId : node.id)
      localStorage.setItem('workspaceUserId', node.userId)
    } else if (node.id > 0 && node.parent == 0 && node.isWorkspace == 0) {
      localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }
    return new Promise(async (resolve, reject) => {
      let folderCacheData = await this._pouchDataService
        .getFoldersFromPouchDb(node.id)
        .then(folderArrObj => {
          this.globalService.folderObj.next(folderArrObj)
          return true
        })
        .catch(err => {
          this.globalService.folderObj.next([])
          return false
        })
      let fileCacheData = await this._pouchDataService
        .getDocumentsFromPouchDb(node.id)
        .then(fileArrObj => {
          this.globalService.docInfo.next(fileArrObj)
          return true
        })
        .catch(err => {
          this.globalService.docInfo.next([])
          return false
        })
      if ((folderCacheData || fileCacheData) && !this.updateLocalData.dashboard) {
        this.globalService.showLoader.next(false)
        resolve(this.newNode)
      } else {
        this.globalService.docInfo.next([])
        this.globalService.folderObj.next([])
        this.globalService.showLoader.next(false)
        this.folderIdBeforeLoading = this.selectedFolderObj.folderId
        let folderId = this.selectedFolderObj.folderId
        if (this.getChildrenCancel) {
          this.getChildrenUnsubscribe()
        }
        this.getChildrenCancel = this._folderService.getChildren({ folderId: node.id }).subscribe(
          res => {
            this.globalService.showLoader.next(false)
            //If the user changes the folder before the response comes back from the server, do nothing.
            if (this.selectedFolderObj.folderId == folderId) {
              if (res.length > 0) {
                this.globalService.folderObj.next(res)
              } else {
                this.globalService.folderObj.next([])
              }
              this._pouchDataService.setFoldersInPouchDb(res, node.id, 'dashboard')
            }
            resolve(this.newNode)
          },
          err => {
            this.globalService.showLoader.next(false)
          },
        )
        if (this.activeDocumentsCancel) {
          this.activeDocumentsUnsubscribe()
        }
        this.activeDocumentsCancel = this._docService
          .activeDocuments({ folderId: node.id })
          .subscribe(
            res => {
              this.globalService.showLoader.next(false)
              if (this.selectedFolderObj.folderId == folderId) {
                if (
                  this.folderIdBeforeLoading &&
                  this.folderIdBeforeLoading != this.selectedFolderObj.folderId
                ) {
                  this.folderIdBeforeLoading = null
                  return
                }
                if (res.length > 0) {
                  res.map((currElement, index) => {
                    let unique = []
                    if (currElement.docSharedWith.length) {
                      currElement.docSharedWith.map(q => {
                        let index = unique.findIndex(data => data.display_name == q.display_name)
                        if (index == -1) {
                          unique.push({ display_name: q.display_name })
                        }
                      })
                      res[index].docSharedWith = []
                      res[index].docSharedWith = unique
                    }
                  })
                  this.globalService.docInfo.next(res)
                } else {
                  this.globalService.docInfo.next([])
                }
                this._pouchDataService.setDocumentsInPouchDb(res, node.id, 'dashboard')
              }
            },
            err => {
              this.globalService.showLoader.next(false)
              console.log('err', err)
            },
          )
      }
    })
  }

  toggleMobileMenu() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMobileMenuOpen: false,
      }),
    )
  }
  openContextDialog($event: MouseEvent, menu: NzDropdownMenuComponent): void {
    $event.preventDefault()
    this.nzContextMenuService.create($event, menu)
  }

  getPath(treeNode) {
    return new Promise((resolve, reject) => {
      const lineage = []
      // add clicked node as first item
      lineage.push(treeNode.data)

      // grab parent of clicked node
      let parent: any = treeNode.parent

      // loop through parents until the root of the tree is reached

      while (parent !== null) {
        lineage.push(parent.data)
        parent = parent.parent
        if (parent == null) {
          resolve(lineage)
        }
      }
    })
  }

  async getChildren(node: any) {
    // this.globalService.currentNode.next(node)
    this.globalService.showLoader.next(true)
    // if (node.data.hasChildren) {
    //   if (node.data.children == undefined) {
    //     this.getChildrenCount(node.data.id);
    //   }
    //   else {
    //     this.position = node.data.children.length + 1;
    //   }

    // }
    if (
      (node.data.id > 0 && node.data.parent == 0 && node.data.isWorkspace == 1) ||
      (node.data.workspaceId > 0 && localStorage.getItem('isWorkspace') == undefined)
    ) {
      localStorage.setItem('isWorkspace', '1')
      localStorage.setItem(
        'workspaceId',
        node.data.workspaceId > 0 ? node.data.workspaceId : node.data.id,
      )
      this._docService.getStorageInfoOfWorkspaceOwner(node.data.userId).subscribe(hasSpace => {
        this.globalService.canUploadToSharedWorkspace.next(hasSpace.canUploadToSharedWorkspace)
        this.globalService.remainingSpace.next(hasSpace.remainingSpace)
      })
      localStorage.setItem('workspaceUserId', node.data.userId)
    } else if (node.data.id > 0 && node.data.parent == 0 && node.data.isWorkspace == 0) {
      localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }

    // this._pouchDataService.destroyUserNodes();
    let isNewNode = await this._pouchDataService
      .getUserNodesPouchDb(node.data.id, '')
      .then(nodeArr => {
        this.globalService.newNode.next(nodeArr)
        return this.newNode
      })
      .catch(err => {
        return false
      })

    if (isNewNode) {
      this.globalService.showLoader.next(false)
      return isNewNode
    } else {
      return new Promise((resolve, reject) => {
        // this._folderService.getChildren({ folderId: node.data.id }).subscribe(res => {
        this._folderService.getDeepChildren({ folderId: 0 }).subscribe(
          async res => {
            this.globalService.showLoader.next(false)
            if (res) {
              this._pouchDataService.setUserNodesPouchDb(res, 'insert')
              let nodeObj = await res.filter(function(element) {
                return element.parent == node.data.id
              })
              this.globalService.newNode.next(nodeObj)
              resolve(this.newNode)
            }
          },
          err => {
            this.globalService.showLoader.next(false)
          },
        )
      })
    }
  }

  loadDocuments(event, node) {
    this.globalService.currentNode.next(node)
    let workspaceId = node.isWorkspace == 1 ? node.id : node.workspaceId
    this.globalService.selectedFolderObj.next({
      folderId: node.id,
      folderName: node.name,
      workspaceId: workspaceId == undefined ? 0 : workspaceId,
      workspaceUserId: node.workspaceId > 0 || node.isWorkspace == 1 ? node.userId : 0,
    })
    this.router.navigateByUrl('/dashboard/documents')
    this.breadcrumbs = []
    this.breadcrumbs.unshift({ folderId: node.id, folderName: node.name })
    if (node.parent > 0) this.findParent(node)
    this.globalService.folderBCObj.next(this.breadcrumbs)

    this.getChildrenOnSelect(node)
  }

  getChildrenOnSelect(node: any) {
    this.globalService.showLoader.next(true)
    this.globalService.searchItemPathArr.next([])
    this.globalService.searchItemName.next(null)
    this.globalService.searchFlag.next(false)
    if (document.querySelector('#searchText') != null) {
      let searchText = <HTMLInputElement>document.querySelector('#searchText')
      searchText.value = ''
    }
    // this.globalService.currentNode.next(node)
    // node.toggleActivated()

    if (
      (node.id > 0 && node.parent == 0 && node.isWorkspace == 1) ||
      (node.workspaceId > 0 && localStorage.getItem('isWorkspace') == undefined)
    ) {
      localStorage.setItem('isWorkspace', '1')
      localStorage.setItem('workspaceId', node.workspaceId > 0 ? node.workspaceId : node.id)
      localStorage.setItem('workspaceUserId', node.userId)
    } else if (node.id > 0 && node.parent == 0 && node.isWorkspace == 0) {
      localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }
    return new Promise(async (resolve, reject) => {
      let folderCacheData = await this._pouchDataService
        .getFoldersFromPouchDb(node.id)
        .then(folderArrObj => {
          this.globalService.folderObj.next(folderArrObj)
          return true
        })
        .catch(err => {
          this.globalService.folderObj.next([])
          return false
        })
      let fileCacheData = await this._pouchDataService
        .getDocumentsFromPouchDb(node.id)
        .then(fileArrObj => {
          this.globalService.docInfo.next(fileArrObj)
          return true
        })
        .catch(err => {
          this.globalService.docInfo.next([])
          return false
        })
      if ((folderCacheData || fileCacheData) && !this.updateLocalData.dashboard) {
        this.globalService.showLoader.next(false)
        resolve(this.newNode)
      } else {
        this.globalService.showLoader.next(false)
        this.folderIdBeforeLoading = this.selectedFolderObj.folderId
        let folderId = this.selectedFolderObj.folderId
        if (this.getChildrenCancel) {
          this.getChildrenUnsubscribe()
        }
        this.getChildrenCancel = this._folderService.getChildren({ folderId: node.id }).subscribe(
          res => {
            this.globalService.showLoader.next(false)
            //If the user changes the folder before the response comes back from the server, do nothing.
            if (this.selectedFolderObj.folderId == folderId) {
              if (res.length > 0) {
                this.globalService.folderObj.next(res)
              } else {
                this.globalService.folderObj.next([])
              }
              this._pouchDataService.setFoldersInPouchDb(res, node.id, 'dashboard')
            }
            resolve(this.newNode)
          },
          err => {
            this.globalService.showLoader.next(false)
          },
        )
        if (this.activeDocumentsCancel) {
          this.activeDocumentsUnsubscribe()
        }
        this.activeDocumentsCancel = this._docService
          .activeDocuments({ folderId: node.id })
          .subscribe(
            res => {
              this.globalService.showLoader.next(false)
              if (this.selectedFolderObj.folderId == folderId) {
                if (
                  this.folderIdBeforeLoading &&
                  this.folderIdBeforeLoading != this.selectedFolderObj.folderId
                ) {
                  this.folderIdBeforeLoading = null
                  return
                }
                if (res.length > 0) {
                  res.map((currElement, index) => {
                    let unique = []
                    if (currElement.docSharedWith.length) {
                      currElement.docSharedWith.map(q => {
                        let index = unique.findIndex(data => data.display_name == q.display_name)
                        if (index == -1) {
                          unique.push({ display_name: q.display_name })
                        }
                      })
                      res[index].docSharedWith = []
                      res[index].docSharedWith = unique
                    }
                  })
                  this.globalService.docInfo.next(res)
                } else {
                  this.globalService.docInfo.next([])
                }
                this._pouchDataService.setDocumentsInPouchDb(res, node.id, 'dashboard')
              }
            },
            err => {
              this.globalService.showLoader.next(false)
              console.log('err', err)
            },
          )
      }
    })
  }

  closeMenu = () => {
    this.folderTreeContextMenu = null
  }

  // edit() {
  //   this.editNode = this.folderTreeContextMenu.node;

  //   const dialogRef = this.dialog.open(RenameDialogComponent, {
  //     width: '500px',
  //     disableClose: true,
  //     data: { name: this.editNode.data.name }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.editNode.data.name = result;
  //       this.stopEdit();
  //     }
  //   });
  // }

  stopEdit = () => {
    let folderObj = {
      folderId: this.editNode.data.id,
      folderName: this.editNode.data.name,
    }

    this._folderService.rnFolder(folderObj).subscribe(
      res => {
        this.editNode.treeModel.update()
        this.closeMenu()
        this.editNode = null
        // this.snackBar.open('Folder renamed.', '', { duration: 3000 });
        this.notification.create('success', null, 'Folder renamed.', {
          nzPlacement: 'bottomRight',
        })
      },
      err => {
        this.closeMenu()
        this.editNode = null
      },
    )
  }

  // removeFolder() {
  //   this.sourceNode = this.folderTreeContextMenu.node.parent;
  //   let folderId = this.folderTreeContextMenu.node.data.id;
  //   const dialogRef = this.dialog.open(DeleteFolderDialogComponent, {
  //     width: '500px',
  //     disableClose: true,
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       let folderObj = { folderId: [folderId] };
  //       this._folderService.trashFolder(folderObj).subscribe( async res => {
  //         this.updateTrashTree(folderId, this.sourceNode);
  //         let trashedNode = [];
  //         let fObj = await Promise.all(this.folderObj.map(v => {
  //           if (v.id == folderId) trashedNode.push(v);
  //         }));
  //         // this._pouchDataService.updateFolderInPouchDb(trashedNode, 'trash');
  //         // this.snackBar.open("Folder trashed.", '', { duration: 3000 });
  //       }, err => { })
  //     }
  //   });
  // }

  // add() {
  //   let folderId = this.folderTreeContextMenu.node.data.id;
  //   this.currentNode = this.folderTreeContextMenu.node;
  //   const dialogRef = this.dialog.open(CreateFolderDialogComponent, {
  //     width: '500px',
  //     disableClose: true,
  //     data: { name: 'Untitled folder' }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       let workspaceId = (this.currentNode.data.workspaceId > 0) ? this.currentNode.data.workspaceId : (this.currentNode.data.isWorkspace == 1 ? this.currentNode.data.id : 0)
  //       let folderObj = {
  //         folderId: folderId,
  //         folderName: result,
  //         isWorkspace: 0,
  //         workspaceId: workspaceId
  //       };
  //       this._folderService.mkFolder(folderObj).subscribe(res => {
  //         this.updateTree(res, this.currentNode);
  //         this.closeMenu();
  //         // this.snackBar.open("Folder created.", '', { duration: 3000 });
  //       }, err => { this.closeMenu(); })
  //     }
  //   });
  // }

  get folderName() {
    return this.folderForm.get('folderName')
  }

  get editFolderName() {
    return this.editFolderForm.get('folderName')
  }

  get weblinkName() {
    return this.weblinkForm.get('weblinkName')
  }
  get webproofName() {
    return this.webproofForm.get('webproofName')
  }

  isValid() {
    return (
      (!this.folderName.valid && this.folderName.touched) ||
      (this.folderName.untouched && this.formSumitAttempt)
    )
  }

  isWeblinkValid() {
    return (
      (!this.weblinkName.valid && this.weblinkName.touched) ||
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
        this.weblinkName.value,
      ) ||
      (this.weblinkName.untouched && this.formSumitAttempt)
    )
  }
  iswebproofValid() {
    return (
      (!this.webproofName.valid && this.webproofName.touched) ||
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
        this.webproofName.value,
      ) ||
      (this.webproofName.untouched && this.formSumitAttempt)
    )
  }

  updateTree(folderRes, currentNode) {
    return
    // this._pouchDataService.updateFolderInPouchDb(folderRes, 'insert');
    if (this.selectedFolderObj.folderId == currentNode.data.id) {
      if (currentNode.data.children) {
        this.folderObj.push(folderRes)
        this.globalService.folderObj.next(this.folderObj)
        currentNode.data.children = this.folderObj
        this.sortOrder('date')
        currentNode.treeModel.update()
      } else {
        if (this.folderObj.length > 0) {
          this.folderObj.push(folderRes)
          this.globalService.folderObj.next(this.folderObj)
          this.sortOrder('date')
          currentNode.treeModel.update()
        } else {
          currentNode.data.children = []
          currentNode.data.children.push(folderRes)
          this.globalService.folderObj.next(currentNode.data.children)
          this.sortOrder('date')
          currentNode.treeModel.update()
        }
      }
    }
  }

  async updateTrashTree(folderId, currentNode) {
    return
    if (currentNode) {
      if (currentNode.data.children) {
        let fObj = await currentNode.data.children.map(v => {
          if (folderId != v.id) return v
        })
        let folderObj = await fObj.filter(function(element) {
          return element !== undefined
        })
        currentNode.data.children = folderObj
        this.globalService.folderObj.next(folderObj)
        await currentNode.treeModel.update()
        this.closeMenu()
      }
    }
  }

  // getChildrenCount(folderId) {
  //   this._folderService.getChildren({ folderId: folderId })
  //     .subscribe(
  //       res => {
  //         this.position = res.length;
  //       },
  //       err => {

  //       }
  //     )
  // }

  onMoveNode($event) {
    return
    let folderObj = {
      folderId: $event.node.id,
      parentId: $event.to.parent.id,
    }

    this._folderService.mvFolder(folderObj).subscribe(
      () => {
        this.tree.treeModel.update()
        this.closeMenu()
      },
      err => {
        this.closeMenu()
      },
    )
  }

  onClickedOutside(e: Event) {
    this.closeMenu()
  }

  clickOutsideCard() {
    this._dataService.resetAllObservable()
  }

  sortOrder(name) {
    let folderKey = ''
    let docKey = ''
    if (name == 'name') {
      folderKey = 'name'
      docKey = 'documentTitle'
      this.nameOrder = true
      this.dateOrder = false
    } else if (name == 'date') {
      folderKey = 'lastModified'
      docKey = 'modifiedOn'
      this.nameOrder = false
      this.dateOrder = true
    }
    let fObj = this.folderObj
    let sortedFolder = orderBy(fObj, [v => v[folderKey]], [this.order ? 'asc' : 'desc'])
    this.folderObj = sortedFolder

    this.globalService.folderObj.next(this.folderObj)
    //this.globalService.docInfo.next(this.docInfo);
  }

  addFolder() {
    if (this.selectedFolder == 'all') {
      this.selectedFolder = {
        id: 0,
        name: 'My Documents',
        workspaceId: 0,
        isWorkspace: false,
      }
    }
    let workspaceId =
      this.selectedFolder.workspaceId > 0
        ? this.selectedFolder.workspaceId
        : this.selectedFolder.isWorkspace == 1
        ? this.selectedFolder.id
        : 0
    const addWorkspaceFolderRef = this.modalService.create({
      nzTitle: 'New Folder',
      nzContent: CreateFolderComponent,
      nzComponentParams: {
        data: {
          name: 'Untitled Folder',
          type: 'folder',
          folderId: this.selectedFolder.id,
          workspaceId: workspaceId,
          isWorkspace: 0,
        },
      },
      nzFooter: null,
      nzWidth: 500,
    })
    addWorkspaceFolderRef.afterClose.subscribe(result => {
      if (result) {
        this.updateTree(result, this.currentNode)
        this.closeMenu()
      }
    })
  }

  editFolder() {
    // this.editNode = this.folderTreeContextMenu.node
    const renameWorkspaceFolderRef = this.modalService.create({
      nzTitle: this.selectedFolder.isWorkspace ? 'Rename Workspace' : 'Rename Folder',
      nzContent: RenameFolderComponent,
      nzComponentParams: {
        data: {
          name: this.selectedFolder.name,
          id: this.selectedFolder.id,
          workspace: this.selectedFolder.isWorkspace,
        },
      },
      nzFooter: null,
      nzWidth: 500,
    })
    renameWorkspaceFolderRef.afterClose.subscribe(result => {
      if (result) {
        this.selectedFolder.name = result
        // this.editNode.treeModel.update()
        this.closeMenu()
        this.editNode = null
      } else {
        this.closeMenu()
        this.editNode = null
      }
    })
  }

  removeFolder() {
    this.sourceNode = this.folderTreeContextMenu.node.parent
    const folderId = this.folderTreeContextMenu.node.data.id

    const removeWorkspaceFolderRef = this.modalService.create({
      nzTitle: null,
      nzContent: RemoveFileComponent,
      nzComponentParams: {
        data: {
          title: 'Delete File',
          content: `Are you sure you wish to delete this document. When you delete this document, it will be removed from dashboard of all users that have access to this document?`,
        },
      },
    })
    removeWorkspaceFolderRef.afterClose.subscribe(result => {
      if (result) {
        const folderObj = { folderId: [folderId] }
        this._folderService.trashFolder(folderObj).subscribe(
          async res => {
            this.notification.create('success', null, 'Folder trashed.', {
              nzPlacement: 'bottomRight',
            })
            this.updateTrashTree(folderId, this.sourceNode)
            const trashedNode = []
            const fObj = await Promise.all(
              this.folderObj.map(v => {
                if (v.id === folderId) {
                  trashedNode.push(v)
                }
              }),
            )
          },
          err => {},
        )
      }
    })
  }

  getData(treeNode): void {
    const folderobj = treeNode.data.children
    this._pouchDataService
      .getAllDocumentDataFromPouchDB()
      .then(fileobj => {
        if (fileobj) {
          for (const folder of folderobj) {
            const matchingFiles = fileobj.filter(file => file.folderId === folder.id)
            if (matchingFiles.length > 0) {
              const approvedFiles = matchingFiles.filter(file => file.wfStatusName === 'Approved')
              const reworkFiles = matchingFiles.filter(file => file.wfStatusName === 'Rework')
              folder.isAllApproved = approvedFiles.length === matchingFiles.length
              folder.isAllRejected = reworkFiles.length === matchingFiles.length
              folder.isMixedStaus =
                (!folder.isAllApproved && approvedFiles.length > 0) ||
                (!folder.isAllRejected && reworkFiles.length > 0)
            }
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
