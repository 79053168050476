<div style="overflow: visible;
max-height: 96vh;" [ngClass]="{
  'vb__layout__grayBackground': settings.isGrayBackground,
  'mobile-overflow': settings.isMobileView
}">
  <nz-layout [ngClass]="{
  'vb__layout': true,
  'vb__layout__contentMaxWidth': settings.isContentMaxWidth,
  'vb__layout__appMaxWidth': settings.isAppMaxWidth,
  'vb__layout__squaredBorders': settings.isSquaredBorders,
  'vb__layout__cardsShadow': settings.isCardShadow,
  'vb__layout__borderless': settings.isBorderless
}">
<qr-notification-modal></qr-notification-modal>
    <qr-variants></qr-variants>
    <qr-sidebar></qr-sidebar>
    <!-- <vb-support-chat></vb-support-chat> -->

    <!-- classic left menu -->
    <!-- && !settings.isMobileView if want to use it in future then add it after 'left  ' -->
    <nz-sider *ngIf="settings.layoutMenu === 'classic' && settings.menuLayoutType === 'left' && !settings.isMobileView "
      [nzWidth]="settings.isMenuCollapsed ? 80 : settings.leftMenuWidth" style="transition: none !important">
      <qr-classic-menu-left></qr-classic-menu-left>
    </nz-sider>
    <!-- <div *ngIf="settings.layoutMenu === 'classic' && settings.isMobileView">
      <div class="handler" (click)="toggleMobileMenu()">
        <div class="handlerIcon"></div>
      </div>
      <nz-drawer [nzClosable]="false" [nzWrapClassName]="'mobileMenu'" [nzVisible]="isMobileMenuOpen" nzPlacement="left"
        (nzOnClose)="toggleMobileMenu()">
        <vb-menu-classic-left></vb-menu-classic-left>
      </nz-drawer>
    </div> -->
    <div *ngIf="settings.layoutMenu === 'classic' && settings.menuLayoutType === 'top' && !settings.isMobileView">
      <qr-classic-menu-top></qr-classic-menu-top>
    </div>
    

    <!-- flyout left menu -->
    <!-- <nz-sider *ngIf="settings.layoutMenu === 'flyout' && settings.menuLayoutType === 'left'" [nzWidth]="'auto'">
      <vb-menu-flyout-left></vb-menu-flyout-left>
    </nz-sider> -->

<!-- for mobile view sidebar -->
    <ng-container *ngIf="settings.isMobileView">
      <qr-flyout-menu-left></qr-flyout-menu-left>
    </ng-container>


    <qr-flyout-menu-top *ngIf="settings.layoutMenu === 'flyout' && settings.menuLayoutType === 'top'">
    </qr-flyout-menu-top>

    <!-- simply menu -->
    <!-- <vb-menu-simply *ngIf="settings.layoutMenu === 'simply'"></vb-menu-simply> -->

    <nz-layout>
      
      <nz-header  *ngIf="settings.layoutTopbar === 'v1'" [ngClass]="{
        'vb__layout__header': true,
        'vb__layout__fixedHeader': settings.isTopbarFixed,
        'vb__layout__headerGray': settings.isGrayTopbar,
        'vb__layout__separatedHeader': settings.isTopbarSeparated
      }"> 

        <qr-topbar></qr-topbar>
      
        
        <!-- <div class="row topbar">
          <div class="offset-10 col-sm-1 text-right pr-0">
            <vb-topbar-actions></vb-topbar-actions>
          </div>
          <div class="col-sm-1 text-right pl-0">
            <vb-topbar-user-menu></vb-topbar-user-menu>
          </div>
        </div> -->
      </nz-header>
  
      <qr-breadcrumbs *ngIf="settings.layoutBreadcrumbs === 'v1'"></qr-breadcrumbs>
      <!-- <vb-breadcrumbs-2 *ngIf="settings.layoutBreadcrumbs === 'v2'"></vb-breadcrumbs-2> -->
      <div class="overflow" style="overflow-x: hidden;">
        <nz-content class="vb__layout__content pt-0 pb-0">
          <div [@slideFadeinRight]="routeAnimation(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
            
          </div>
        </nz-content>
      </div>
      <!-- <nz-footer *ngIf="settings.layoutFooter === 'v1'">
        <vb-footer></vb-footer>
      </nz-footer> -->
      <!-- <nz-footer *ngIf="settings.layoutFooter === 'v2'">
        <vb-footer-2></vb-footer-2>
      </nz-footer>
      <nz-footer *ngIf="settings.layoutFooter === 'v3'">
        <vb-footer-3></vb-footer-3>
      </nz-footer>
      <nz-footer *ngIf="settings.layoutFooter === 'v4'">
        <vb-footer-4></vb-footer-4>
      </nz-footer> -->
    </nz-layout>
  </nz-layout>
</div>
<qr-loader *ngIf="showLoader"></qr-loader>