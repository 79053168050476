<nz-sider nzCollapsible [nzWidth]="leftMenuWidth" [(nzCollapsed)]="isMenuCollapsed && !isMobileView"
  (nzCollapsedChange)="onCollapse($event)" [ngClass]="{
  menu: true,
  'bg-white': menuColor === 'gray' || menuColor === 'white',
  dark: menuColor === 'dark',
  unfixed: isMenuUnfixed,
  shadow: isMenuShadow && !isMobileView
}">

  <div class="menuOuter" [ngStyle]="{
    width: isMenuCollapsed ? '80px' : leftMenuWidth + 'px',
    height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)'
  }">
    <div class="logoContainer">
      <div class="logo">
        <!-- <a href="" [ngSwitch]="menuColor" *ngIf="!customUrlLogo && !isTeamUrl">
          <ng-container *ngSwitchCase="'dark'">
            <img *ngIf="isUserSetting == 2" src="assets/images/logo/qr-product-logo.png" alt="">
            <img *ngIf="isUserSetting == 1"
              src="{{apiUrl}}/logo-dark/{{userSetting.user_id}}/{{userSetting.setting_id}}/''?{{dynamicDarkLogoNumber}}" alt="">
          </ng-container>
          <ng-container *ngSwitchDefault>
            <img *ngIf="isUserSetting == 1" src="{{apiUrl}}/logo/{{userSetting.user_id}}/{{userSetting.setting_id}}/''?{{dynamicLogoNumber}}"
              alt="">
            <img *ngIf="isUserSetting == 2" (error)="errorHandler($event)" src="{{src}}" alt="">
          </ng-container>

        </a> -->
        <a href="">
          <img src="{{src}}" *ngIf="menuColor != 'dark'" (error)="errorHandler($event)" alt="">
          <img src="{{darkSrc}}" *ngIf="menuColor == 'dark'" (error)="errorDarkImageHandler($event)" alt="">
        </a>
      </div>
    </div>
    <div class="nz-divider">
      <nz-divider></nz-divider>
    </div>

    <perfect-scrollbar class="border-right">
      <button class="mt-3 newBtn ant-new-btn" [class.btn-width]="isMenuCollapsed" nzTrigger="click" nz-button
        nz-dropdown [nzDropdownMenu]="menu" *ngIf="!isReviewer" nzPlacement="bottomLeft" nz-tooltip nzTooltipTitle="New"
        (click)="_dataService.resetAllObservable()">
        <span class="btn-text">
          <i class="fe fe-plus"></i><span class="ml-0" *ngIf="!isMenuCollapsed">New</span>
        </span>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="newDropdownMenu">
          <li nz-menu-item *ngIf="!isTeamUrl"
            (click)="currentPage == 'dashboard' && userPermission.allowWorkspace && createFolder('workspace', 1)"
            [nzDisabled]="selectedFolderObj.folderId !== 0 || currentPage != 'dashboard'"
            [ngClass]="{'opacity':currentPage != 'dashboard', 'opacity2': !userPermission.allowWorkspace, 'isAuthorized': selectedFolderObj.folderId !== 0}">
            <i class='fe fe-airplay mr-3'></i>
            Workspace </li>
          <li
            [nzDisabled]="currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer"
            [ngClass]="{'opacity':currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer}"
            nz-menu-item (click)="createFolder('folder', 0)"> <i class="fe fe-folder mr-3"></i> Folder
          </li>
          <li
            [nzDisabled]="currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer"
            [ngClass]="{'opacity':currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer}"
            nz-menu-item
            (click)="(currentPage == 'dashboard' && teamAllowedSpace) || (currentPage == 'dashboard' && isReviewer) || (currentPage == 'dashboard' && selectedFolderObj.folderId > 0) ? fileInput.click() : ''">
            <i class="fe fe-upload mr-3"></i> File Upload
          </li>
          <li
            [nzDisabled]="currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer"
            [ngClass]="{'opacity':currentPage != 'dashboard' || (selectedFolderObj.folderId == 0 && !teamAllowedSpace) || isReviewer}"
            nz-menu-item (click)="createWebproof()"> <i class="fe fe-layers mr-3"></i> Website</li>
        </ul>
      </nz-dropdown-menu>
      <div class="ml-4">
      </div>
      <div class="mt-3" *ngIf="isReviewer"></div>
      <ul qrTreeToggle nz-menu class="navigation" [nzInlineIndent]="15" [nzMode]="'inline'"
        [nzInlineCollapsed]="isMenuCollapsed && !isMobileView" (click)="clickOutsideCard()">
        <ng-container *ngTemplateOutlet="menuTpl; context: {$implicit: menuDataActivated, level: 0}"></ng-container>
        <ng-template #menuTpl let-menuDataActivated let-level="level">
          <ng-container *ngFor="let item of menuDataActivated">
            <ng-container *ngIf="!item.roles || item.roles.includes(role)">
              <ng-template #tooltipTpl>
                {{ item.title }}

              </ng-template>
              <!-- group title item -->
              <li nz-menu-group [nzTitle]="item.title" *ngIf="item.category"></li>
              <!-- disabled item -->
              <li nz-menu-item *ngIf="item.disabled" [nzSelected]="item.selected" [nzDisabled]="true" nz-tooltip
                nzTooltipPlacement="right" [nzTooltipTitle]="isMenuCollapsed && level === 0 ? tooltipTpl : ''">
                <span [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
              </li>
              <!-- main item -->
              <li nz-menu-item
                [ngClass]="{'d-none-Dropbox': item.title=='Dropbox' ? (isAuthenticatedDropBox == false || teamAllowedSpace == false ? true: false) : false,
                'd-none-Youtube': item.title=='YouTube' ? (isAuthenticatedYoutube == false ? true: false) : false, 
                'd-none-Box': item.title=='Box' ? (isAuthenticatedBox == false ? true: false) : false, 
                'isAuthorized': (item.title == 'Team' ? !userPermission.allowTeam :  false)|| ( item.title == 'Whitelabel' ? !userPermission.allowWhitelabel : false) || ( item.title == 'Workflow' && (userPermission?.allowWorkFlow < 1 && loginType == 'owner') ? true : false)}"
                *ngIf="!item.children && item.url && !item.notForTeam  && ((item.title != 'Support / Feedback') || (item.title == 'Support / Feedback' && currentUrl.includes('quickreviewer.com'))) || ( item.notForTeam && item.notForTeam !== isTeamUrl)"
                routerLink={{item.url}} [nzSelected]="item.selected" [nzDisabled]="item.disabled" nz-tooltip
                nzTooltipPlacement="right" [nzTooltipTitle]="isMenuCollapsed && level === 0 ? tooltipTpl : ''">
                <span [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}"
                  class="d-inline-flex align-items-center justify-content-center">
                  <span class="title"
                    (click)="item.title == 'My Documents' ? _dataService.dashboardDocument() : false">{{item.title}}</span>
                  <button [disabled]="!trashDocInfo && !trashFolderInfo" type="button"
                    *ngIf="item.url=='/dashboard/trash'" class="ant-btn empty-btn px-2" (click)="emptyTrash($event)">
                    Empty
                  </button>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon && item.title != 'Dropbox' && item.title != 'Box'  && item.title != 'Workflow'" [ngClass]="[item.icon, 'icon']"></i>
                  <i class="icon dropbox-icon" *ngIf="item.title == 'Dropbox'"></i>
                  <img *ngIf="item.title == 'Box'" class="icon " src="assets/images/logo/box_logo.svg">
                  <img *ngIf="item.title == 'Workflow' && this.settings.theme == 'default'" class="icon " src="assets/workflow.svg">
                  <img *ngIf="item.title == 'Workflow' &&  this.settings.theme != 'default'" class="icon " src="assets/workflowDark.svg">
                </span>
              </li>
              <nz-divider *ngIf="item.url=='/dashboard/trash'"></nz-divider>
              <li class="treeComponent" nz-menu-item *ngIf="item.children && !isMenuCollapsed"
                nz-tooltip nzTooltipPlacement="right"
                [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}"
                [nzTooltipTitle]="isMenuCollapsed ? 'My Documents' : ''" [ngClass]="{'item-selected':router.url == '/dashboard/documents'}"
                style="height: auto;">
                <qr-folder-tree></qr-folder-tree>
              </li>
              <li nz-submenu *ngIf="item.children && isMenuCollapsed" [nzOpen]="item.open && !isMenuCollapsed"
                routerLink={{item.url}} [nzSelected]="item.selected" nz-tooltip nzTooltipPlacement="right"
                [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}"
                [nzTooltipTitle]="isMenuCollapsed ? 'My Documents' : ''"
                (click)="item.title == 'My Documents' ? _dataService.dashboardDocument() : false">
                <span (contextmenu)="openContextDialog(myDocument, $event, folderMenu, true)" title
                  [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
              </li>
              <nz-dropdown-menu #folderMenu="nzDropdownMenu">
                <ul nz-menu *ngIf="currentNode">
                  <li (click)="addFolder()" nz-menu-item><i class="fa fa-plus mr-3"></i>New Folder</li>
                  <li (click)="editFolder()" nz-menu-item
                    *ngIf="currentNode.title !='My Documents' && currentNode.userId==nodes[0].userId"><i
                      class="fa fa-pencil mr-3"></i>Rename Folder</li>
                  <li (click)="removeFolder()" nz-menu-item
                    *ngIf="currentNode.title !='My Documents' && currentNode.userId==nodes[0].userId"><i
                      class="fa fa-trash mr-3"></i>Remove Folder</li>
                </ul>
              </nz-dropdown-menu>
            </ng-container>
          </ng-container>
        </ng-template>
        <nz-divider></nz-divider>
      </ul>
      <div class="storage">
        <qr-storage [isMenuCollapse]="isMenuCollapsed"></qr-storage>
      </div>
    </perfect-scrollbar>
  </div>
</nz-sider>

<input hidden type="file" id="sidebar-file" #fileInput multiple
  accept=".pdf,.tiff,.eps,.jpeg,.jpg,.png,.webm,.mp4,.mov,.avi,.zip,.psd,.ai,.pcm,.wav,.mp3,.aiff,.aac,.ogg,.wma,.m4a,.gif,.ppt,.pptx,.doc,.docx,
  .glb,.gltf,.stl,.fbx,.obj,.usdz"
  (change)="addFileToUploadQueue($event.target.files)">