<div>
    <div>
        <!-- <nz-badge [nzCount]="badgeCount"> -->
        <i class="icon fe fe-bell" nzTooltipTitle="Announcements" nzTooltipPlacement="bottom" nz-button nz-tooltip
            (click)="notificationFn($event)"></i>
        <!-- </nz-badge> -->
    </div>


    <qr-announcement></qr-announcement>
</div>

<!-- 
  <div>
    <div nz-dropdown (click)="open()" (nzVisibleChange)="badgeCountIncrease()"> -->
<!-- <nz-badge [nzCount]="badgeCount"> -->
<!-- <i class="icon fe fe-bell mt-2"></i> -->
<!-- </nz-badge> -->
<!-- </div>
    <div class="abc">
    <nz-drawer [nzClosable]="false" mask="false" maskClosable="true" [nzVisible]="visible" nzPlacement="right"   (nzOnClose)="close()">
      <ng-container *nzDrawerContent>
          <vb-widgets-lists-2></vb-widgets-lists-2>
    </ng-container>
  </nz-drawer>
  </div>
  </div> -->