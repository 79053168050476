import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as base64 from 'base-64'
import { SamloktaService } from 'src/app/services/samlokta.service';
@Component({
  selector: 'qr-samlokta',
  templateUrl: './samlokta.component.html',
  styleUrls: ['./samlokta.component.scss']
})
export class SamloktaComponent {
  constructor(
    private smaloktaService: SamloktaService, private route: ActivatedRoute,) {

  }
  ngOnInit() {
    
    if (window.location.hash.includes('/saml')) {
      this.route.queryParams.subscribe(params => {
        let email = params['email'];
         email =  base64.decode(email)
        if(email){
        this.smaloktaService.parseAccessToken(email)
        }
      });
    } else {
      // this.authZero.logout()
      // this.router.navigate([window.location.origin])
    }
  }
}
