import { Component, OnInit, ElementRef, HostListener, Renderer2 } from '@angular/core'
import { GlobalService } from 'src/app/services/common/global.service'
import { DOCUMENT } from '@angular/common'
import { Observable, fromEvent } from 'rxjs'
import { map } from 'rxjs/operators'
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router'
import { DocumentService } from 'src/app/services/document.service'

@Component({
  selector: 'qr-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {
  public text: String
  mouseover: boolean
  stopApi = false
  isLoadingResults = false
  allNotification = []
  notification: boolean
  notificationCheck: any
  page: number = 1
  // @HostListener('document:mouseover', ['$event'])
  // clickout(event) {
  //   if(this.eRef.nativeElement.contains(event.target)) {
  //     this.text = " inside";
  //   } else {
  //     this.text = " outside";
  //   }
  // }

  constructor(
    private globalService: GlobalService,
    private _docService: DocumentService,
    private renderer: Renderer2,
    private router: Router,
    public eRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this._docService.notificationList({ page: this.page }).subscribe((res: any) => {
      if (res.length) {
        this.allNotification = res
      } else {
        this.stopApi = true
      }
    })
    // const content = document.querySelector('.notification');
    // const scroll = fromEvent(content, 'scroll').pipe(map(() => content));
    // scroll.subscribe(element => {
    //   if (element.scrollTop + window.innerHeight >= element.scrollHeight) {
    //     if (this.stopApi == false) {
    //       this.stopApi = true
    //       this.page = this.page + 1
    //       this.isLoadingResults=true
    //       this._docService.notificationList({ page: this.page }).subscribe((res: any) => {
    //         if (res.length) {
    //           if(res.length==10)
    //             this.stopApi = false;
    //           else
    //             this.stopApi = true;
    //           this.allNotification = this.allNotification.concat(res)
    //           this.isLoadingResults=false;
    //         } else {
    //           this.stopApi = true
    //         }
    //       })
    //     }
    //   }
    // });
    this.onWindowScroll()
    this.globalService.notification.subscribe(notification => (this.notification = notification))
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const content = document.querySelector('.notification')
    const scroll = fromEvent(content, 'scroll').pipe(map(() => content))
    scroll.subscribe(element => {
      if (element.scrollTop + window.innerHeight >= element.scrollHeight) {
        if (this.stopApi == false) {
          this.stopApi = true
          this.page = this.page + 1
          this.isLoadingResults = true
          this._docService.notificationList({ page: this.page }).subscribe((res: any) => {
            if (res.length) {
              if (res.length == 10) this.stopApi = false
              else this.stopApi = true
              this.allNotification = this.allNotification.concat(res)
              this.isLoadingResults = false
            } else {
              this.stopApi = true
            }
          })
        }
      }
    })
  }
  // ngDoCheck() {

  //   if (this.notificationCheck == '') {
  //     if (this.text == 'outside') {

  //       this.globalService.notification.next(false)

  //     }

  //   }
  // }
  onClickSetDetailView() {
    this.globalService.notification.next(false)
    this.isLoadingResults = false
  }
}
