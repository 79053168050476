import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import moment from 'moment'
import { differenceInCalendarDays } from 'date-fns'
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker'
import { trigger, state, style, transition, animate } from '@angular/animations'

@Component({
  selector: 'qr-set-expiry',
  templateUrl: './set-expiry.component.html',
  styleUrls: ['./set-expiry.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: 'auto',
        opacity: 1,
        marginTop:'20px'
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
        marginBottom : '0px',
        marginTop : '0px'
      })),
      transition('open <=> closed', [
        animate('1s')
      ]),
    ])
  ]
})
export class SetExpiryComponent implements OnInit {
  @Input() data: any
  startDate: Date = new Date()
  today = new Date();

  reminderCounter = [
    { key: '  23  ', value: 23 },
    { key: '  22  ', value: 22 },
    { key: '  21  ', value: 21 },
    { key: '  20  ', value: 20 },
    { key: '  19  ', value: 19 },
    { key: '  18  ', value: 18 },
    { key: '  17  ', value: 17 },
    { key: '  16  ', value: 16 },
    { key: '  15  ', value: 15 },
    { key: '  14  ', value: 14 },
    { key: '  13  ', value: 13 },
    { key: '  12  ', value: 12 },
    { key: '  11  ', value: 11 },
    { key: '  10  ', value: 10 },
    { key: '  09  ', value: 9 },
    { key: '  08  ', value: 8 },
    { key: '  07  ', value: 7 },
    { key: '  06  ', value: 6 },
    { key: '  05  ', value: 5 },
    { key: '  04  ', value: 4 },
    { key: '  03  ', value: 3 },
    { key: '  02  ', value: 2 },
    { key: '  01  ', value: 1 },
    { key: '  00  ', value: 0 },
  ]
  reminderKeys = [
    { key: '  Day  ', value: 'day' },
    { key: '  Hour  ', value: 'hour' },
  ]

  @ViewChild('neverExpire') neverExpire: ElementRef
  constructor(public modal: NzModalRef) {}

  ngOnInit(): void {
    if (this.data.neverExpire != 1) {
      this.data.neverExpire = false
      if (this.data.expiryDate != null || this.data.expiryDate != 0) {
        let date = new Date(this.data.expiryDate * 1000)
        let day = date.getDate()
        let month = date.getMonth()
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        this.data.expiryDate = new Date(year, month, day, hours, minutes)
        this.startDate = this.data.expiryDate
      } else {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth()
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        this.data.expiryDate = new Date(year, month, day, hours, minutes)
        this.data.neverExpire = true
      }
    }
    // data?.reminderSetting.reminder
    this.data.reminderSetting.reminderType =
    this.data == null || this.data?.reminderSetting.reminderType != null
      ? this.data?.reminderSetting.reminderType
      : "day"
    this.data.reminderSetting.reminderSett.reminder1 =
      this.data == null || this.data?.reminderSetting.reminderSett.reminder1 != null
        ? Number(this.data?.reminderSetting.reminderSett.reminder1)
        : 0
    this.data.reminderSetting.reminderSett.reminder2 =
      this.data == null || this.data?.reminderSetting.reminderSett.reminder2 != null
        ? Number(this.data?.reminderSetting.reminderSett.reminder2)
        : 0
    this.data.reminderSetting.reminderSett.reminder3 =
      this.data == null || this.data?.reminderSetting.reminderSett.reminder3 != null
        ? Number(this.data?.reminderSetting.reminderSett.reminder3)
        : 0
    this.data.reminderSetting.reminderOn =
      this.data == null || this.data?.reminderSetting.reminderOn != null
        ? this.data?.reminderSetting.reminderOn.toString()
        : '1'
    this.data.reminderSetting.reminder =
      this.data == null || this.data?.reminderSetting.reminder != null
        ? this.data?.reminderSetting.reminder
        : false

    if (this.data.neverExpire) {
      this.data.reminderSetting.reminder = false
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.neverExpire.nativeElement.focus()
    }, 500)
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.modal.destroy(false)
    }
  }
  onKey(val): void {
    this.modal.destroy(val)
  }
  onKeyEsc(): void {
    this.modal.destroy(false)
  }

  handleNeverExpire() {
    if (!this.data.neverExpire) {
      this.data.reminderSetting.reminder = false
    }
  }

  reminderModeChange(){
    this.data.reminderSetting.reminderSett.reminder1 = 0;
    this.data.reminderSetting.reminderSett.reminder2 = 0;
    this.data.reminderSetting.reminderSett.reminder3 = 0
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) < 0;

  disabledDateTime: DisabledTimeFn = (current: Date) => ({
    nzDisabledHours: () => this.disabledHours(current), // hours
    nzDisabledMinutes: () => this.disabledMinutes(current),
    nzDisabledSeconds: () => [0, 60]
  });

  disabledHours = (current) => {
    let isToday = differenceInCalendarDays(current, this.today);
    let hours = [];
    if (isToday == 0) {
      for (let i = 0; i < moment().hour(); i += 1) hours.push(i);
    } else hours = [];
    return hours;
  };

  disabledMinutes(current) {
    let isToday = differenceInCalendarDays(current, this.today);
    let minutes = [];
    if (isToday == 0) {
      for (let i = 0; i < moment().minute(); i += 1) minutes.push(i);
    } else minutes = [];
    return minutes;

  }

}
