import { Injectable } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { GlobalService } from './common/global.service'
import { environment } from 'src/environments/environment'
import { DocumentService } from './document.service'
import { DataService } from './data.service'
import { DeleteFileDialogComponent } from '../pages/delete-file-dialog/delete-file-dialog.component'
import { PouchDataService } from './pouch-data.service'

import * as mime from 'mime-types'
import * as path from 'path'
import * as bytes from 'bytes'
import Resumable from 'resumablejs'
import $ from 'jquery'

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private apiUrl = environment.apiUrl

  URL = this.apiUrl + '/upload'
  fileUploadUrl = environment.fileUploadUrl

  docInfo: any
  selectedFolderObj: any
  selectedDocumentArr: any
  currentVersion: number = 1
  currentDocument: any = null
  currentPage: string
  currentUser: any
  customAppSetting: any
  totalSpace: number
  usedSpace: number
  usedPercentage: number
  host = window.location.hostname
  proto = window.location.protocol
  resumableUploadQueue: any = []
  uploadContainer: any
  uploadFolder: any
  uploadUrl: string
  requestObject: any = {}
  interval: any
  cancelledItem: any = []
  progressIterations = 0
  uploadTimeRemaining: any = 'Calculating...'
  checkSpeedTimer: any
  internetSpeed: any = 'Calculating'
  isReviewer: boolean = false
  isTeamUrl: boolean = false
  teamAllowedSpace: boolean = true
  canUploadToSharedWorkspace: boolean = true
  resumableUpload = new Resumable({
    testChunks: true,
    //(this.host=='localhost' ? this.proto+'//'+this.host+'/file-upload/upload.php': this.proto+'//'+this.host+'/file-upload/upload.php'),
    target: this.fileUploadUrl,
    fileType: [
      'pdf',
      'tif',
      'tiff',
      'eps',
      'jpeg',
      'jpg',
      'png',
      'webm',
      'mkv',
      'mov',
      'mp4',
      'avi',
      'zip',
      'psd',
      'ai',
      'pcm',
      'wav',
      'mp3',
      'aiff',
      'aac',
      'ogg',
      'wma',
      'm4a',
      'gif',
      'ppt',
      'pptx',
      'doc',
      'docx','glb','gltf','stl','fbx','obj','usdz'
    ],
    maxFileSize:
      this.host == 'iframe.nixieonline.in'
        ? 1024 * 1024 * 1024 * 10
        : environment.maxFileUploadSize, //5 GB
    uploadMethod: 'POST',
    chunkSize: 1024 * 1024 * 20, //10 MB
    chunkRetryInterval: 5000,
    simultaneousUploads: 1,
    generateUniqueIdentifier: (file, event) => {
      return (file.parentFolderId || this.uploadFolder.folderId) + '-' + file.name
    },
    fileTypeErrorCallback: (file, errorCount) => {
      this.dialog.open(DeleteFileDialogComponent, {
        width: '500px',
        maxWidth: '95vw',
        disableClose: true,
        data: {
          title: `Incorrect file type (${file.type})`,
          content: `This file type is not supported. You can upload the following formats only \n\n AAC, AI, AIFF, AVI, EPS, GIF, JPEG, M4A, MKV, MOV, MP3, MP4, OOG, PCM, PDF, PNG, PSD, TIFF, WAV, WEBM, WMA, ZIP, PPT, PPTX, doc , docx, docx, glb, gltf,stl, fbx, obj, usdz`,
        },
      })
    },
    maxFileSizeErrorCallback: (file, errorCount) => {
      this.dialog.open(DeleteFileDialogComponent, {
        width: '500px',
        maxWidth: '95vw',
        disableClose: true,
        data: {
          title: `File size limit exceeded`,
          content: `The file that you are tring to upload is larger than the allowed limit of ${bytes(
            environment.maxFileUploadSize,
            { unit: 'GB', decimalPlaces: 2, unitSeparator: ' ' },
          )} per file.`,
        },
      })
    },
  })

  constructor(
    private globalService: GlobalService,
    private documentService: DocumentService,
    private dataService: DataService,
    public dialog: MatDialog,
    private _pouchDataService: PouchDataService,
  ) {
    this.globalService.selectedFolderObj.subscribe(
      selectedFolderObj => (this.selectedFolderObj = selectedFolderObj),
    )
    this.globalService.selectedDocumentArr.subscribe(
      selectedDocumentArr => (this.selectedDocumentArr = selectedDocumentArr),
    )
    this.globalService.currentVersion.subscribe(
      currentVersion => (this.currentVersion = currentVersion),
    )
    this.globalService.currentDocument.subscribe(
      currentDocument => (this.currentDocument = currentDocument),
    )
    this.globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    this.globalService.userInfo.subscribe(userInfo => (this.currentUser = userInfo))
    this.globalService.customAppSetting.subscribe(
      customAppSetting => (this.customAppSetting = customAppSetting),
    )
    this.globalService.totalSpace.subscribe(totalSpace => (this.totalSpace = totalSpace))
    this.globalService.usedSpace.subscribe(usedSpace => (this.usedSpace = usedSpace))
    this.globalService.usedPercentage.subscribe(
      usedPercentage => (this.usedPercentage = usedPercentage),
    )
    this.globalService.docInfo.subscribe(docInfo => (this.docInfo = docInfo))
    this.globalService.resumableUploadQueue.subscribe(
      resumableUploadQueue => (this.resumableUploadQueue = resumableUploadQueue),
    )
    this.globalService.uploadContainer.subscribe(
      uploadContainer => (this.uploadContainer = uploadContainer),
    )
    this.globalService.uploadFolder.subscribe(uploadFolder => (this.uploadFolder = uploadFolder))
    this.globalService.isReviewer.subscribe(isReviewer => (this.isReviewer = isReviewer))
    this.globalService.isTeamUrl.subscribe(isTeamUrl => (this.isTeamUrl = isTeamUrl))
    this.globalService.teamAllowedSpace.subscribe(
      teamAllowedSpace => (this.teamAllowedSpace = teamAllowedSpace),
    )

    if (this.fileUploadUrl == null) {
      this.dialog.open(DeleteFileDialogComponent, {
        width: '500px',
        maxWidth: '95vw',
        disableClose: true,
        data: {
          title: `Error`,
          content: `File upload URL not found.`,
          cancel: 'off',
        },
      })
    }

    this.resumableUpload.on('filesAdded', (file, event) => {
      if (file.length == 0) file = event
      if (file.length == 0) return

      if (Object.keys(this.resumableUploadQueue).length < 10) {
        this.checkUploadSpeed(5, (speed, average) => {
          this.internetSpeed = (average / 1024).toFixed(2)
        })
      }
      if (this.uploadContainer == 'sidebar-file') {
        this.newDocumentUpload(file)
      }
    })

    this.resumableUpload.on('fileProgress', (file, message) => {
      if (!navigator.onLine) return
      this.resumableUploadQueue[file.file.uniqueIdentifier].progress =
        Math.floor(file.progress() * 100) || 0
      if (file.progress() >= 0.99)
        this.resumableUploadQueue[file.file.uniqueIdentifier].progressText = 'Scanning...'
      else this.resumableUploadQueue[file.file.uniqueIdentifier].progressText = 'Uploading...'
      if (file.progress() > 0.02)
        this.resumableUploadQueue[file.file.uniqueIdentifier].mode = 'determinate'
      this.globalService.resumableUploadQueue.next(this.resumableUploadQueue)
      this.calculateRemainigUploadTime()
    })

    this.resumableUpload.on('fileSuccess', file => {
      file.fileSuccessFired = true
      this.fileSuccess(file)
      this.clearSpeedTimerInterval()
    })

    this.resumableUpload.on('cancel', (file, message) => {
      console.log('fileError', message, file)
      $('#' + this.uploadContainer).val(null)
      this.resumableUploadQueue = []
      this.globalService.resumableUploadQueue.next(this.resumableUploadQueue)
    })

    this.resumableUpload.on('error', (file, message) => {
      console.log('fileError', message, file)
      this.clearSpeedTimerInterval()
    })

    this.resumableUpload.on('complete', () => {
      console.log('complete')
      this.clearSpeedTimerInterval()
    })

    this.resumableUpload.on('fileRetry', (file, message) => {})
  }

  newDocumentUpload(file) {
    if (this.currentVersion > 1) {
      let mTypeFull = mime.lookup(file[0].file.name)
      let oldTypeFull = mime.lookup(this.selectedDocumentArr[0].documentFileName)
      let mType = mime.lookup(file[0].file.name).split('/')[0]
      let oldMType = mime.lookup(this.selectedDocumentArr[0].documentFileName).split('/')[0]
      let ext = path.extname(file[0].file.name)
      let oldExt = path.extname(this.selectedDocumentArr[0].documentFileName)
      if (mTypeFull.toLowerCase() == 'image/gif') mType = 'gif'
      if (oldTypeFull.toLowerCase() == 'image/gif') oldMType = 'gif'
      if (mTypeFull.toLowerCase() == 'application/zip') mType = 'zip'
      if (oldTypeFull.toLowerCase() == 'application/zip') oldMType = 'zip'
      if (ext.toLowerCase() == '.pdf') mType = 'pdf'
      if (oldExt.toLowerCase() == '.pdf') oldMType = 'pdf'
      if (ext.toLowerCase() == '.zip') mType = 'zip'
      if (oldExt.toLowerCase() == '.zip') oldMType = 'zip'
      

      if (mType != oldMType) {
        this.dialog.open(DeleteFileDialogComponent, {
          width: '500px',
          maxWidth: '95vw',
          disableClose: true,
          data: {
            title: `File type mismatch error`,
            content: `Cannot upload this file as a version. You can only upload a file of type ${oldMType.toLowerCase()}`,
            cancel: 'off',
          },
        })
        this.currentVersion = 1
        this.resumableUpload.removeFile(file)
        return
      }
    }

    if (
      (this.isReviewer && this.isTeamUrl) ||
      (this.isTeamUrl &&
        !this.isReviewer &&
        !this.teamAllowedSpace &&
        this.selectedFolderObj.folderId == 0)
    ) {
      this.dialog.open(DeleteFileDialogComponent, {
        width: '500px',
        maxWidth: '95vw',
        disableClose: true,
        data: {
          title: `Permission denied`,
          content: `You can not upload a file. Please contact your administrator.`,
          cancel: 'off',
        },
      })

      this.resumableUpload.removeFile(file)
      return
    }

    if (this.selectedFolderObj.folderId == 0) {
      localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }

    let workspaceUserId = parseInt(localStorage.getItem('workspaceUserId'))
    workspaceUserId = workspaceUserId > 0 ? workspaceUserId : 0
    let userId = parseInt(localStorage.getItem('userId'))
    this.globalService.canUploadToSharedWorkspace.subscribe(canUpload => {
      this.canUploadToSharedWorkspace = canUpload
    })
    this.globalService.remainingSpace.subscribe(remainingSpace => {
      let size = file[0].file.size / 1024
      this.canUploadToSharedWorkspace =
        size > remainingSpace && workspaceUserId > 0 && workspaceUserId != userId
          ? false
          : this.canUploadToSharedWorkspace
    })
    if (
      (bytes(this.totalSpace) >= bytes(this.usedSpace) + parseInt(file[0].file.size) ||
        (workspaceUserId > 0 && workspaceUserId != userId)) &&
      this.canUploadToSharedWorkspace
    ) {
    } else {
      let message = this.canUploadToSharedWorkspace
        ? `Cannot upload this file. The file size is ${bytes(file[0].file.size, {
            unit: 'MB',
            decimalPlaces: 2,
            unitSeparator: ' ',
          })} and you have ${bytes(bytes(this.totalSpace) - bytes(this.usedSpace), {
            unit: 'MB',
            decimalPlaces: 0,
            unitSeparator: ' ',
          })} available.`
        : `Cannot upload this file. Shared workspace have not enough space to upload this file.`
      this.dialog.open(DeleteFileDialogComponent, {
        width: '500px',
        maxWidth: '95vw',
        disableClose: true,
        data: {
          title: `Not enough space available`,
          content: message,
          cancel: 'off',
        },
      })
      this.resumableUpload.removeFile(file)
      return
    }

    let i = 0
    for (i = 0; i < file.length; i++) {
      //Change the filename to avoid overwrting files with same name.
      let extension = path.extname(file[i].file.name)
      delete this.cancelledItem[file[i].uniqueIdentifier]
      if (extension) file[i].fileName = `${file[i].uniqueIdentifier}${extension}`

      let mimetype = file[i].file.type

      //MIME for common files is ok. But for other files, it may not be available.
      if (mimetype == '' || mimetype == null) {
        if (extension) {
          if (extension.match(/pdf/) != null) {
            mimetype = 'application/pdf'
          } else if (extension.match(/jpg|tiff|eps|jpeg|png|psd|ai|pcm|gif/) != null) {
            mimetype = 'image/jpg'
          } else if (extension.match(/webm|mp4|mov|avi|ogg|mkv/) != null) {
            mimetype = 'video/mp4'
          } else if (extension.match(/zip/) != null) {
            mimetype = 'application/zip'
          } else if (extension.match(/wav|mp3|aiff|aac|wma|m4a/) != null) {
            mimetype = 'audio/mp3'
          } else if (extension.match(/glb|gltf|stl|fbx|obj|usdz/) != null) {
            mimetype = `model/${extension.substring(1)}`
          }
        } else {
          const dialogRef = this.dialog.open(DeleteFileDialogComponent, {
            width: '500px',
            maxWidth: '95vw',
            disableClose: true,
            data: {
              title: `Unknown file type`,
              content: `Unable to assess the type of file that you are uploading. It is important for the processing of file. Please add an extension to the file name.`,
              cancel: 'off',
            },
          })
          dialogRef.afterClosed().subscribe(result => {
            if (result) return
          })
        }
      }
      this.resumableUploadQueue[file[0].file.uniqueIdentifier] = file
      this.resumableUploadQueue[file[0].file.uniqueIdentifier].isCancel = false
      this.resumableUploadQueue[file[0].file.uniqueIdentifier].isSuccess = false
      this.resumableUploadQueue[file[0].file.uniqueIdentifier].isUploading = true
      this.resumableUploadQueue[file[0].file.uniqueIdentifier].mode = 'indeterminate'

      this.resumableUploadQueue[file[0].file.uniqueIdentifier].versionNo = this.currentVersion || 1
      if (this.resumableUploadQueue[file[0].file.uniqueIdentifier].versionNo == 1) {
        this.resumableUploadQueue[file[0].file.uniqueIdentifier].documentId = null
      } else {
        this.resumableUploadQueue[file[0].file.uniqueIdentifier].documentId = this.currentDocument
      }
      //First check if upload folder Id is provided in the file
      if (file[0].file.parentFolderId) {
        this.resumableUploadQueue[file[0].file.uniqueIdentifier].folderId =
          file[0].file.parentFolderId
        if (!this.uploadFolder) this.uploadFolder = {}
        this.uploadFolder.folderId = file[0].file.parentFolderId
      } else
        this.resumableUploadQueue[file[0].file.uniqueIdentifier].folderId =
          this.uploadFolder.folderId || 0
      this.resumableUploadQueue[
        file[0].file.uniqueIdentifier
      ].workspaceId = this.uploadFolder.workspaceId

      file[i].mimetype = mimetype
      this.globalService.resumableUploadQueue.next(this.resumableUploadQueue)
    }
    this.resumableUpload.upload()
    this.calculateRemainigUploadTime()
  }

  async fileSuccess(file) {
    try {
      let success = await this.appendToDb(
        file,
        this.resumableUploadQueue[file.file.uniqueIdentifier],
      )
      if (success) {
        this.resumableUploadQueue[file.file.uniqueIdentifier].isSuccess = true
        this.resumableUploadQueue[file.file.uniqueIdentifier].isError = false
        this.resumableUploadQueue[file.file.uniqueIdentifier].isUploading = false
        this.resumableUploadQueue[file.file.uniqueIdentifier].progressText = 'Processing'
        this.globalService.resumableUploadQueue.next(this.resumableUploadQueue)

        this.resumableUpload.removeFile(file)
        console.log(this.resumableUpload)
        let workspaceUserId = parseInt(localStorage.getItem('workspaceUserId'))
        workspaceUserId = workspaceUserId > 0 ? workspaceUserId : 0
        let userId = parseInt(localStorage.getItem('userId'))
        if (workspaceUserId == userId || workspaceUserId == 0) {
          let uSpaceBytes = <number>bytes(this.usedSpace) + parseInt(file.file.size)
          let tSpaceBytes = <number>bytes(this.totalSpace)
          let uSpace = <number>bytes(uSpaceBytes, { unitSeparator: ' ' })
          let uSpaceP = <number>((uSpaceBytes / tSpaceBytes) * 100)
          this.globalService.usedSpace.next(uSpace)
          this.globalService.usedPercentage.next(uSpaceP)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  appendToDb(file: any, fileDocObj) {
    return new Promise(async (resolve, reject) => {
      if (this.cancelledItem[file.file.uniqueIdentifier]) {
        delete this.cancelledItem[file.file.uniqueIdentifier]
        return false
      }

      this.requestObject.user_id = this.currentUser.userId
      this.requestObject.filename = file.fileName
      this.requestObject.originalname = file.file.name
      this.requestObject.size = file.file.size
      this.requestObject.mimetype = file.mimetype
      if (fileDocObj && fileDocObj.documentId) {
        this.requestObject.versionNo = fileDocObj.versionNo
        this.requestObject.documentId = fileDocObj.documentId
        this.requestObject.folderId = fileDocObj.folderId
        this.requestObject.workspaceId = fileDocObj.workspaceId
      } else {
        this.requestObject.versionNo = this.currentVersion || 1
        if (this.requestObject.versionNo == 1) this.requestObject.documentId = null
        else this.requestObject.documentId = this.currentDocument

        if (file.file.parentFolderId) this.requestObject.folderId = file.file.parentFolderId
        else this.requestObject.folderId = this.uploadFolder.folderId || 0
        this.requestObject.workspaceId = this.uploadFolder.workspaceId
      }
      this.requestObject.ownerId =
        this.customAppSetting.userId != undefined ? this.customAppSetting.userId : 0
      this.requestObject.priv = this.currentUser.membershipId > 1 ? true : false
      this.requestObject.noEmail = file.file.noEmail
      this.documentService.insertNewDocument(this.requestObject).subscribe(
        async docInfo => {
          if (
            this.currentPage == 'dashboard' ||
            this.currentPage == 'starred' ||
            this.currentPage == 'recent'
          ) {
            if (
              this.selectedFolderObj.folderId != this.uploadFolder.folderId ||
              this.selectedFolderObj.workspaceId != this.uploadFolder.workspaceId
            ) {
              resolve(true)
            } else {
              if (this.currentVersion > 1) {
                let docInfoIndex = this.docInfo.findIndex(v => {
                  return v.documentId == docInfo[0].documentId
                })
                this.docInfo[docInfoIndex] = docInfo[0]
                this.dataService.getDocVersions()
                this.docInfo.splice(docInfoIndex, 1, docInfo[0])
                this.globalService.docInfo.next(this.docInfo)
                this.resumableUpload.opts.query = docInfo[0]
                this.currentVersion = 1
              } else {
                this.docInfo.splice(0, 0, docInfo[0])
                this.globalService.docInfo.next(this.docInfo)
                this.resumableUpload.opts.query = docInfo[0]
              }
            }
          }
          resolve(true)
        },
        err => console.log('err', err),
      )
    })
  }

  cancelFileUpload(uniqueIdentifier) {
    let file = this.resumableUpload.getFromUniqueIdentifier(uniqueIdentifier)
    this.cancelledItem[uniqueIdentifier] = true
    this.resumableUpload.removeFile(file)
    file.abort()
    this.resumableUploadQueue[uniqueIdentifier].isSuccess = false
    this.resumableUploadQueue[uniqueIdentifier].isError = true
    this.resumableUploadQueue[uniqueIdentifier].isUploading = false
    this.resumableUploadQueue[uniqueIdentifier].progressText = ''
    this.globalService.resumableUploadQueue.next(this.resumableUploadQueue)
    this.resumableUpload.upload()
  }

  /**
   * Calculates the remaining upload time by executing on a 1 second interval and
   * monitoring the progress of the upload then updating a global time variable.
   * Also fill the progress bar based off the progress reported by the uploader.
   */
  calculateRemainigUploadTime() {
    try {
      var step = 2 //Change this to alter how often this check is run. It corresponds to seconds.
      var progress = this.resumableUpload.progress()
      //Progress is a float from 0 - 1 so we can calculate the remainder by subtracting from 1.
      var remainingProgress = 1.0 - progress
      //Calculate how much relative progress has been made then multiply by the iteration count.
      var estimatedCompletionTime = Math.round(
        (remainingProgress / progress) * this.progressIterations,
      )
      var estimatedHours,
        estimatedMinutes,
        estimatedSeconds,
        displayHours,
        displayMinutes,
        displaySeconds
      //Important! Make sure we increment the iteration count.
      this.progressIterations += step

      //If progress is complete then quit
      if (progress >= 1.0) return

      if (progress > 0.99) {
        this.uploadTimeRemaining = '00:00:00'
        return
      }

      //If the estimated time is valid then calculate the time values.
      //NOTE: It might take 1 or 2 iterations to get a valid estimate.
      if (isFinite(estimatedCompletionTime)) {
        //Convert estimated completion time to hours, minutes,
        //and seconds and append 0's if they are single digits
        estimatedHours = Math.floor(estimatedCompletionTime / 3600)
        displayHours = estimatedHours > 9 ? estimatedHours : '0' + estimatedHours
        estimatedMinutes = Math.floor((estimatedCompletionTime / 60) % 60)
        displayMinutes = estimatedMinutes > 9 ? estimatedMinutes : '0' + estimatedMinutes
        estimatedSeconds = estimatedCompletionTime % 60
        displaySeconds = estimatedSeconds > 9 ? estimatedSeconds : '0' + estimatedSeconds

        this.uploadTimeRemaining = displayHours + ':' + displayMinutes + ':' + displaySeconds
      }
    } catch (e) {}
    //NOTE: If you want to adjust how often this timeout runs then change the step variable.
    // setTimeout(this.calculateRemainigUploadTime, step * 1000);
  }

  checkUploadSpeed(iterations, update) {
    let check = () => {
      var xhr = new XMLHttpRequest(),
        url = '?cache=' + Math.floor(Math.random() * 10000), //prevent url cache
        data = getRandomString(1), //1 meg POST size handled by all servers
        startTime,
        speed = 0
      xhr.onreadystatechange = event => {
        if (xhr.readyState == 1) {
        } else if (xhr.readyState == 4) {
          speed = Math.round(1024 / ((+new Date() - startTime) / 1000))
          average == 0 ? (average = speed) : (average = Math.round((average + speed) / 2))
          update(speed, average)
          index++
          if (index == iterations) {
            window.clearInterval(this.checkSpeedTimer)
          }
        }
      }
      xhr.open('POST', url, true)
      startTime = new Date()
      xhr.send(data)

      // if(Object.entries(this.resumableUploadQueue).filter((v => !v.isUploading).length == 0
    }

    let getRandomString = sizeInMb => {
      var chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?", //random data prevents gzip effect
        iterations = sizeInMb * 1024 * 1024, //get byte count
        result = ''
      for (var index = 0; index < iterations; index++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return result
    }

    var average = 0,
      index = 0
    window.clearInterval(this.checkSpeedTimer)
    this.checkSpeedTimer = window.setInterval(() => check(), 5000) //check every 5 seconds
  }

  clearSpeedTimerInterval() {
    let stillUploading = false
    for (var i = 0; i < Object.keys(this.resumableUploadQueue).length; i++) {
      if (
        this.resumableUploadQueue[Object.keys(this.resumableUploadQueue)[i]].isUploading == true
      ) {
        stillUploading = true
        break
      }
    }

    if (!stillUploading) clearInterval(this.checkSpeedTimer)
  }
}
