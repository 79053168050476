<div>
  <!-- <h5 class="mb-3">Expiry & Reminders</h5> -->
  <div class="notify">
    <label nz-checkbox class="checkbox-margin" (ngModelChange)="handleNeverExpire()" [(ngModel)]="data.neverExpire" [nzValue]="false"
      (keyup.enter)="onKey(data)">
      Never Expire </label>
  </div>
</div>

<div class="mt-3" style="position: relative;">
  <nz-date-picker
    #neverExpire
    [nzDisabledTime]="disabledDateTime"
    [nzDisabledDate]="disabledDate"
    [nzShowTime]="{ nzFormat: 'h:mm'}"
    [nzUse12Hours]="true"
    [nzDisabledSeconds] = "true"
    nzFormat="MM/dd/yyyy, h:mm a"
    nzPlaceHolder="Enter Date"
    [disabled]="data.neverExpire"
    [(ngModel)]="data.expiryDate" (keyup.enter)="onKey(data)"></nz-date-picker>
  <!-- <input class="ant-input" #neverExpire [disabled]="data.neverExpire" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
    [min]="startDate" [(ngModel)]="data.expiryDate" placeholder="Enter Date" (keyup.enter)="onKey(data)">
  <owl-date-time #dt1></owl-date-time> -->
</div>

<div style="margin-top:20px" class="notify">
  <label nz-checkbox [disabled]="data.neverExpire" class="checkbox-margin"
    [(ngModel)]="data?.reminderSetting.reminder" [nzValue]="true" (keyup.enter)="onKey(data)">
    Send Reminder(s)
  </label>
  <div style="min-height: 34px; display: inline-block;vertical-align: middle;">
    <nz-select *ngIf="data?.reminderSetting.reminder == 1" [nzDisabled]="!data?.reminderSetting.reminder || data?.neverExpire" style="width:150px !important"
        [(ngModel)]="data.reminderSetting.reminderType" (ngModelChange)="reminderModeChange()">
        <nz-option *ngFor="let mode of reminderKeys" [nzValue]="mode.value" [nzLabel]="mode.key"></nz-option>
      </nz-select>
  </div>
<nz-form-item [@openClose]="data?.reminderSetting.reminder == 1 ? 'open' : 'closed'" style="margin-top:20px;">
  <nz-form-label [nzSm]="6" [nzXs]="9">First reminder</nz-form-label>
  <nz-form-control [nzSm]="14" [nzXs]="15">
    <nz-select [nzDisabled]="!data?.reminderSetting.reminder || data?.neverExpire" width="auto"
      [(ngModel)]="data?.reminderSetting.reminderSett.reminder1">
      <nz-option *ngFor="let option of (data.reminderSetting.reminderType== 'day' ? reminderCounter.slice(13) : reminderCounter)" [nzValue]="option.value" [nzLabel]="option.key"></nz-option>
    </nz-select>
    <span style="color: var(--menu-popup-text-color) !important;">&nbsp;&nbsp;{{data.reminderSetting.reminderType == "day" ? "day(s) before" : "hour(s) before"}}</span>
  </nz-form-control>
  <nz-form-label [nzSm]="6" [nzXs]="9">Second reminder</nz-form-label>
  <nz-form-control [nzSm]="14" [nzXs]="15">
    <nz-select [nzDisabled]="!data.reminderSetting.reminder || data.neverExpire" width="18px" nzPlaceHolder="2"
      [(ngModel)]="data.reminderSetting.reminderSett.reminder2">
      <nz-option *ngFor="let option of (data.reminderSetting.reminderType== 'day' ? reminderCounter.slice(13) : reminderCounter)" [nzValue]="option.value" [nzLabel]="option.key"></nz-option>
    </nz-select>
    <span style="color: var(--menu-popup-text-color) !important;">&nbsp;&nbsp;{{data.reminderSetting.reminderType == "day" ? "day(s) before" : "hour(s) before"}}</span>
  </nz-form-control>
  <nz-form-label [nzSm]="6" [nzXs]="9">Third reminder</nz-form-label>
  <nz-form-control [nzSm]="14" [nzXs]="15">
    <nz-select [nzDisabled]="!data.reminderSetting.reminder || data.neverExpire" width="18px" nzPlaceHolder="3"
      [(ngModel)]="data.reminderSetting.reminderSett.reminder3">
      <nz-option *ngFor="let option of (data.reminderSetting.reminderType== 'day' ? reminderCounter.slice(13) : reminderCounter)" [nzValue]="option.value" [nzLabel]="option.key"></nz-option>
    </nz-select>
    <span style="color: var(--menu-popup-text-color) !important;">&nbsp;&nbsp;{{data.reminderSetting.reminderType == "day" ? "day(s) before" : "hour(s) before"}}</span>
  </nz-form-control>
  <nz-form-label [nzSm]="6" [nzXs]="9">Only if </nz-form-label>
  <nz-form-control [nzSm]="14" [nzXs]="15">
    <nz-select [nzDisabled]="!data.reminderSetting.reminder || data.neverExpire" style="width:157px !important"
      [(ngModel)]="data.reminderSetting.reminder_on">
      <nz-option nzCustomContent nzValue="1" nzLabel="Always" title="">
        <span nz-tooltip nzTooltipTitle="Compulsorily send reminder(s)" title=""> Always</span>
      </nz-option>
      <nz-option nzCustomContent nzValue="2" nzLabel="Not opened" title="">
        <span nz-tooltip nzTooltipTitle="If user has not launched the proof" title=""> Not opened</span>
      </nz-option>
      <nz-option nzCustomContent nzValue="3" nzLabel="Not commented" title="">
        <span nz-tooltip nzTooltipTitle="If user has not commented" title=""> Not commented</span>
      </nz-option>
      <nz-option nzCustomContent nzValue="4" nzLabel="Not actioned" title="">
        <span nz-tooltip nzTooltipTitle="If user has not approved or asked for rework" title=""> Not actioned</span>
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
</div>

<!-- <div style="margin-top:20px" class="notify">
  <label style="margin-bottom:20px" nz-checkbox [nzDisabled]="data?.neverExpire" class="checkbox-margin"
    [(ngModel)]="data?.reminderSetting.reminder" [nzValue]="true" (keyup.enter)="onKey(data)">
    Send Reminder(s)
  </label>
  <nz-form-item *ngIf="data?.reminderSetting.reminder == 1">
    <nz-form-label [nzSm]="6" [nzXs]="24">First reminder</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-select [nzDisabled]="!data?.reminderSetting.reminder || data?.neverExpire" width="auto"
        [(ngModel)]="data?.reminderSetting.reminderSett.reminder1">
        <nz-option *ngFor="let option of (data.reminderSetting.reminderType== 'day' ? reminderCounter.slice(13) : reminderCounter)" [nzValue]="option.value" [nzLabel]="option.key"></nz-option>
      </nz-select>
      <span style="color: var(--menu-popup-text-color) !important;">&nbsp;&nbsp;{{data.reminderSetting.reminderType == "day" ? "day(s) before" : "hour(s) before"}}</span>
    </nz-form-control>
    <nz-form-label [nzSm]="6" [nzXs]="24">Second reminder</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-select [nzDisabled]="!data.reminderSetting.reminder || data.neverExpire" width="18px" nzPlaceHolder="2"
        [(ngModel)]="data.reminderSetting.reminderSett.reminder2">
        <nz-option *ngFor="let option of (data.reminderSetting.reminderType== 'day' ? reminderCounter.slice(13) : reminderCounter)" [nzValue]="option.value" [nzLabel]="option.key"></nz-option>
      </nz-select>
      <span style="color: var(--menu-popup-text-color) !important;">&nbsp;&nbsp;{{data.reminderSetting.reminderType == "day" ? "day(s) before" : "hour(s) before"}}</span>
    </nz-form-control>
    <nz-form-label [nzSm]="6" [nzXs]="24">Third reminder</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-select [nzDisabled]="!data.reminderSetting.reminder || data.neverExpire" width="18px" nzPlaceHolder="3"
        [(ngModel)]="data.reminderSetting.reminderSett.reminder3">
        <nz-option *ngFor="let option of (data.reminderSetting.reminderType== 'day' ? reminderCounter.slice(13) : reminderCounter)" [nzValue]="option.value" [nzLabel]="option.key"></nz-option>
      </nz-select>
      <span style="color: var(--menu-popup-text-color) !important;">&nbsp;&nbsp;{{data.reminderSetting.reminderType == "day" ? "day(s) before" : "hour(s) before"}}</span>
    </nz-form-control>
    <nz-form-label [nzSm]="6" [nzXs]="24">Only if </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <nz-select [nzDisabled]="!data.reminderSetting.reminder || data.neverExpire" style="width:157px !important"
        [(ngModel)]="data.reminderSetting.reminder_on">
        <nz-option nzCustomContent nzValue="1" nzLabel="Always" title="">
          <span nz-tooltip nzTooltipTitle="Compulsorily send reminder(s)" title=""> Always</span>
        </nz-option>
        <nz-option nzCustomContent nzValue="2" nzLabel="Not opened" title="">
          <span nz-tooltip nzTooltipTitle="If user has not launched the proof" title=""> Not opened</span>
        </nz-option>
        <nz-option nzCustomContent nzValue="3" nzLabel="Not commented" title="">
          <span nz-tooltip nzTooltipTitle="If user has not commented" title=""> Not commented</span>
        </nz-option>
        <nz-option nzCustomContent nzValue="4" nzLabel="Not actioned" title="">
          <span nz-tooltip nzTooltipTitle="If user has not approved or asked for rework" title=""> Not actioned</span>
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</div> -->
<div class="text-right mt-3">
  <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
  <button type="button" class="ant-btn ant-btn-primary" (click)="modal.destroy(data)"
    [disabled]="!data.expiryDate">Create</button>
</div>
