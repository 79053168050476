import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, Subject } from 'rxjs'
import { CookieService } from 'ngx-cookie-service'
import { environment } from '../../../environments/environment'
import { GlobalService } from 'src/app/services/common/global.service'
import { NzModalService } from 'ng-zorro-antd/modal'
import { TrashAlertComponent } from '../../pages/dashboard/modal-components/trash-alert/trash-alert.component'
import { Router } from '@angular/router'
import { ThemeService } from 'src/app/services/theme.service'
import { Store } from '@ngrx/store'
import * as SettingsActions from 'src/app/store/settings/actions'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public urlData = new Subject<string>()
  private apiUrl = environment.apiUrl
  private _registerUrl = this.apiUrl + '/signup'
  private _loginUrl = this.apiUrl + '/login'
  private _logoutUrl = this.apiUrl + '/logout'
  private _forgotUrl = this.apiUrl + '/forgot-password'
  private _resetUrl = this.apiUrl + '/reset-password'
  private _changePasswordUrl = this.apiUrl + '/change-password'
  private _changeEmailUrl = this.apiUrl + '/change-email'
  private _supportUrl = this.apiUrl + '/feedback'
  private _profileUrl = this.apiUrl + '/profile'
  private _emailNotificationUrl = this.apiUrl + '/email-notification'
  private _emailSettngsUrl = this.apiUrl + '/user-emailsettings'
  private _userPermissionUrl = this.apiUrl + '/user-permissions'
  private _editUserUrl = this.apiUrl + '/edit-user'
  private _refreshTokenUrl = this.apiUrl + '/token'
  private _socialLoginUrl = this.apiUrl + '/social'
  private _unsubscribeEmailUrl = this.apiUrl + '/unsubscribe-email'
  private _GetAllUnsubscribeEmailUrl = this.apiUrl + '/getAllUnsubscribe-email'
  private _themeUrl = this.apiUrl + '/theme'
  private _viewUrl = this.apiUrl + '/view'
  private _searchUrl = this.apiUrl + '/search'
  private _addEditProdStatusUrl = this.apiUrl + '/wfstatus-add-edit'
  // private _listProdStatusUrl = this.apiUrl + '/wfstatus-list'
  private _deleteProdStatusUrl = this.apiUrl + '/wfstatus-delete'
  private _addEditTeamUrl = this.apiUrl + '/team-add-edit'
  private _addEditTeamDetailUrl = this.apiUrl + '/team/team-add-edit2'
  private _listTeamUrl = this.apiUrl + '/team-list'
  private _deleteTeamUrl = this.apiUrl + '/team-delete'
  private _deleteTeamDetailUrl = this.apiUrl + '/team/team-delete2'
  private _allUserInfoUrl = this.apiUrl + '/userinfo'
  private _teamUserUrl = this.apiUrl + '/get-team-user'
  private _allteamUrl = this.apiUrl + '/allteam'
  private _addEditSettingUrl = this.apiUrl + '/setting-add-edit'
  private _addEditSettingNoLogoUrl = this.apiUrl + '/setting-add-edit-no-logo'
  private _settingUrl = this.apiUrl + '/setting'
  private _addEditEmailTemplateUrl = this.apiUrl + '/email-template-add-edit'
  private _listEmailTemplateUrl = this.apiUrl + '/email-template-list'
  private _deleteEmailTemplateUrl = this.apiUrl + '/email-template-delete'
  private _emailTemplateVarUrl = this.apiUrl + '/email-template-var'
  private _emailFuncUrl = this.apiUrl + '/email-func'
  private _testSMTPUrl = this.apiUrl + '/test-smtp'
  private _tokenInfoUrl = this.apiUrl + '/verify-token-info'
  private _membershipPlanUrl = this.apiUrl + '/get-membership-plan'
  private _topupPlanUrl = this.apiUrl + '/get-topups-plan'
  private _getUsersListUrl = this.apiUrl + '/get-users-list'
  private _loginAsUserUrl = this.apiUrl + '/login-as-user'
  private _currentMembershipUrl = this.apiUrl + '/get-current-membership'
  private _upgradeUserPlanUrl = this.apiUrl + '/upgrade-user-plan'
  private _dropboxAccessTokenUrl = this.apiUrl + '/dropbox-access-token'
  private _updateCustomUrl = this.apiUrl + '/update-custom-url'
  private _getAppSettingsUrl = this.apiUrl + '/get-app-settings'
  private _getTeamUrl = this.apiUrl + '/team/get-team-user2'
  private _getUserExist = this.apiUrl + '/team/is-user-exist'
  private _getOnlineTeamMembers = this.apiUrl + '/online-members'
  private _saveApiKey = this.apiUrl + '/add-api-key'
  private _getApiKey = this.apiUrl + '/get-api-key'
  private _myPayment = this.apiUrl + '/my-payments'
  private _changeAddressUrl = this.apiUrl + '/add-address'
  private _getLanguagesUrl = this.apiUrl + '/get-language'
  // private _checkLogin = this.apiUrl + '/check-login/';
  private _valiadteResetPasswordToken = this.apiUrl + '/verify-reset-password-token'
  private _updateTeamUrl = this.apiUrl + '/team/update-name'
  private _removeQrAcc = this.apiUrl + '/remove-account'
  private _removeUserAllFiles = this.apiUrl + '/remove-mark-document'
  private _handleSubscriptionUrl = this.apiUrl + '/manage-user-subcription'
  private _externalApiToken = this.apiUrl + '/external-access-token'
  _userActionOccured: Subject<void> = new Subject()
  currentUser: any
  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable()
  }

  constructor(
    private _cookieService: CookieService,
    private http: HttpClient,
    private _globalService: GlobalService,
    private modalService: NzModalService,
    private _router: Router,
    private themeService: ThemeService,
    private store: Store<any>,
  ) {
    this._globalService.userInfo.subscribe(userInfo => (this.currentUser = userInfo))
  }
  registerUser(user) {
    return this.http.post<any>(this._registerUrl, user, httpOptions)
  }
  loginUser(user) {
    return this.http.post<any>(this._loginUrl, user, httpOptions)
  }
  forgotPassword(user) {
    return this.http.post<any>(this._forgotUrl, user, httpOptions)
  }
  urlValue(value) {
    this.urlData.next(value)
  }
  resetPassword(user, token) {
    let httpOptionsReset = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'AUTH ' + token,
      }),
    }

    return this.http.post<any>(this._resetUrl, user, httpOptionsReset)
  }
  loggedIn() {
    try {
      return !!localStorage.getItem('qrtoken')
    } catch {
      return false
    }
  }

  getToken() {
    try {
      return localStorage.getItem('qrtoken')
    } catch {
      return ''
    }
  }
  apiKey() {
    try {
      return localStorage.getItem('apiKey')
    } catch {
      return ''
    }
  }

  logoutapi() {
    // remove user from local storage to log user out
    return this.http.get<any>(this._logoutUrl, httpOptions)
  }

  logout(isApiLogout = true) {
    // remove user from local storage to log user out
    // return this.http.get<any>(this._logoutUrl, httpOptions);
    // localStorage.removeItem('token');

    if (isApiLogout) {
      this.logoutapi().subscribe(onLineMembers => {})
    }
    localStorage.removeItem('qrtoken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userPassword')
    localStorage.removeItem('dropBoxAccessToken')
    localStorage.removeItem('isSocial')
    localStorage.removeItem('youToken')
    localStorage.removeItem('youTubeRefreshToken')
    localStorage.removeItem('youtubeAccessToken')
    localStorage.removeItem('status-sort')
    localStorage.removeItem('boxAccessToken')
    localStorage.removeItem('boxRefreshToken')
    this._cookieService.delete('token')
    this._globalService.updateAppSettings.next(true)
    // setTimeout(() => {
    // this.themeService.themeName.next('default')
    // this.store.dispatch(
    //   new SettingsActions.SetStateAction({
    //     theme: 'default',
    //   }),
    // )
    this._router.navigate(['/auth/login'])
    // }, 1000)
  }

  changePassword(user) {
    return this.http.post<any>(this._changePasswordUrl, user, httpOptions)
  }

  changeEmail(user) {
    return this.http.post<any>(this._changeEmailUrl, user, httpOptions)
  }

  feedback(user) {
    return this.http.post<any>(this._supportUrl, user, httpOptions)
  }

  getProfile() {
    return this.http.get<any>(this._profileUrl, httpOptions)
  }

  getEmailNotification() {
    return this.http.get<any>(this._emailNotificationUrl, httpOptions)
  }

  getEmailSettings() {
    return this.http.get<any>(this._emailSettngsUrl, httpOptions)
  }
  getUserPermission() {
    return this.http.get<any>(this._userPermissionUrl, httpOptions)
  }

  updateProfile(user) {
    return this.http.post<any>(this._editUserUrl, user, httpOptions)
  }

  refreshToken(user) {
    return this.http.post<any>(this._refreshTokenUrl, user, httpOptions)
  }

  notifyUserAction() {
    this._userActionOccured.next()
  }

  socialLogin(user) {
    return this.http.post<any>(this._socialLoginUrl, user, httpOptions)
  }

  unsubscribeEmail(user) {
    return this.http.post<any>(this._unsubscribeEmailUrl, user, httpOptions)
  }

  setTheme(user) {
    return this.http.post<any>(this._themeUrl, user, httpOptions)
  }

  setView(user) {
    return this.http.post<any>(this._viewUrl, user, httpOptions)
  }

  search(searchObj) {
    return this.http.post<any>(this._searchUrl, searchObj, httpOptions)
  }
  addEditProdStatus(prodObj) {
    return this.http.post<any>(this._addEditProdStatusUrl, prodObj, httpOptions)
  }
  // getProdStatus(prodObj) {
  //   return this.http.post<any>(this._listProdStatusUrl, prodObj, httpOptions)
  // }
  deleteProdStatus(prodObj) {
    return this.http.post<any>(this._deleteProdStatusUrl, prodObj, httpOptions)
  }
  addEditTeam(teamObj) {
    return this.http.post<any>(this._addEditTeamUrl, teamObj, httpOptions)
  }
  addEditTeamDetail(teamObj) {
    return this.http.post<any>(this._addEditTeamDetailUrl, teamObj, httpOptions)
  }
  getTeam(teamObj) {
    return this.http.post<any>(this._listTeamUrl, teamObj, httpOptions)
  }
  deleteTeam(teamObj) {
    return this.http.post<any>(this._deleteTeamUrl, teamObj, httpOptions)
  }
  deleteTeamDetail(teamObj) {
    return this.http.post<any>(this._deleteTeamDetailUrl, teamObj, httpOptions)
  }
  getAllUserInfo() {
    return this.http.get<any>(this._allUserInfoUrl, httpOptions)
  }
  getTeamUser(teamObj) {
    return this.http.post<any>(this._teamUserUrl, teamObj, httpOptions)
  }
  getAllTeamInfo(userId, folderId, documentId) {
    return this.http.get<any>(
      this._allteamUrl + '/' + userId + '/' + folderId + '/' + documentId,
      httpOptions,
    )
  }
  addEditSetting(settingObj) {
    return this.http.post<any>(this._addEditSettingUrl, settingObj, httpOptions)
  }
  addEditSettingNoLogo(settingObj) {
    return this.http.post<any>(this._addEditSettingNoLogoUrl, settingObj, httpOptions)
  }
  getSetting() {
    return this.http.get<any>(this._settingUrl, httpOptions)
  }
  addEditEmailTemplate(tempObj) {
    return this.http.post<any>(this._addEditEmailTemplateUrl, tempObj, httpOptions)
  }
  getEmailTemplate(tempObj) {
    return this.http.post<any>(this._listEmailTemplateUrl, tempObj, httpOptions)
  }
  deleteEmailTemplate(tempObj) {
    return this.http.post<any>(this._deleteEmailTemplateUrl, tempObj, httpOptions)
  }
  getEmailTemplateVar() {
    return this.http.get<any>(this._emailTemplateVarUrl, httpOptions)
  }
  getEmailFunc() {
    return this.http.get<any>(this._emailFuncUrl, httpOptions)
  }
  testSMTP(smtpObj) {
    return this.http.post<any>(this._testSMTPUrl, smtpObj, httpOptions)
  }
  getTokenInfo() {
    return this.http.get<any>(this._tokenInfoUrl, httpOptions)
  }
  getMembershipPlan() {
    return this.http.get<any>(this._membershipPlanUrl, httpOptions)
  }
  getTopupPlan() {
    return this.http.get<any>(this._topupPlanUrl, httpOptions)
  }
  getUsersList(user) {
    return this.http.post<any>(this._getUsersListUrl, user, httpOptions)
  }
  loginAsUser(user) {
    return this.http.post<any>(this._loginAsUserUrl, user, httpOptions)
  }
  getCurrentMemberhsip() {
    return this.http.get<any>(this._currentMembershipUrl, httpOptions)
  }
  upgradeUserPlan(userObj) {
    return this.http.post<any>(this._upgradeUserPlanUrl, userObj, httpOptions)
  }
  dropboxAccessToken(tokenObj) {
    return this.http.post<any>(this._dropboxAccessTokenUrl, tokenObj, httpOptions)
  }
  updateCustomUrl(settingObj) {
    return this.http.post<any>(this._updateCustomUrl, settingObj, httpOptions)
  }
  getAppSettings(settingObj, isAnonymous = false) {
    let isLoggedIn = this.loggedIn()
    try {
      settingObj.userId =
        parseInt(localStorage.getItem('userId')) > 0 && isLoggedIn
          ? parseInt(localStorage.getItem('userId'))
          : isAnonymous
          ? settingObj.userId
          : 0
    } catch {
      this.showDisableCookie()
    }
    if (!isLoggedIn && !isAnonymous) {
      setTimeout(() => {
        this._globalService.themeName.next('default')
        this.store.dispatch(
          new SettingsActions.SetStateAction({
            theme: 'default',
          }),
        )
      }, 1000)
    }
    if (isLoggedIn && settingObj.userId == 0 && this.currentUser) {
      settingObj.userId = this.currentUser.userId
    }
    return this.http.post<any>(this._getAppSettingsUrl, settingObj, httpOptions)
  }
  getTeamDetail(teamObj) {
    return this.http.post<any>(this._getTeamUrl, teamObj, httpOptions)
  }
  getCheckEmailExist(email) {
    return this.http.post<any>(this._getUserExist, email, httpOptions)
  }
  getAllUnsubscribeEmail() {
    return this.http.get<any>(this._GetAllUnsubscribeEmailUrl, httpOptions)
  }

  getOnlineTeamMembers(userId) {
    return this.http.get<any>(this._getOnlineTeamMembers + '/' + userId, httpOptions)
  }
  saveApiKey(apiKey) {
    return this.http.post<any>(this._saveApiKey, apiKey, httpOptions)
  }
  getApiKey() {
    return this.http.get<any>(this._getApiKey, httpOptions)
  }
  showDisableCookie() {
    const trashItemAlert = this.modalService.create({
      nzTitle: 'Permission required',
      nzContent: TrashAlertComponent,
      nzFooter: null,
      nzComponentParams: {
        data: {
          message: '',
          type: 'cookie',
        },
      },
      nzClassName: 'trashAlert',
    })
    trashItemAlert.afterClose.subscribe(result => {})
  }
  showAlertMessage(title, type, message) {
    const trashItemAlert = this.modalService.create({
      nzMaskClosable: false,
      // nzClosable: false,
      nzTitle: title,
      nzContent: TrashAlertComponent,
      nzFooter: null,
      nzComponentParams: {
        data: {
          message: message,
          type: type,
        },
      },
      nzClassName: 'trashAlert',
    })
    trashItemAlert.afterClose.subscribe(result => {})
  }

  saveNewEmail() {}
  validateResetPasswordToken(tokenStr) {
    return this.http.get<any>(this._valiadteResetPasswordToken + '/' + tokenStr, httpOptions)
  }

  myPayment() {
    return this.http.get<any>(this._myPayment, httpOptions)
  }

  changeAddress(payLoad) {
    return this.http.post<any>(this._changeAddressUrl, payLoad, httpOptions)
  }
  updateTeam(teamObj) {
    return this.http.post<any>(this._updateTeamUrl, teamObj, httpOptions)
  }
  getLang() {
    return this.http.get(this._getLanguagesUrl, httpOptions)
  }

  unsubscribeMemberhsipFromApi(payLoad) {
    return this.http.post<any>(this._handleSubscriptionUrl, payLoad, httpOptions)
  }

  removeQrAccount() {
    return this.http.get(this._removeQrAcc, httpOptions)
  }

  removeUserAllFiles(userId) {
    return this.http.get(this._removeUserAllFiles + '/' + userId, httpOptions)
  }

  externalApiToken(params:any) {
    return this.http.post<any>(this._externalApiToken, params, httpOptions)
  }
}
