<div>
    <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
        <!-- <nz-avatar nzIcon="user" [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar> -->

        <span class="account-info">
            <span *ngIf="profileImage"><img [src]="profileImage"></span>
            <span *ngIf="!profileImage" class="fCharImage">{{ profileFname }}</span>

        </span>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <!-- <li nz-menu-item>
          <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>
         
          <div>
            <strong>
              {{ 'topBar.profileMenu.role' | translate }}:
            </strong>
            {{role || '—'}}
          </div>
        </li> -->
            <li nz-menu-item>

                <a class="text-default" [routerLink]="['/dashboard/user-details']">
                    <i class="fe fe-user mr-2" aria-hidden="true"></i>
                    My Profile
                </a>
            </li>

            <!-- <li nz-menu-divider></li> -->
            <!-- <li nz-menu-item>
          <div>
            <strong>{{ 'topBar.profileMenu.email' | translate }}: </strong>
            {{email || '—'}}
            <br />
            <strong>{{ 'topBar.profileMenu.phone' | translate }}: </strong>
            {{phone || '—'}}
          </div>
        </li> -->
            <!-- <li nz-menu-divider></li> -->
            <!-- <li nz-menu-item>
          <a href="javascript: void(0);">
            <i class="fe fe-user mr-2"></i>
            {{ 'topBar.profileMenu.editProfile' | translate }}:
          </a>
        </li> -->
            <!-- <li nz-menu-divider></li> -->
            <!-- <li nz-menu-item>
  
          <a class="text-default" [routerLink]="['./change-password']">
            <i nz-icon nzType="key" class="mr-2" nzTheme="outline"></i>
            Change Password 
          </a>
        </li> -->
            <!-- <li nz-menu-divider></li> -->
            <li nz-menu-item *ngIf="!isTeamUrl">

                <a class="text-default" [routerLink]="['/dashboard/integration']">
                    <i class="fe fe-link-2 mr-2" aria-hidden="true"></i>
                    Integrations
                </a>
            </li>
            <!-- <li nz-menu-divider></li> -->
            <li nz-menu-item *ngIf="isAdmin">

                <a class="text-default" (click)="navigateToUserList()">
                    <i class="fe fe-user mr-2" aria-hidden="true"></i>
                    Sign in as user
                </a>
            </li>
            <li nz-menu-divider *ngIf="isAdmin"></li>
            <li nz-menu-item>

                <a class="text-default" href="javascript: void(0);" (click)="logout()">
                    <i class="fe fe-log-out mr-2"></i>
                    {{ 'topBar.profileMenu.logout' | translate }}
                </a>
            </li>

        </ul>
    </nz-dropdown-menu>
</div>