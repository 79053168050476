import { Component, OnInit } from '@angular/core'
import { GlobalService } from 'src/app/services/common/global.service'

@Component({
  selector: 'qr-topbar-actions',
  templateUrl: './topbar-actions.component.html',
  styleUrls: ['./topbar-actions.component.scss'],
})
export class TopbarActionsComponent implements OnInit {
  notification: boolean
  badgeCount: number = 7

  constructor(private globalService: GlobalService) {}

  ngOnInit(): void {}

  badgeCountIncrease() {
    this.badgeCount = this.badgeCount + 1
  }
  notificationFn(event) {
    // var target = event.target || event.srcElement || event.currentTarget;
    // var idAttr = event.currentTarget.attributes[2].nodeValue;
    // debugger;
    this.notification = !this.notification
    this.globalService.notification.next(this.notification)
    this.globalService.notificationCheck.next('header')
  }
}
