<div>
    <p>You have chosen a new plan for your subscription. This will cancel the existing plan. Do you wish to proceed?</p>
</div>
<div>
    <div class="text-right">
        <button class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(true)">Proceed</button>
        <button class="ant-btn ant-btn-primary"  (click)="modal.destroy(false)">Cancel</button>
    </div>
</div>
<div>

