<div class="alertBox">
    <div *ngIf="data.type != 'cookie'">
        {{data.message}}
    </div>
    <div *ngIf="data.type == 'cookie'">
        <p>
            We use cookies to secure your interaction with QuickReviewer. It seems your browser is blocking cookies and hence you are not able to login. To allow cookies, <a target="_blank" href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/chrome">read here</a>
        </p>
    </div>
    <div class="text-right mt-3">
        <ng-container *ngIf="data.type == 'file' || data.type == 'cookie' || data.type == 'whitelabel'; else folder">
            <button type="button" class="ant-btn ant-btn-primary" (click)="modal.destroy(true)">OK</button>
        </ng-container>
        <ng-template #folder>
            <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
            <button type="button" class="ant-btn ant-btn-primary" (click)="modal.destroy(true)">Restore</button>
        </ng-template>
    </div>
</div>