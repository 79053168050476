import { Injectable } from '@angular/core'
import { DocumentService } from '../document.service'
import { GlobalService } from './global.service'
import { Document as DocumentRes } from 'src/app/Model/document'
import { NzNotificationService } from 'ng-zorro-antd/notification'

@Injectable({
  providedIn: 'root',
})
export class CommonDocumentService {
  constructor(
    private globalService: GlobalService,
    private _docService: DocumentService,
    private notification: NzNotificationService,
  ) {}

  increaseDocumentVersion(element, priviledged: boolean, docInfo: Array<DocumentRes>) {
    const docObj = {
      userId: element.userId,
      documentId: element.documentId,
      versionNo: parseInt(element.versionNo) + 1,
      folderId: element.folderId || 0,
      workspaceId: element.workspaceId,
      documentUrl: element.documentFileName,
      uploadSource: element.documentType == 'youtube' ? 'youtube' : 'web',
      documentTitle: element.documentTitle,
      documentContentType: element.documentContentType,
      documentType: element.documentType,
      priv: priviledged,
    }

    this._docService.incrementWeblinkVersion(docObj).subscribe((data: any) => {
      docInfo.forEach(v => {
        if (v.documentId === data[0].documentId) {
          v.versionNo = data[0].versionNo
        }
      })
      this.globalService.docInfo.next(docInfo)
      this.globalService.currentDocument.next(element.documentId)
      this.globalService.currentVersion.next(parseInt(element.versionNo) + 1)
      this.notification.create('success', null, 'Version Incremented.', {
        nzPlacement: 'bottomRight',
        nzClass: 'test-class',
      })
    })
  }

  filterDocumentData(docInfo: Array<DocumentRes>, filterKeys: string[]) {
    const filteredData = docInfo.filter(item => filterKeys.indexOf(item.wfStatusName) === 0)
    return filteredData
  }
}
