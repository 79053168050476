import { Component, Input, OnInit } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
// import { GlobalService } from 'src/app/services/common/global.service'
// import { DataService } from 'src/app/services/data.service'
// import { FolderService } from 'src/app/services/folder.service'

@Component({
  selector: 'qr-remove-file',
  templateUrl: './remove-file.component.html',
  styleUrls: ['./remove-file.component.scss'],
})
export class RemoveFileComponent implements OnInit {
  @Input() data: { title: string; content: string }

  constructor(
    public modal: NzModalRef, // private _folderService: FolderService, // private globalService: GlobalService, // private notification: NzNotificationService, // private dataService: DataService,
  ) {}

  ngOnInit(): void {}

  // submitName() {
  //   if (!this.data.name) {
  //     return
  //   }
  //   const folderObj = {
  //     folderId: 0,
  //     folderName: this.data.name,
  //     isWorkspace: 0,
  //     workspaceId: this.data.type === 'workspace' ? 1 : 0,
  //   }

  //   this._folderService.mkFolder(folderObj).subscribe(
  //     res => {
  //       if (this.data.type === 'workspace') {
  //         this.globalService.newFolderAdded.next(res)
  //       } else {
  //         this.globalService.newDocumentAdded.next(res)
  //       }
  //       this.modal.destroy(true)
  //       const message = this.data.type === 'workspace' ? 'Workspace Created' : 'Folder Created'
  //       this.notification.blank(null, message)
  //       // this.snackBar.open(message, '', { duration: 3000 })
  //       this.dataService.refreshData()
  //     },
  //     err => {},
  //   )
  // }

  onDeleteAction(event) {
    if (event.keyCode === 13) {
      this.modal.destroy(true)
    }
  }
}
