<!-- 
<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#notificationModal" >Notification Modal</button>


<div class="modal fade" id="notificationModal" tabindex="-1" role="dialog" aria-labelledby="notificationModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="notificationModalLabel">What's New</h5>
      </div>
      <span class="modal-body" style="max-height: 50vh!important;min-height: 12vh!important;overflow-y:auto;">
        <div   *ngFor="let noti of notification">
          <mat-list>
            <mat-list-item style="height: auto!important;" >
              <mat-icon class="list-icon" mat-list-icon svgIcon="lens" color='primary' style="width:16px; height: 16px; vertical-align: top;margin-top: -7px;"></mat-icon>
              <div mat-line class="noti-list" [innerHTML]="noti.message" style="padding-top: 5px; font-size: 14px;"></div>
            </mat-list-item>
        
          </mat-list>
        </div>
      </span>
      
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" (click)="updateNotification()"><span class="add-new">Got
            it!!</span></button>
      </div>
    </div>
  </div>
</div> -->


<nz-modal [(nzVisible)]="isVisible" nzClosable=false  [nzStyle]="{ top: '20px' }" [nzFooter]=null  (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
        
        <div class="modalHeader">
            <h5 class="modal-title" id="notificationModalLabel">What's New</h5>
          </div>
          <div class="listSection">
        <nz-list nzItemLayout="vertical" [nzLoading]="loading">
            <nz-list-item *ngFor="let noti of notification" style="height: auto!important;">
                
                <div class="noti-list" [innerHTML]="noti.message" style="padding-top: 5px; font-size: 14px;"></div>
            </nz-list-item>
          </nz-list>
          </div>
          <div class="modalFooter text-right">
            <button type="button" class="ant-btn ant-btn-primary" (click)="updateNotification()">Got
                it!!</button>
          </div>
    </ng-container>
  </nz-modal>