import { Component, Input, OnInit } from '@angular/core'
import { GlobalService } from '../../services/common/global.service'
import { DataService } from '../../services/data.service'
import { environment } from '../../../environments/environment'
// import { UpgradeStorageDialogComponent } from '../upgrade-storage-dialog/upgrade-storage-dialog.component';
import { DocumentService } from 'src/app/services/document.service'
import { UpgradeStorageDialogComponent } from '../upgrade-storage-dialog/upgrade-storage-dialog.component'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { Store, select } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'

@Component({
  selector: 'qr-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.css'],
})
export class StorageComponent implements OnInit {
  totalSpace: number
  usedSpace: number
  usedPercentage: number
  userType: string = 'qr'
  isCustomUrl: boolean
  isCustomUrlOwner: boolean
  showUpgradeStorage: boolean = true
  customAppSetting: any
  usedWeblink: number = 0
  totalWeblink: number = 0
  @Input() isMenuCollapse: boolean
  settings: any
  isMobileView: boolean
  isTeamUrl: boolean
  allowedTeamMember:number=0
  addedTeamMember: number = 0
  userPermission:any;
  createdWF : number =0;
  totalWF : number = 5;
  constructor(
    private _docService: DocumentService,
    private globalService: GlobalService,
    private _dataService: DataService,
    private modalService: NzModalService,
    private store: Store<any>,
  ) {}

  ngOnInit() {
    
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.settings = state
      this.isMobileView = state.isMobileView
    })
    this.globalService.isTeamUrl.subscribe(isTeamUrl => {
      this.isTeamUrl = isTeamUrl
    })
    this._dataService.storagedocs()
    this.globalService.totalSpace.subscribe(totalSpace => {
      this.totalSpace = totalSpace
    })
    this.globalService.usedSpace.subscribe(usedSpace => {
      this.usedSpace = usedSpace
    })
    this.globalService.usedPercentage.subscribe(usedPercentage => {
      this.usedPercentage = usedPercentage
    })
    this.globalService.userInfo.subscribe(userInfo => {
      if (userInfo != null) {
        this.userType = userInfo.userType
      }
    })
    this.globalService.isCustomUrl.subscribe(isCustomUrl => {
      this.isCustomUrl = isCustomUrl
    })
    this.globalService.isCustomUrlOwner.subscribe(isCustomUrlOwner => {
      this.isCustomUrlOwner = isCustomUrlOwner
    })
    
    if (this.isCustomUrl) {
      this.showUpgradeStorage = false
    }
    setTimeout(() => {
      if (this.isCustomUrlOwner) {
        this.showUpgradeStorage = true
      }
    }, 2000)

    let currentUserId =
      localStorage.getItem('userId') != undefined && localStorage.getItem('userId') != undefined
        ? parseInt(localStorage.getItem('userId'))
        : 0
    let docObj = { userId: currentUserId }
    this._docService.getTotalTeamMember(docObj).subscribe(res => {
      this.allowedTeamMember = res.allowedTeamMember
      this.addedTeamMember = res.addedTeamMember
      this.globalService.teamMemberUsed.next(res)
    })
    this._docService.getWeblinkCount(docObj).subscribe(res => {
      this.usedWeblink = res.used
      this.totalWeblink = res.count
      this.globalService.useWebDataUsed.next(res.used)
      this.globalService.useWebDataCount.next(res.count)
    })

    this._docService.getWorkflowCount().subscribe((res:any)=>{
      this.createdWF = res.createdWorkflow;
      // this.totalWF = res.count;
      this.globalService.workflowUsed.next(res)
    },
    error => console.log(error))

    this.globalService.refreshWebLinkCount.subscribe(resfreshWeblinkStatus => {
      if (resfreshWeblinkStatus) {
        this._docService.getWeblinkCount(docObj).subscribe(res => {
          this.usedWeblink = res.used
          this.totalWeblink = res.count
        })
      }
    })
    this.globalService.userPermission.subscribe(userPermission => {
      this.userPermission = userPermission
    })
    this.globalService.teamMemberUsed.subscribe(teamMemberUsed=>{
      this.allowedTeamMember = teamMemberUsed.allowedTeamMember
      this.addedTeamMember = teamMemberUsed.addedTeamMember
    })
    this.globalService.workflowUsed.subscribe(createdWorkflow=>{
      this.createdWF = createdWorkflow.createdWorkflow;
    })
  }

  upgradeQR() {
    let userId = localStorage.getItem('userId')
    let emailId = localStorage.getItem('userEmail')
    let password = localStorage.getItem('userPassword')
    // const dialogRef = this.dialog.open( UpgradeStorageDialogComponent, {
    //   width: '550px',

    //   maxWidth: '95vw',
    //   disableClose: true
    // });

    const editEmailRef = this.modalService.create({
      nzTitle: 'Manage Subscription',
      nzContent: UpgradeStorageDialogComponent,
      nzFooter: null,
      nzWidth: 940,
      nzStyle: { top: '32px' },
    })
  }
}
