
<div class="path-header">
    <h5 class="font-size-16">
        <i *ngIf="canNavigateUp" class="fa fa-angle-left mr-3" (click)="navigateUp()"></i> {{((path && path.length > 20) ? path.slice(0,20)+'...' : path) || selectedFolderObj.folderName}}
    </h5>
</div>
<nz-list *ngIf="fileElements.length>0">
    <ng-container *ngFor="let element of fileElements; let i=index;">
        <nz-list-item  (click)="navigate(element)" [ngClass]="(selectedFolderItem==i) ? 'bg-dark text-light' : ''"> 
            <p (click)="onSelect(element,i)" class="w-100 d-inline-flex align-items-center justify-content-between">
                <span class="ellipsis-width">
                    <i class="fa fa-folder-o mr-3 icont2"></i>         
                    <span nz-button nzTooltipTitle="{{element.name}}" nzTooltipPlacement="bottom" nz-tooltip>{{(element.name.length > 25 ? element.name.slice(0,25)+'...' : element.name)}}</span>
                </span>
                <span class="right-icon icont2"><i class="fa fa-angle-right mr-0"></i></span>
            </p>
        </nz-list-item>
    </ng-container>
    

</nz-list>

<nz-list *ngIf="(( objectKeys(fileElements).length==0)||(fileElements.hasOwnProperty('folder_id')))">
    <nz-list-item>
        <p class="no-folder">No Folder found.</p>
    </nz-list-item>  
</nz-list>
<div class="text-right mt-3 pr-4">
    <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
    <button type="button" [disabled]="!moveBtn" class="ant-btn ant-btn-primary" (window:keypress)="onMoveAction($event)" (click)="modal.destroy(moveFolderId)">Move Here</button>
</div>

