<!-- <div>
    <h5>
        Rename {{data.workspace ? 'Workspace' : 'Folder'}}
    </h5>
</div> -->
<div >
    <nz-form-control nzErrorTip="Please input name!">
        <input type="text" nz-input placeholder="Enter new name" [(ngModel)]="data.name" (ngModelChange)="disableSave = false" (keyup.enter)="renameFolder()">
    </nz-form-control>
</div>

<div class="text-right mt-3">
    <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
    <button type="button" class="ant-btn ant-btn-primary" (click)="renameFolder()"
        [disabled]="!data.name?.trim() || disableSave">OK</button>
</div>