import { COMMA, ENTER } from '@angular/cdk/keycodes'
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  Input,
  Output,
} from '@angular/core'
import { Observable, forkJoin } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete'
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipSelectionChange as MatChipSelectionChange } from '@angular/material/legacy-chips'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { AuthService } from '../../services/common/auth.service'
import { GlobalService } from 'src/app/services/common/global.service'
import { environment } from '../../../environments/environment'
import { FolderService } from 'src/app/services/folder.service'
import { DocumentService } from 'src/app/services/document.service'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { CommonDocumentService } from 'src/app/services/common/common-document.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { EventEmitter } from 'stream'
import { ConsoleSqlOutline } from '@ant-design/icons-angular/icons'
// import swal from 'sweetalert2';

export interface Section {
  name: string
  updated: Date
}

@Component({
  selector: 'qr-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit, AfterViewInit {
  emailTags: string[] = []
  listOfSelectedValue: string[] = []
  enteredShareWith: any
  previousAllEmailTags: any
  isValidEmail: boolean = true
  isOptionClicked: boolean
  getAllEmailTags: any
  unchangedAllEmailTags = []
  isNotSelected(value: string): boolean {
    return this.emailTags.indexOf(value) === -1
  }
  shareDocForm: UntypedFormGroup
  public show: Boolean = false
  visible = true
  selectable = true
  removable = true
  addOnBlur = true
  separatorKeysCodes: number[] = [ENTER, COMMA]
  userEmailCtrl = new UntypedFormControl()
  viewShareAction = new UntypedFormControl()
  filteredEmailTags: Observable<string[]>
  allEmails: string[] = []
  allEmailTags: string[] = []
  removeEmailTags: string[] = []
  allEmailTagsArr: string[] = []
  sharedEmail: any = []
  updateShareData: any = []
  //updateActionVal: string;
  updateChange: boolean = false
  deleteShareId: any = []
  deleteAction: boolean = false
  currentSelectedItem: any = null
  selectedSharedEmail: any = []
  isActionVal: string = 'Comment'
  currentUser: any = {}
  downloadVal: any = false
  checkEmail: boolean = false
  maxEmailsReached:boolean = false;
  maxEmails:any = 10;
  anybodyVal: any = false
  emailTagsAll: any = []
  getRightEmail: any = []
  invalidEmail: boolean = false
  selectedVal: string = 'Comment'
  docExt = environment.docExt.split(',').map(v => v.trim().toLowerCase())
  apiUrl: string = environment.apiUrl
  shareUrl: any = ''
  teamObj: any = null
  teamUserArr: any = {}
  selectedFolderArr: any = []
  shareableLink: boolean = true
  isWorkspace: boolean = false
  disableCopyLink: boolean = true
  onSharebtnLoader: boolean = false
  unsubscribeEmail: any = []
  callApi: boolean = false
  isContentLoaded: boolean = false
  allowAnonymousLink: boolean = true;
  isSetView = false;
  isPdf: boolean = false
  proofSetting: any = {};
  userProfileSetting: any = {}
  isCustomUrl: boolean = false;
  teamName = ''
  appSettingInfo: any;
  proofViewMode = [{label : "Actual Size", value : "page-actual"},
            {label : "Page Fit", value : "page-fit"},
            {label : "Page Width", value : "page-width"},
            {label : "50%" , value : 50},
            {label : "100%", value : 100},
            {label : "125%", value : 125},
            {label : "150%", value : 150},
            {label : "200%", value : 200},
            {label : "300%", value : 300},
            {label : "400%", value : 400}]



  @Input() data: any

  @ViewChild('emailTagsInput') emailTagsInput: ElementRef<HTMLInputElement>
  @ViewChild('auto') matAutocomplete: MatAutocomplete
  customAppSetting: any
  selectedItem: any
  selectedDocumentArr: any
  firstCall: boolean = true
  @ViewChild('emailIpt') emailIpt: ElementRef
  advanceShareSettingsObj : any = {};
  isPasswordChecked : boolean = false;
  checkPasswordLength : boolean = false
  passwordValidation = false
  documentPassword: any = ""
  checked = false


  constructor(
    public modal: NzModalRef,
    private _authService: AuthService,
    private snackBar: MatSnackBar,
    private _folderService: FolderService,
    private _docService: DocumentService,
    private globalService: GlobalService,
    private commonDocService: CommonDocumentService,
    private notification: NzNotificationService,
  ) {
    this.filteredEmailTags = this.userEmailCtrl.valueChanges.pipe(
      startWith(null),
      map((emailTag: string | null) =>
        emailTag ? this._filter(emailTag) : this.allEmailTags.slice(),
      ),
    )
  }

  ngOnInit() {
    this.globalService.currentSelectedItem.subscribe(currentSelectedItem => {
      this.currentSelectedItem = currentSelectedItem
    })

    this.globalService.userInfo.subscribe(userInfo => {
      let userSett = userInfo.userSettings;
      this.userProfileSetting = userInfo.userSettings;
      this.allowAnonymousLink = userSett.allowAnonymousLink != undefined
        ? Boolean(userSett.allowAnonymousLink)
        : true
    })

    this.globalService.customAppSetting.subscribe(userSetting => {
      if (userSetting.appUrlOwnerId != userSetting.userId) {
        try {
          let userSett = JSON.parse(userSetting.userSetting);
          this.allowAnonymousLink = userSett.allowAnonymousLink == 0 ? false : true;
        } catch (e) { }
      }
    })

    if (this.currentSelectedItem == 'Folder') {
      let folderId = Array.isArray(this.data) ? this.data : [this.data.id]
      this._folderService.folderShareSetting({ folderId: folderId }).subscribe(
        res => {
          if (res) {
            this.selectedVal = res.share_action
            this.downloadVal = Boolean(res.download)
            this.anybodyVal = Boolean(res.anybody)
            if (res.anonymous_password) {
              this.documentPassword = atob(res.anonymous_password)
              this.isPasswordChecked = true
            }
          } else {
            this.downloadVal =
              this.currentUser?.userSettings?.disableDownload != undefined
                ? Boolean(this.currentUser.userSettings.disableDownload)
                : true
          }
          this.initSharedFolderEmail()
        },
        err => {
          this.downloadVal =
            this.currentUser?.userSettings?.disableDownload != undefined
              ? Boolean(this.currentUser.userSettings.disableDownload)
              : true
          this.initSharedFolderEmail()
        },
      )
    } else {
      let documentId = Array.isArray(this.data.documentId)
        ? this.data.documentId
        : [this.data.documentId]
      this._docService.documentShareSetting({ documentId: documentId }).subscribe(
        res => {
          if (res) {

            this.selectedVal = res.share_action
            this.downloadVal = Boolean(res.download)
            this.anybodyVal = Boolean(res.anybody)
            if (res.anonymous_password) {
              this.documentPassword = atob(res.anonymous_password)
              this.isPasswordChecked = true
            }
            if (res.proofSetting && Object.keys(res.proofSetting).length) {
              this.shareDocForm.get('isSetView')?.setValue(true)
              this.shareDocForm.get('pageFit')!.setValue(res.proofSetting.pageFit)
              this.shareDocForm.get('spreadMode')!.setValue(res.proofSetting.spreadMode)
              // localStorage.setItem('setView', res.proofSetting);
            }
          } else {
            this.downloadVal =
              this.currentUser?.userSettings?.disableDownload != undefined
                ? Boolean(this.currentUser.userSettings.disableDownload)
                : Boolean(this.userProfileSetting.disableDownload)
                this.checked = false
          }

          this.initSharedEmail()
        },
        err => {
          this.downloadVal =
            this.currentUser?.userSettings?.disableDownload != undefined
              ? Boolean(this.currentUser.userSettings.disableDownload)
              : Boolean(this.userProfileSetting.disableDownload)
          this.initSharedEmail()
        },
      )
    }
    this.shareDocForm = new UntypedFormGroup({
      shareEmail: new UntypedFormControl('', [Validators.required]),
      shareAction: new UntypedFormControl('Comment', [Validators.required]),
      download: new UntypedFormControl('', []),
      anybody: new UntypedFormControl('', []),
      pageFit: new UntypedFormControl('page-width', []),
      isSetView: new UntypedFormControl('false', []),
      spreadMode: new UntypedFormControl('spreadSingle', []),
      note: new UntypedFormControl('', []),
    })

    if (
      this.globalService.selectedFolderObj.value.workspaceId == 0 ||
      this.globalService.selectedFolderObj.value.workspaceId == undefined
    )
      this.globalService.userInfo.subscribe(userInfo => {
        this.currentUser = userInfo
      })
    else
      this._folderService
        .getWorkspaceOwner({ folderId: this.globalService.selectedFolderObj.value.workspaceId })
        .subscribe(res => {
          this.currentUser = res
        })

    this.globalService.selectedFolderArr.subscribe(selectedFolderArr => {
      if (selectedFolderArr.length > 0) {
        selectedFolderArr.map(v => {
          if (v.isWorkspace == 1) {
            this.shareableLink = false
            this.isWorkspace = true
          }
        })
      }
    })

    this.globalService.selectedDocumentArr.subscribe(selectedDocumentArr => {
      this.selectedDocumentArr = selectedDocumentArr
      // console.log(this.selectedDocumentArr);
      if (selectedDocumentArr.length > 1) this.shareableLink = false
      for (let i = 0; i < this.selectedDocumentArr.length; i++) {
        if (this.selectedDocumentArr[i].documentType != 'pdf') {
          this.isPdf = false;
          break;
        } else this.isPdf = true
      }
    })

    this.globalService.customAppSetting.subscribe(customAppSetting => {
      this.customAppSetting = customAppSetting
    })

    // this._authService.getAllUnsubscribeEmail().subscribe(result => {
    //   this.unsubscribeEmail = result
    // })
    this.shareDocForm.get('isSetView')!.valueChanges.subscribe((isSetView: boolean) => {
      this.isSetView = isSetView
      this.handleCallApi()
      if (!this.isSetView) {
        this.shareDocForm.get('pageFit')!.setValue('page-width')
        this.shareDocForm.get('spreadMode')!.setValue('spreadSingle')
        // localStorage.removeItem('setView');
      }
    });

    this.globalService.isCustomUrl.subscribe(isCustomUrl => {
      this.isCustomUrl = isCustomUrl
    });
    this.globalService.teamName.subscribe(teamName => {
      this.teamName = teamName
    });
    this._authService.getAppSettings({ cname: ((this.isCustomUrl) ? window.location.host : ''), team: this.teamName }).subscribe(settingInfo => {
      this.appSettingInfo = settingInfo;
    });
  }

  getColor(item, unsubscribeEmail) {
    if (unsubscribeEmail.findIndex(value => value == item) == -1) {
      return null
    } else {
      return '#ff9100'
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.emailTagsInput.nativeElement.blur()
      this.initAllSharedUserUrl()
    }, 500)
    setTimeout(() => {
      this.isContentLoaded = true
    }, 1000)
    setTimeout(() => {
      this.callApi = false
    }, 1800)
  }

  saveShared() {
    //SetTimeout is used because if a user would click on share button immediately after typing email ID,
    //the email is not converted to tag and the array is empty
    setTimeout(() => {
      const newData = []
      this.emailTags.some((e, index) => {
        if (!this.unchangedAllEmailTags.includes(e)) {
          newData.push(e)
          this.emailTags.splice(index, 1)
        }
      })
      const isValid = []
      const correctEmail = []
      newData.forEach(n => {
        if (this.validateEmail(n)) {
          correctEmail.push(n)
        }
        isValid.push(this.validateEmail(n))
      })
      if (correctEmail?.length) {
        correctEmail.forEach(c => {
          this.emailTags.push(c)
        })
      }
      if (!isValid.length) {
        this.isValidEmail = true
      } else {
        if (isValid.includes(false)) {
          this.isValidEmail = false
          setTimeout(() => {
            this.emailIpt.nativeElement?.focus()
          })
        } else {
          this.isValidEmail = true
        }
      }
      if (this.currentSelectedItem == 'Folder') {
        this.saveSharedFolder()
      } else {
        this.saveSharedDoc()
      }
    }, 500)
  }

  saveSharedDoc() {
    this.passwordValidation = false
    this.checkPasswordLength = false
    this.emailTags.map(v => this.emailTagsAll.push(v))

    let shareEmailTmp = []
    for (let key in this.teamUserArr) shareEmailTmp = shareEmailTmp.concat(this.teamUserArr[key])

    let mergeEmail = this.emailTags.concat(shareEmailTmp)
    let rmDupliArr = mergeEmail.filter((v, i) => mergeEmail.indexOf(v) == i)
    let validateEmailArr = rmDupliArr.filter(v => this.validateEmail(v))
    let shareEmail = validateEmailArr.toString()
    let shareAction = this.shareDocForm.value.shareAction
    let note = this.shareDocForm.value.note
    let proofSetting: any;
    let anonymousPassword: any = ''

    if (this.isPasswordChecked) {
      if(!this.validatePassword()) return;
      if (this.documentPassword) anonymousPassword = btoa(this.documentPassword)
    } else {
      anonymousPassword = ''
    }

    if (this.isSetView) {
      proofSetting = { pageFit: this.shareDocForm.value.pageFit, spreadMode: this.shareDocForm.value.spreadMode }
    }
    let docObj = {
      documentId: Array.isArray(this.data.documentId)
        ? this.data.documentId
        : [this.data.documentId],
      versionId: Array.isArray(this.data.versionId) ? this.data.versionId : [this.data.versionId],
      shareEmail: shareEmail,
      shareAction: shareAction,
      download: Number(this.downloadVal),
      anybody: (this.allowAnonymousLink ? Number(this.anybodyVal) : 0),
      note: note,
      proofSetting,
      priv: this.globalService.userInfo.value.membershipId > 1 ? true : false,
      from: this.globalService.userInfo.value.userEmail,
      ownerId: this.customAppSetting.userId != undefined ? this.customAppSetting.userId : 0,
      anonymousPassword: anonymousPassword,
    }

    if (shareEmail.trim() != '') {
      this.onSharebtnLoader = true
      this.globalService.showLoader.next(true)
      this._docService.shareDocument(docObj).subscribe(
        res => {
          this.initSharedEmail()
          this.globalService.showLoader.next(false)
          this.checked = false
          // this.snackBar.open('Document shared.', '', { duration: 3000 })
          this.notification.create('success', null, 'Document shared.', {
            nzPlacement: 'bottomRight',
            nzClass: 'test-class',
          })
          this.cancelShare()
          this.onSharebtnLoader = false
          this.checked = false
        },
        err => {
          console.log('err', err)
          this.onSharebtnLoader = false
          this.checked == false
          this.documentPassword = null
        },
      )
    }
    // else if (shareEmail.trim() == '') {
    //   this.notification.create('error', null, 'User not selected', {
    //     nzPlacement: 'bottomRight',
    //     nzClass: 'test-class',
    //   })
    // } else {
    //   this.notification.create('error', null, 'checkbox on but no password not found', {
    //     nzPlacement: 'bottomRight',
    //     nzClass: 'test-class',
    //   })
    // }
  }

  saveSharedFolder() {
    this.firstCall = false
    let shareEmailTmp = []
    for (let key in this.teamUserArr) shareEmailTmp = shareEmailTmp.concat(this.teamUserArr[key])

    let mergeEmail = this.emailTags.concat(shareEmailTmp)
    let rmDupliArr = mergeEmail.filter((v, i) => mergeEmail.indexOf(v) == i)
    let validateEmailArr = rmDupliArr.filter(v => this.validateEmail(v))
    let shareEmail = validateEmailArr.toString()
    let shareAction = this.shareDocForm.value.shareAction
    let note = this.shareDocForm.value.note
    let folderObj = {
      folderId: Array.isArray(this.data) ? this.data : [this.data.id],
      shareEmail: shareEmail,
      shareAction: shareAction,
      download: Number(this.downloadVal),
      anybody: (this.allowAnonymousLink ? Number(this.anybodyVal) : 0),
      note: note,
      priv: this.globalService.userInfo.value.membershipId > 1 ? true : false,
      from: this.globalService.userInfo.value.userEmail,
      ownerId: this.customAppSetting.userId != undefined ? this.customAppSetting.userId : 0,
    }
    // if(this.isWorkspace) {
    //   folderObj.download = 1;
    // }
    let msg =
      this.data.isWorkspace == 1
        ? 'Workspace'
        : parseInt(localStorage.getItem('isWorkspace')) == 1
          ? 'Workspace'
          : 'Folder'
    if (shareEmail.trim() != '') {
      this.onSharebtnLoader = true
      this.globalService.showLoader.next(true)
      this._folderService.shareFolder(folderObj).subscribe(
        res => {
          this.initSharedFolderEmail()
          this.globalService.showLoader.next(false)
          // this.snackBar.open(msg + ' shared.', '', { duration: 3000 })
          this.notification.create('success', null, msg + ' shared.', {
            nzPlacement: 'bottomRight',
            nzClass: 'test-class',
          })
          this.cancelShare()
          this.onSharebtnLoader = false
        },
        err => {
          console.log('err', err)
          this.onSharebtnLoader = false
        },
      )
    }
  }

  destroyModal(): void {
    this.modal.destroy(false)
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return this.allEmailTags.filter(emailTag => emailTag.toLowerCase().indexOf(filterValue) >= 0)
  }

  cancelShare() {
    this.emailTags.map(emailTag => {
      let existFlag = this.allEmailTagsArr.filter(v => v == emailTag)
      if (existFlag.length > 0) {
        this.allEmailTags.push(emailTag)
        this.allEmailTags.sort()
      }
      let teamFilter = this.teamObj.filter(v => v.teamName == emailTag)
      if (teamFilter.length > 0) {
        delete this.teamUserArr[teamFilter[0].teamId]
        this.allEmailTags.push(emailTag)
        this.allEmailTags.sort()
      }
    })
    this.invalidEmail = false
    this.show = false
    this.emailTags = []
    this.emailTagsInput.nativeElement.value = ''
    this.userEmailCtrl.setValue(null)
    this.checked == false
    this.documentPassword == ''
  }

  initSharedEmail() {
    //disable the copy link button
    this.disableCopyLink = true
    this._docService
      .documentSharedUser({
        documentId: Array.isArray(this.data.documentId)
          ? this.data.documentId
          : [this.data.documentId],
        versionId: Array.isArray(this.data.versionId) ? this.data.versionId : [this.data.versionId],
      })
      .subscribe(
        res => {
          this.sharedEmail = res
          this.maxEmails = this.maxEmails - this.sharedEmail.length;
          // this.allEmailTags=this.allEmailTags.filter(email=>this.sharedEmail.indexOf(sE=>sE.shareEmail==email)==-1)
          this.allEmailTags = this.allEmailTags.filter(
            item1 => !this.sharedEmail.some(item2 => item2.shareEmail === item1),
          )
          if (res.length > 0) {
            let abArr = res.filter(v => v.anybody == 1)
            let dwArr = res.filter(v => v.download == 1)
            if ((dwArr.length > 0 || abArr.length > 0) && this.emailTagsAll.length == 0) {
              this.anybodyVal = Boolean(abArr.length > 0 ? abArr[0].anybody : 0)
              this.downloadVal = Boolean(dwArr.length > 0 ? dwArr[0].download : 0)
              // this.selectedVal = (abArr.length > 0) ? 'Comment' : 'View';
            } else {
              this.downloadVal = Boolean(this.downloadVal)
              this.anybodyVal = Boolean(this.anybodyVal)
            }
            this.sharedLink()
          } else {
            this.sharedLink()
          }
          //enable the copy link button
          this.disableCopyLink = false
        },
        err => console.log('err', err),
      )
  }

  initSharedFolderEmail() {
    this._folderService
      .folderSharedUser({ folderId: Array.isArray(this.data) ? this.data : [this.data.id] })
      .subscribe(
        res => {
          this.sharedEmail = res
          this.allEmailTags = this.allEmailTags.filter(
            item1 => !this.sharedEmail.some(item2 => item2.shareEmail === item1),
          )
          this.unchangedAllEmailTags = JSON.parse(JSON.stringify(this.allEmailTags))
          if (res.length > 0) {
            let abArr = res.filter(v => v.anybody == 1)
            let dwArr = res.filter(v => v.download == 1)
            if ((dwArr.length > 0 || abArr.length > 0) && this.emailTagsAll.length == 0) {
              this.anybodyVal = Boolean(abArr.length > 0 ? abArr[0].anybody : 0)
              this.downloadVal = Boolean(dwArr.length > 0 ? dwArr[0].download : 0)
              // this.selectedVal = (abArr.length > 0) ? 'Comment' : 'View';
            } else {
              this.downloadVal = Boolean(this.downloadVal)
              this.anybodyVal = Boolean(this.anybodyVal)
            }
            this.sharedLinkFolder()
          } else {
            // this.downloadVal = 1;
            this.sharedLinkFolder()
          }
        },
        err => console.log('err', err),
      )
  }
  pattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
  onChangeDownload(checked) {
    // this.firstCall = false
    // this.downloadVal = Boolean(checked ? 1 : 0)
    // if(this.isContentLoaded) {
    // if (this.currentSelectedItem == 'Folder') {
    //   this.sharedLinkFolder()
    // } else {
    //   this.sharedLink()
    // }
    // }
  }

  onChangeAnybody(checked, checked2) {
    if (!this.callApi) {
      return false
    }
    this.firstCall = false
    this.anybodyVal = Boolean(checked ? 1 : 0)
    this.downloadVal = Boolean(checked2 ? 1 : 0)
    // this.selectedVal = (e.checked) ? 'Comment' : 'View';
    if (this.isContentLoaded) {
      if (this.currentSelectedItem == 'Folder') {
        this.sharedLinkFolder()
      } else {
        this.sharedLink()
      }
    }
  }

  onUpdateShare(action, shareId) {
    let fData = this.updateShareData.filter(v => v.shareId == shareId)
    if (fData.length > 0) {
      let fMapData = this.updateShareData.map(v => {
        if (v.shareId == shareId) {
          v.shareAction = action
        }
        return v
      })
      this.updateShareData = fMapData
    } else {
      this.updateShareData.push({ shareId: shareId, shareAction: action })
    }

    this.updateChange = true
  }

  onSelectAction(action) {
    this.isActionVal = action
  }

  updateShared() {
    // let shareIdArr: any = '';
    // let parseShareId: any = '';
    // if (typeof this.updateShareId == 'number') {
    //   parseShareId = [this.updateShareId];
    // }
    // else {
    //   shareIdArr = this.updateShareId.split(',');
    //   parseShareId = shareIdArr.map(Number);
    // }
    let proofSetting: any = {};
    if (this.isSetView) {
      proofSetting = { pageFit: this.shareDocForm.value.pageFit, spreadMode: this.shareDocForm.value.spreadMode }
    }
    if (this.currentSelectedItem == 'Folder') {
      this._folderService
        .updateFolderShareAction({
          shareData: this.updateShareData,
          download: Number(this.downloadVal),
        })
        .subscribe(
          res => {
            this.cancelUpdateShare()
            this.initSharedFolderEmail()
            // this.snackBar.open('Share information updated.', '', { duration: 3000 })
            this.notification.create('success', null, 'Share information updated.', {
              nzPlacement: 'bottomRight',
            })
          },
          err => console.log('err', err),
        )
    } else {
      this._docService
        .updateDocumentShareAction({
          shareData: this.updateShareData,
          download: Number(this.downloadVal),
          proofSetting: {},
          // password: btoa(this.shareDocForm.value.password),
        })
        .subscribe(
          res => {
            this.cancelUpdateShare()
            this.initSharedEmail()
          },
          err => console.log('err', err),
        )
    }
  }

  cancelUpdateShare() {
    this.checked == false
    this.updateShareData = []
    this.updateChange = false

  }

  onDeleteShare(shareId, i, row) {
    if (this.deleteShareId.indexOf(shareId) == -1) {
      this.deleteShareId.push(shareId)
    }
    this.deleteAction = true
    this.selectedSharedEmail.push(i)
    this.allEmails.push(row)
    this.show = false
  }
  arrayUnique(array) {
    var a = array.concat()
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1)
      }
    }

    return a
  }

  deleteShared() {
    if (this.currentSelectedItem == 'Folder') {
      this._folderService.deleteFolderShare({ shareId: this.deleteShareId }).subscribe(
        res => {
          this.cancelDeleteShare()
          this.initSharedFolderEmail()
          this.selectedSharedEmail = []
          // this.snackBar.open('Folder unshared.', '', { duration: 3000 })
          this.allEmailTags = this.arrayUnique(this.allEmails.concat(this.allEmailTags))
          this.notification.create('success', null, 'Folder unshared', {
            nzPlacement: 'bottomRight',
            nzClass: 'test-class',
            nzDuration: 4500,
          })
        },
        err => console.log('err', err),
      )
    } else {
      this._docService.deleteDocumentShare({ shareId: this.deleteShareId }).subscribe(
        res => {
          this.cancelDeleteShare()
          this.initSharedEmail()

          this.selectedSharedEmail = []
          // this.snackBar.open('Document unshared.', '', { duration: 3000 })

          // this.sharedEmail = this.sharedEmail.filter(
          //     item1=> !this.allEmailTags.some(item2 => item2 == item1.shareEmail),
          //    )
          //  this.allEmailTags = this.allEmailTags.filter(
          //   item1 => this.sharedEmail.some(item2 => item2.shareEmail === item1),
          // )
          this.allEmailTags = this.arrayUnique(this.allEmails.concat(this.allEmailTags))
          this.notification.create('success', null, 'Document unshared', {
            nzPlacement: 'bottomRight',
            nzClass: 'test-class',
          })
        },
        err => console.log('err', err),
      )
    }
  }

  cancelDeleteShare() {
    this.deleteShareId = []
    this.deleteAction = false
    this.selectedSharedEmail = []
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode !== 13) {
      // this.dialogRef.close(false);
      this.isOptionClicked = false
    } else {
      this.isOptionClicked = true
    }
  }

  validateEmail(email) {
    if (
      /^([0-9a-zA-Z]([-_\\.']*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/.test(
        email,
      )
    ) {
      return true
    }

    return false
  }

  initAllSharedUserUrl() {
    let ownerId = this.customAppSetting.userId != undefined ? this.customAppSetting.userId : 0
    let folderId = 0
    let documentId = 0
    if (this.currentSelectedItem == 'Folder') {
      folderId = Array.isArray(this.data) ? this.data[0] : this.data.id ? this.data.id : 0
    } else {
      documentId = Array.isArray(this.data.documentId)
        ? this.data.documentId[0]
        : this.data.documentId
          ? this.data.documentId
          : 0
    }
    let sharedEmailRes = this._docService.getAllSharedUserUrl(ownerId, folderId, documentId)
    let teamRes = this._authService.getAllTeamInfo(ownerId, folderId, documentId)

    forkJoin([sharedEmailRes, teamRes]).subscribe(results => {
      this.allEmailTags = results[0]
      this.allEmailTags.sort()
      let shareEmailArr = this.sharedEmail.map(v => v.shareEmail)
      let allEmailFilter = this.allEmailTags.filter(v => shareEmailArr.indexOf(v) == -1)
      this.allEmailTags = allEmailFilter
      this.teamObj = results[1]
      this.teamObj.map(v => {
        this.allEmailTags.splice(0, 0, v.teamName)
      })
      this.allEmailTagsArr = results[0].map(v => v)
    })
    // this._docService.getAllSharedUserUrl()
    //   .subscribe(
    //     res => {
    //       this.allEmailTags = res;
    //       this.allEmailTags.sort();
    //       let shareEmailArr = this.sharedEmail.map(v => v.shareEmail);
    //       let allEmailFilter = this.allEmailTags.filter(v => shareEmailArr.indexOf(v) == -1);
    //       this.allEmailTags = allEmailFilter;
    //       this.allEmailTagsArr = res.map(v => v);
    //     },
    //     err => console.log('err', err)
    //   )
  }

  sharedLink() {
    //disable the copy link button
    this.disableCopyLink = true
    let documentId = Array.isArray(this.data.documentId)
      ? parseInt(this.data.documentId.toString())
      : this.data.documentId
    let versionId = Array.isArray(this.data.versionId)
      ? parseInt(this.data.versionId.toString())
      : this.data.versionId
    let userId = Array.isArray(this.data.userId)
      ? parseInt(this.data.userId.toString())
      : this.data.userId
    let documentType = Array.isArray(this.data.documentType)
      ? this.data.documentType.toString()
      : ['pdf', 'zip'].indexOf(this.data.documentType) != -1
        ? this.data.documentType
        : this.data.documentContentType
    let documentTitle = Array.isArray(this.data.documentTitle)
      ? this.data.documentTitle.toString()
      : this.data.documentTitle
    documentType =
      documentType == undefined && this.selectedDocumentArr.length == 1
        ? this.selectedDocumentArr[0].documentType
        : documentType
    documentTitle =
      documentTitle == undefined && this.selectedDocumentArr.length == 1
        ? this.selectedDocumentArr[0].documentTitle
        : documentTitle
    if (this.docExt.indexOf(documentType) != -1) {
    } else {
      let proofSetting: any = {};
      if (this.isSetView) {
        proofSetting = { pageFit: this.shareDocForm.value.pageFit, spreadMode: this.shareDocForm.value.spreadMode }
      }
      let docObj = {
        documentId: documentId,
        versionId: versionId,
        shareAction: this.selectedVal,
        download: Number(this.downloadVal),
        anybody: Number(this.anybodyVal),
        proofSetting: proofSetting,
        anonymousPassword : btoa(this.documentPassword)
      }
      this._docService.getSharedProofToken(docObj).subscribe(
        async res => {
          let ver = res.version == 2 ? 'v2' : ''
          if (documentType == 'zip' || documentType == 'audio') ver = 'v2'
          let p_url = window.location.protocol + '//' + window.location.hostname + '/proof/'

          /////////////No longer needed as old proofing is not needed anymore
          // if (documentType == 'video') {
          //   // p_url += "video" + ver;
          // } else if (documentType == 'audio') {
          //   p_url += 'audiov2' + ver
          // } else if (documentType == 'zip') {
          //   p_url += 'html' + ver
          //   if (this.globalService.docDetail.value['DocumentVersions'][0].frame_hw == '1')
          //     p_url = p_url.replace('https://', 'http://')
          // } else if (documentType == 'weblink') {
          //   p_url += 'weblink' + ver
          //   if (this.globalService.docDetail.value['DocumentVersions'][0].frame_hw == '1')
          //     p_url = p_url.replace('https://', 'http://')
          // } else {
          //   if (this.globalService.docDetail.value['upload_source'] == 'Dropbox') p_url += 'imagev2'
          //   else if (this.globalService.docDetail.value['DocumentVersions'][0].video_hw != null)
          //     p_url += 'imagev2'
          //   else p_url += 'pdf' + ver
          // }
          // if (documentType == 'webproof') {
          //   this.shareUrl = this.selectedDocumentArr[0].documentFileName + '/#/' + res.token;
          // }

          // if (documentType == 'video') {
          //   p_url += `video/${userId}/${res.token}`;
          //   if(p_url.indexOf('localhost') > -1) p_url = p_url.replace('localhost/proof', 'localhost:4300');
          //   this.shareUrl = p_url;
          //   return;
          // }

          if (res.proofUrl != '') {
            this.shareUrl = res.proofUrl
          } else {
            this.shareUrl = p_url + '/index.php?firstParam=1&t=' + res.token
          }
        },
        err => {
          console.log(err)
        },
      )
    }
    //enable the copy link button
    this.disableCopyLink = false
  }

  sharedLinkFolder() {
    //disable the copy link button
    this.disableCopyLink = true
    let folderId = Array.isArray(this.data) ? parseInt(this.data.toString()) : this.data.id
    this._folderService
      .getFolderToken({
        folderId: folderId,
        shareAction: this.selectedVal,
        download: Number(this.downloadVal),
        anybody: Number(this.anybodyVal),
        firstCall: this.firstCall,
      })
      .subscribe(
        res => {
          this.shareUrl = res['link']
          //enable the copy link button
          this.disableCopyLink = false
        },
        err => console.log(err),
      )
  }

  copyToClipBoard() {
    var copyText = <HTMLInputElement>document.getElementById('shareCopyUrl')
    copyText.select()
    document.execCommand('copy')
  }

  searchEmail(val) {
    if (!val.length) {
      return
    }
    this.isValidEmail = true
    this.enteredShareWith = val
  }

  onBlurMethod() {
    if (this.enteredShareWith) {
      this.selectedOpt(this.enteredShareWith)
    }
  }
  //   // value = [...value.values()]
  //   //removeduplicateEnd

  //   //   let uniqueChars = value.filter((c, index) => {
  //   //     return value.indexOf(c) === index;
  //   // });last
  // }

  changeModal(value): void {
    this.isOptionClicked = false
    const lastElement = value[value.length - 1] ? value[value.length - 1] : null
    const emailReg = /^[a-zA-Z]+[a-z0-9A-Z._+a-z]+@[a-z0-9]+\.[a-z.]{2,5}$/
    // if (lastElement !== null) {
    //   this.isValidEmail = this.ValidateEmail(lastElement)
    // }

    // for (let i = 0; i < this.allEmailTags.length; i++) {
    //   for (let j = 0; j < this.emailTags.length; j++) {
    //     if (this.emailTags[j] !== this.allEmailTags[i]) {
    //       this.isValidEmail = true
    //     }
    //   }
    // }
    // if(this.emailTags.includes(lastElement) == false){
    //  this.isValidEmail = true
    // }

    //     for(let i =0;i<this.emailTags.length;i++){
    // if(this.emailTags[i]==lastElement){
    //   this.emailTags.splice(i,1)
    // }
    //     }
    // if(this.checkEmail){

    //   this.isValidEmail=true
    //   this.checkEmail=false
    // }

    // console.log(this.isValidEmail!=='undefined')
    this.selectedOpt(lastElement)
    if (!value?.length) {
      return
    }
  }
  // onKeyUp(value) {
  //   this.isValidEmail = this.ValidateEmail(value)
  //   if (!this.isValidEmail) {
  //     setTimeout(() => {
  //       const lastData = this.emailTags[this.emailTags.length - 1]
  //       this.emailTags.splice(this.emailTags.length - 1, 1)
  //       const existFlag = this.allEmailTagsArr.filter(v => v === lastData)
  //       if (existFlag.length > 0) {
  //         // this.allEmailTags.push(emailTag)
  //         // this.allEmailTags.sort()
  //       } else {
  //         // this.allEmailTags.push(emailTag)
  //         // this.allEmailTags.sort()
  //         setTimeout(() => {
  //           for (let i = 0; i < this.allEmailTags.length; i++) {
  //             if (this.allEmailTags[i] == lastData) {
  //               const index = this.allEmailTags.indexOf(this.allEmailTags[i])
  //               this.allEmailTags.splice(index, 1)
  //             }
  //           }
  //         }, 0)
  //       }

  //       this.filteredEmailTags = this.userEmailCtrl.valueChanges.pipe(
  //         startWith(null),
  //         map((userEmail: string | null) =>
  //           userEmail ? this._filter(userEmail) : this.allEmailTags.slice(),
  //         ),
  //       )
  //     }, 1000)
  //   }
  //   if(!this.isValidEmail){
  //     this.emailTags=[]
  //   }
  // }
  onFocus() {
    this.show = true
  }

  selectedOpt(val) {
    if (this.currentUser.membershipId === 1  && this.appSettingInfo.appUrlType == 'default') {
      let maxEmails:any = this.maxEmails
      if (maxEmails > 0 && maxEmails > this.emailTags.length){
      }else{
        this.maxEmailsReached = true;
        return;
      }
    }
    var index = this.allEmailTags.indexOf(val)
        if (index > -1) {
          this.allEmailTags.splice(index, 1)
        }
        let teamFilter = this.teamObj.filter(v => v.teamName == val.trim())
        this.filteredEmailTags = this.userEmailCtrl.valueChanges.pipe(
          startWith(null),
          map((emailTag: string | null) =>
            emailTag ? this._filter(emailTag) : this.allEmailTags.slice(),
          ),
        )
        if (teamFilter.length > 0) {
          this._authService.getTeamUser({ teamId: teamFilter[0].teamId }).subscribe(
            res => {
              this.teamUserArr[teamFilter[0].teamId] = res
            },
            err => console.log(err),
          )
        }

    // else{
    // let uniqueChars = this.emailTags.filter((c, i) => this.emailTags.indexOf(c) === i)
    // this.emailTags = uniqueChars

    this.addEmailsToEmailTags(val);
    this.enteredShareWith = null
    this.show = true
  }

  changeAction(val) {
    // this.anybodyVal = (val == 'Comment') ? 1 : 0;
    this.selectedVal = val
    this.firstCall = false
    if (this.currentSelectedItem == 'Folder') {
      this.sharedLinkFolder()
    } else {
      this.sharedLink()
    }
  }

  handleCheckBoxActivity(e) {

  }
  // function to validate value and push or add email value to emailsTags array.
  addEmailsToEmailTags(value: string) {
    if (this.currentUser.membershipId == 1 && this.appSettingInfo.appUrlType == 'default' ) {
      if (this.maxEmails>0 &&  this.maxEmails > this.emailTags.length) {
      } else {
        this.maxEmailsReached = true;
        return;
      }
    }
    if ((value || '').trim()) {
      this.emailTags.push(value.trim())
      let rmDupliArr = this.emailTags.filter((v, i) => this.emailTags.indexOf(v) == i)
      this.emailTags = rmDupliArr;
      this.checkEmail = true
    }
  }

  add(event: MatChipInputEvent): void {
    if (!event.value) {
      return
    }
    console.log('event', event);
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    this.show = true
    // if (!this.matAutocomplete.isOpen) {
    const input = event.input
    const value = event.value
    if (value == '') this.invalidEmail = false

    if (value.match(/[ ,]+/g) == null) {
      if (this.validateEmail(value)) {
        this.invalidEmail = false
        // Add our emails
        this.addEmailsToEmailTags(value);
        // Reset the input value
        if (input) input.value = ''
        this.userEmailCtrl.setValue(null)
      } else if (value.trim()) {
        this.invalidEmail = true
      }
    } else {
      let invalidEmailArr = []
      if (value.match(/[ ,]+/g) != null) {
        let emailArr = value.split(/[ ,]+/)
        let i = 1
        this.invalidEmail = false
        for (let eMval of emailArr) {
          if (this.validateEmail(eMval)) {
            // Add our emails
            if ((eMval || '').trim()) {
              this.emailTags.push(eMval.trim())
              let rmDupliArr = this.emailTags.filter((v, i) => this.emailTags.indexOf(v) == i)
              this.emailTags = rmDupliArr
            }
          } else if (eMval.trim()) {
            this.invalidEmail = true
            invalidEmailArr.push(eMval)
          }

          if (i == emailArr.length) {
            if (!this.invalidEmail) {
              // Reset the input value
              if (input) input.value = ''
              this.userEmailCtrl.setValue(null)
            } else {
              if (input) input.value = invalidEmailArr.join(',')
            }
          }

          i++
        }
      }
    }
  }
  remove(emailTag: string): void {
    const index = this.emailTags.indexOf(emailTag)
    this.maxEmailsReached= false ;
    if (index >= 0) {
      this.emailTags.splice(index, 1)
      if (this.currentUser.membershipId == 1 && this.appSettingInfo.appUrlType == 'default' && this.maxEmails < 10) {
        this.maxEmails=this.maxEmails + 1;
      }
    }


    let existFlag = this.allEmailTagsArr.filter(v => v == emailTag)
    if (existFlag.length > 0) {
      this.allEmailTags.push(emailTag)
      this.allEmailTags.sort()
    }
    let teamFilter = this.teamObj.filter(v => v.teamName == emailTag)
    if (teamFilter.length > 0) {
      delete this.teamUserArr[teamFilter[0].teamId]
      this.allEmailTags.push(emailTag)
      this.allEmailTags.sort()
    }
    this.filteredEmailTags = this.userEmailCtrl.valueChanges.pipe(
      startWith(null),
      map((emailTag: string | null) =>
        emailTag ? this._filter(emailTag) : this.allEmailTags.slice(),
      ),
    )
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(this.currentUser.membershipId === 1  && this.appSettingInfo.appUrlType == 'default'){
      if(this.maxEmails>0 && this.maxEmails > this.emailTags.length){
      }else{
        this.maxEmailsReached = true;
        return;
      }
    }
      var index = this.allEmailTags.indexOf(event.option.viewValue)
      if (index > -1) {
        this.allEmailTags.splice(index, 1)
      }
      this.emailTags.push(event.option.viewValue)
      this.emailTagsInput.nativeElement.value = ''
      this.userEmailCtrl.setValue(null)
      this.invalidEmail = false
      this.filteredEmailTags = this.userEmailCtrl.valueChanges.pipe(
        startWith(null),
        map((emailTag: string | null) =>
          emailTag ? this._filter(emailTag) : this.allEmailTags.slice(),
        ),
      )


  }

  handleCallApi() {
    this.callApi = true
  }

  changeViewMode(value) {
    this.proofSetting = {}
    if (this.isSetView) {
      this.handleCallApi()
      this.proofSetting = { pageFit: this.shareDocForm.value.pageFit, spreadMode: this.shareDocForm.value.spreadMode }
    }
  }

  changePassword() {
    this.checked = !this.checked
    this.passwordValidation = false
  }

  generateNewURL() {
    console.log(this.data)

    let docObj = {
      documentId: this.data.documentId,
      versionId: this.data.versionId,
      shareAction: this.selectedVal,
      download: Number(this.downloadVal),
      anybody: Number(this.anybodyVal),
      newToken : 1,
    }
    this._docService.getSharedProofToken(docObj).subscribe(res => {
      console.log(res)
      // let ver = res.version == 2 ? 'v2' : ''
      //     if (documentType == 'zip' || documentType == 'audio') ver = 'v2'
      //     let p_url = window.location.protocol + '//' + window.location.hostname + '/proof/'
      if (res.proofUrl != '') {
        this.shareUrl = res.proofUrl
      } //else {
      //   this.shareUrl = p_url + '/index.php?firstParam=1&t=' + res.token
      // }
    },
    error => console.log(error))

    // let ver = res.version == 2 ? 'v2' : ''
    // if (documentType == 'zip' || documentType == 'audio') ver = 'v2'
    // let p_url = window.location.protocol + '//' + window.location.hostname + '/proof/'
  }


  handleUpdatePasswordWithoutShare() {
    this.callApi = true
    if(this.checkPasswordLength){
      if(this.documentPassword.length > 5)  this.checkPasswordLength = false;
    }
    if(this.documentPassword) this.passwordValidation = false;
  }

  togglePassword(){
    this.callApi = true
    if(!this.isPasswordChecked) {
      this.documentPassword = ""
      this.passwordValidation = false;
      this.checkPasswordLength = false;
    }
  }

  validatePassword(){
    if(this.documentPassword == "") this.passwordValidation = true;
    else if(this.documentPassword.length < 6) this.checkPasswordLength = true;
    else {
      this.passwordValidation = false;
      this.checkPasswordLength = false;
    }
    if(this.passwordValidation || this.checkPasswordLength) return false;
    else return true;
  }

  onUpdate(){
    if(this.currentSelectedItem == 'Document'){
      this.passwordValidation = false;
      this.checkPasswordLength = false;
      if(this.isPasswordChecked) {
      if(!this.validatePassword()) return;
      }
    }
    this.modal.destroy({downloadVal:this.downloadVal,anybodyVal:this.anybodyVal,sharedEmail:this.sharedEmail,emailTagsAll:this.emailTagsAll})
  }
}
