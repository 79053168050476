<div class="font-weight-bolder" [class.pb-5]="isMobileView">
  <div class="heading d-flex justify-content-between" [class.mobile-heading]="settings.isMobileView">
    <div>
      <span *ngIf="!isMenuCollapse" class="">Storage</span>

    </div>

    <div class="text-right">
      <i nz-tooltip class="fe fe-cloud font-size-18" nzTooltipPlacement="right"
        [nzTooltipTitle]="isMenuCollapse ? usedSpace + ' of ' + totalSpace + ' used': ''" nzTheme="outline"></i>
    </div>

  </div>
  <div class="storageBottom">
    <nz-progress *ngIf="!isMenuCollapse" nzSize="small" [nzPercent]="usedPercentage" [nzShowInfo]="false"></nz-progress>
    <p *ngIf="!isMenuCollapse" class="mb-0">
      <span class="used mt-2">
        {{usedSpace}} of
      </span>
      <span class="used">
        {{totalSpace}} Used.
      </span>
    </p>
    <!-- <a *ngIf="showUpgradeStorage && (userType=='qr' || userType=='')" class="margin-left" (click)="upgradeQR()" >Manage Subscription</a>
    <a *ngIf="showUpgradeStorage && userType=='appsumo'" class="margin-left" (click)="upgradeQR()" >Stack AppSumo Code</a> -->


    <p class="mb-0">
      <span class="used" *ngIf="!isMenuCollapse">
        {{usedWeblink}} of {{totalWeblink}} Weblinks Used.
      </span>
    </p>
    <p class="mb-0">
      <span class="used" *ngIf="!isMenuCollapse && !isTeamUrl">
        {{addedTeamMember}} of {{allowedTeamMember}} Team Members Added.
      </span>
    </p>
    <p class="mb-0">
      <span class="used" *ngIf="!isMenuCollapse && userPermission?.allowWorkFlow">
        {{createdWF}} of {{userPermission?.allowWorkFlow}} Workflows Added.
      </span>
    </p>
    <a *ngIf="!isMenuCollapse && !isTeamUrl" class="margin-left" (click)="upgradeQR()">Manage Subscription</a>

  </div>

</div>

<!-- <a *ngIf="showUpgradeStorage" class="margin-left" (click)="upgradeQR()" >Add Weblinks</a> -->