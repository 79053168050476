import { Injectable } from '@angular/core'
import { GlobalService } from './common/global.service'
import { resolve } from 'url'

declare var PouchDB: any

@Injectable({
  providedIn: 'root',
})
export class PouchDataService {
  folderDB: string
  documentDB: string
  userAllNodesDB: string
  currentPage: string
  selectedFolderObj: any

  constructor(private _globalService: GlobalService) {
    this._globalService.selectedFolderObj.subscribe(
      selectedFolderObj => (this.selectedFolderObj = selectedFolderObj),
    )
  }

  getFoldersFromPouchDb(parentId) {
    this._globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    let folderDB = 'qr_' + this.currentPage + '_folders_' + localStorage.getItem('userId')
    let pouchObj = this
    return new Promise((resolve, reject) => {
      let folderDbObj = new PouchDB(folderDB)
      folderDbObj
        .allDocs({
          include_docs: true,
        })
        .then(function(result) {
          if (result.rows.length > 0) {
            let folderObj = []
            let workspaceObj = []
            let folderDataLoaded = false
            result.rows.map(folderArr => {
              if (
                folderArr.doc.parent == parentId ||
                (parentId == 0 && folderArr.doc.showInRoot == true) ||
                pouchObj.currentPage == 'trash'
              ) {
                if (folderArr.doc.isWorkspace == 1) {
                  workspaceObj.push(folderArr.doc)
                } else {
                  folderObj.push(folderArr.doc)
                  // if(pouchObj.currentPage == 'shared' && parentId > 0 && folderArr.doc.showInRoot == false) {
                  //   folderDataLoaded = true;
                  // }
                }
              }
            })
            // console.log('------------------folderObj', folderObj);
            // if(pouchObj.currentPage == 'shared' && parentId > 0 && !folderDataLoaded) {
            //   folderObj = [];
            // }
            if (folderObj.length > 0)
              folderObj.sort(function(a, b) {
                return b.lastModified - a.lastModified
              })
            if (workspaceObj.length > 0)
              workspaceObj.sort(function(a, b) {
                return b.lastModified - a.lastModified
              })
            let resObj = workspaceObj.concat(folderObj)
            if (resObj.length > 0) {
              resolve(resObj)
            } else {
              reject(false)
            }
          } else {
            reject(false)
          }
        })
        .catch(function(err) {
          reject(err)
        })
    })
  }

  getDocumentsFromPouchDb(parentId) {
    let limit = 5
    this._globalService.userInfo.subscribe(userInfo => {
      if (userInfo && userInfo.userSettings && userInfo.userSettings.recentDocSize) {
        limit = userInfo.userSettings.recentDocSize
      }
    })
    this._globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    let documentDB = 'qr_' + this.currentPage + '_files_' + localStorage.getItem('userId')
    let pouchObj = this
    return new Promise((resolve, reject) => {
      let documentDBObj = new PouchDB(documentDB)
      documentDBObj
        .allDocs({
          include_docs: true,
          attachments: true,
        })
        .then(async function(result) {
          if (result.rows.length > 0) {
            let fileObj = []
            await result.rows.map(docArr => {
              if (
                docArr.doc.folderId == parentId ||
                (parentId == 0 && docArr.doc.showInRoot == true) ||
                pouchObj.currentPage == 'starred' ||
                pouchObj.currentPage == 'recent'
              ) {
                fileObj.push(docArr.doc)
              }
            })
            if (fileObj.length > 0) {
              fileObj.sort(function(a, b) {
                return b.modifiedOn - a.modifiedOn
              })
              if (pouchObj.currentPage == 'recent' && fileObj.length > limit) {
                let filesToDelete = []
                let recentFileObj = []
                for (let k in fileObj) {
                  if (parseInt(k) > limit--) {
                    filesToDelete.push({
                      _id: fileObj[k]._id,
                      _rev: fileObj[k]._rev,
                      _deleted: true,
                    })
                  } else {
                    recentFileObj.push(fileObj[k])
                  }
                }
                pouchObj.updateFileData(filesToDelete, 'recent')
                resolve(recentFileObj)
              } else {
                resolve(fileObj)
              }
            } else {
              reject(false)
            }
          } else {
            reject(false)
          }
        })
        .catch(function(err) {
          reject(err)
        })
    })
  }

  setFoldersInPouchDb(folderObj, parentId, pageName) {
    // this._globalService.currentPage.subscribe(currentPage => this.currentPage = currentPage);
    let folderDB = 'qr_' + pageName + '_folders_' + localStorage.getItem('userId')
    if (folderObj.length > 0) {
      let pouchDbObj = this
      let folderDbObj = new PouchDB(folderDB)
      folderDbObj
        .allDocs({
          include_docs: true,
          startkey: 'folder_' + parentId + '_',
          endkey: 'folder_' + parentId + '_' + '\ufff0',
        })
        .then(function(result) {
          let pouchFolderArr = []
          if (result.rows.length > 0) {
            result.rows.map(folderArr => {
              pouchFolderArr[folderArr.id] = { id: folderArr.id, rev: folderArr.value.rev }
            })
          }
          for (let i in folderObj) {
            let id = 'folder_' + folderObj[i]['parent'] + '_' + folderObj[i]['id']
            folderObj[i]['_id'] = id
            if (pouchFolderArr[id] != undefined) {
              folderObj[i]['_rev'] = pouchFolderArr[id].rev
              delete pouchFolderArr[id]
            }
          }

          pouchDbObj.updateFolderData(folderObj, pageName)
          if (Object.keys(pouchFolderArr).length > 0) {
            let foldersToDelete = []
            let k = 0
            for (let j in pouchFolderArr) {
              foldersToDelete[k] = {
                _id: pouchFolderArr[j].id,
                _rev: pouchFolderArr[j].rev,
                _deleted: true,
              }
              k++
            }
            pouchDbObj.updateFolderData(foldersToDelete, pageName)
          }
        })
        .catch(function(err) {})
    } else {
      if (parentId == 0) this.resetFolderDataInPouchDb(pageName)
    }
  }

  setDocumentsInPouchDb(fileObj, parentId, pageName) {
    // this._globalService.currentPage.subscribe(currentPage => this.currentPage = currentPage);
    let documentDB = 'qr_' + pageName + '_files_' + localStorage.getItem('userId')
    if (fileObj.length > 0) {
      let documentDBObj = new PouchDB(documentDB)
      documentDBObj
        .allDocs({
          include_docs: true,
          startkey: 'file_' + parentId + '_',
          endkey: 'file_' + parentId + '_' + '\ufff0',
        })
        .then(async function(result) {
          let pouchFileArr = []
          let currentArr = []
          if (result.rows.length > 0) {
            result.rows.map(docArr => {
              pouchFileArr[docArr.id] = { id: docArr.id, rev: docArr.value.rev }
            })
          }
          for (let i in fileObj) {
            let id = 'file_' + fileObj[i]['folderId'] + '_' + fileObj[i]['documentId']
            fileObj[i]['_id'] = id
            if (pouchFileArr[id] != undefined) {
              fileObj[i]['_rev'] = pouchFileArr[id].rev
              delete pouchFileArr[id]
            }
            currentArr.push(id)
          }

          documentDBObj
            .bulkDocs(fileObj)
            .then(function(result) {
              // console.log('---------------result1',result);
            })
            .catch(function(err) {
              // console.log('---------------err1',err);
            })

          if (pageName == 'shared' && parentId == 0) {
            await documentDBObj
              .allDocs({
                include_docs: true,
              })
              .then(function(result) {
                if (result.rows.length > 0) {
                  result.rows.map(docArr1 => {
                    let extraArr = currentArr.includes(docArr1.id)
                    if (docArr1.doc.showInRoot == true && !extraArr) {
                      pouchFileArr[docArr1.id] = { id: docArr1.id, rev: docArr1.value.rev }
                    }
                  })
                }
              })
          }
          if (Object.keys(pouchFileArr).length > 0) {
            let filesToDelete = []
            let k = 0
            for (let j in pouchFileArr) {
              filesToDelete[k] = {
                _id: pouchFileArr[j].id,
                _rev: pouchFileArr[j].rev,
                _deleted: true,
              }
              k++
            }
            documentDBObj
              .bulkDocs(filesToDelete)
              .then(function(result) {})
              .catch(function(err) {})
          }
        })
        .catch(function(err) {})
    } else {
      if (parentId == 0) this.resetFileDataInPouchDb(pageName)
    }
  }

  // Start for get/set user nodes
  setUserNodesPouchDb(folderObj, action) {
    let userAllNodesObj = new PouchDB('qr_nodes_' + localStorage.getItem('userId'))
    let pouchDbObj = this
    if (folderObj.length > 0) {
      userAllNodesObj
        .allDocs({
          include_docs: true,
        })
        .then(function(result) {
          let pouchNodeArr = []
          if (result.rows.length > 0) {
            result.rows.map(folderArr => {
              pouchNodeArr[folderArr.id] = { id: folderArr.id, rev: folderArr.value.rev }
            })
          }
          for (let i in folderObj) {
            let id = 'node_' + folderObj[i]['id']
            folderObj[i]['_id'] = id
            if (pouchNodeArr[id] != undefined) {
              folderObj[i]['_rev'] = pouchNodeArr[id].rev
              if (action == 'trash') {
                folderObj[i]['_deleted'] = true
              }
              delete pouchNodeArr[id]
            }
          }
          pouchDbObj.updateFolderData(folderObj, 'nodes')

          if (action == 'insert' && Object.keys(pouchNodeArr).length > 0) {
            let foldersToDelete = []
            let k = 0
            for (let j in pouchNodeArr) {
              foldersToDelete[k] = {
                _id: pouchNodeArr[j].id,
                _rev: pouchNodeArr[j].rev,
                _deleted: true,
              }
              k++
            }
            pouchDbObj.updateFolderData(foldersToDelete, 'nodes')
          }
        })
        .catch(function(err) {})
    }
  }

  updateFolderData(dataObj, table) {
    if (table == 'nodes') {
      table = 'qr_nodes_' + localStorage.getItem('userId')
    } else if (table == 'trash') {
      table = 'qr_trash_folders_' + localStorage.getItem('userId')
    } else {
      table = 'qr_' + table + '_folders_' + localStorage.getItem('userId')
    }
    let tableObj = new PouchDB(table)
    tableObj
      .bulkDocs(dataObj)
      .then(function(result) {})
      .catch(function(err) {})
  }

  updateFileData(dataObj, table) {
    if (table == 'trash') {
      table = 'qr_trash_files_' + localStorage.getItem('userId')
    } else {
      table = 'qr_' + table + '_files_' + localStorage.getItem('userId')
    }
    let tableObj = new PouchDB(table)
    tableObj
      .bulkDocs(dataObj)
      .then(function(result) {})
      .catch(function(err) {})
  }

  emptyData(table) {
    if (table == 'nodes') {
      table = 'qr_nodes_' + localStorage.getItem('userId')
    } else if (table == 'trash') {
      table = 'qr_trash_folders_' + localStorage.getItem('userId')
    }
    return new Promise((resolve, reject) => {
      let tableObj = new PouchDB(table)
      tableObj
        .destroy()
        .then(function() {
          resolve(true)
        })
        .catch(function(err) {
          resolve(err)
        })
    })
  }

  emptyFileData(table) {
    if (table == 'trash') {
      table = 'qr_trash_files_' + localStorage.getItem('userId')
    }
    return new Promise((resolve, reject) => {
      let tableObj = new PouchDB(table)
      tableObj
        .destroy()
        .then(function() {
          resolve(true)
        })
        .catch(function(err) {
          resolve(err)
        })
    })
  }

  resetFileDataInPouchDb(table) {
    table = 'qr_' + table + '_files_' + localStorage.getItem('userId')
    return new Promise((resolve, reject) => {
      let tableObj = new PouchDB(table)
      tableObj
        .destroy()
        .then(function() {
          resolve(true)
        })
        .catch(function(err) {
          resolve(err)
        })
    })
  }

  resetFolderDataInPouchDb(table) {
    table = 'qr_' + table + '_folders_' + localStorage.getItem('userId')
    return new Promise((resolve, reject) => {
      let tableObj = new PouchDB(table)
      tableObj
        .destroy()
        .then(function() {
          resolve(true)
        })
        .catch(function(err) {
          resolve(err)
        })
    })
  }

  getUserNodesPouchDb(folderId, action) {
    return new Promise((resolve, reject) => {
      let userAllNodesDbObj = new PouchDB('qr_nodes_' + localStorage.getItem('userId'))
      userAllNodesDbObj
        .allDocs({
          include_docs: true,
        })
        .then(async function(result) {
          if (result.rows.length > 0) {
            let nodesObj = []
            let nodesWObj = []
            await result.rows.map(folderArr => {
              if (action == 'detail') {
                if (folderArr.doc.id == folderId) nodesObj.push(folderArr.doc)
              } else if (folderArr.doc.parent == folderId) {
                if (folderArr.doc.isWorkspace) nodesWObj.push(folderArr.doc)
                else nodesObj.push(folderArr.doc)
              }
            })
            if (nodesObj.length > 0)
              nodesObj.sort(function(a, b) {
                return b.lastModified - a.lastModified
              })
            if (nodesWObj.length > 0)
              nodesWObj.sort(function(a, b) {
                return b.lastModified - a.lastModified
              })
            let resObj = nodesWObj.concat(nodesObj)
            if (resObj.length > 0) {
              resolve(resObj)
            } else {
              reject(false)
            }
          } else {
            reject(false)
          }
        })
        .catch(function(err) {
          reject(false)
        })
    })
  }
  destroyUserNodes() {
    return new Promise((resolve, reject) => {
      let userAllNodesDbObj = new PouchDB('qr_nodes_' + localStorage.getItem('userId'))
      userAllNodesDbObj
        .destroy()
        .then(function() {
          resolve(true)
        })
        .catch(function(err) {
          resolve(err)
        })
    })
  }
  // End for get/set user nodes

  updateFolderInPouchDb(folderObj, action) {
    this._globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    let folderDB = 'qr_dashboard_folders_' + localStorage.getItem('userId')
    let pouchDbObj = this
    let folderObjArr = []
    if (folderObj.id != undefined) {
      folderObjArr.push(folderObj)
    } else {
      folderObjArr = folderObj
    }
    if (folderObjArr.length > 0) {
      let folderDbObj = new PouchDB(folderDB)
      let startkey = ''
      let endkey = ''
      if (folderObjArr.length == 1) {
        startkey = 'folder_' + folderObjArr[0].parent + '_' + folderObjArr[0].id
        endkey = 'folder_' + folderObjArr[0].parent + '_' + folderObjArr[0].id
      } else {
        startkey = 'folder_' + folderObjArr[0].parent + '_'
        endkey = 'folder_' + folderObjArr[0].parent + '_' + '\ufff0'
      }
      folderDbObj
        .allDocs({
          include_docs: true,
          startkey: startkey,
          endkey: endkey,
        })
        .then(function(result) {
          let pouchFolderArr = []
          if (result.rows.length > 0) {
            result.rows.map(folderArr => {
              pouchFolderArr[folderArr.id] = { id: folderArr.id, rev: folderArr.value.rev }
            })
          }
          for (let i in folderObjArr) {
            let id = 'folder_' + folderObjArr[i].parent + '_' + folderObjArr[i]['id']
            folderObjArr[i]['_id'] = id
            if (pouchFolderArr[id] != undefined) {
              folderObjArr[i]['_rev'] = pouchFolderArr[id].rev
              if (action == 'trash') {
                folderObjArr[i]['_deleted'] = true
              }
              delete pouchFolderArr[id]
            }
          }

          folderDbObj
            .bulkDocs(folderObjArr)
            .then(function(result) {
              let nodesArr = []
              if (folderObj.id != undefined) {
                nodesArr.push(folderObj)
              } else {
                nodesArr = folderObj
              }
              pouchDbObj.setUserNodesPouchDb(nodesArr, action == 'insert' ? 'insertOne' : action)
              if (action == 'trash') {
                for (let i in folderObjArr) {
                  delete folderObjArr[i]['_rev']
                  delete folderObjArr[i]['_deleted']
                }
                pouchDbObj.updateFolderData(folderObjArr, 'trash')
              }
            })
            .catch(function(err) {})
        })
        .catch(function(err) {})
    }
  }

  updateFolderTrashPouchDb(folderObj, action) {
    this._globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    let folderDB = 'qr_trash_folders_' + localStorage.getItem('userId')
    let pouchDbObj = this
    let folderObjArr = []
    if (folderObj.id != undefined) {
      folderObjArr.push(folderObj)
    } else {
      folderObjArr = folderObj
    }
    if (folderObjArr.length > 0) {
      let folderDbObj = new PouchDB(folderDB)
      let startkey = ''
      let endkey = ''
      if (folderObjArr.length == 1) {
        startkey = 'folder_' + folderObjArr[0].parent + '_' + folderObjArr[0].id
        endkey = 'folder_' + folderObjArr[0].parent + '_' + folderObjArr[0].id
      } else {
        startkey = 'folder_' + folderObjArr[0].parent + '_'
        endkey = 'folder_' + folderObjArr[0].parent + '_' + '\ufff0'
      }
      folderDbObj
        .allDocs({
          include_docs: true,
          startkey: startkey,
          endkey: endkey,
        })
        .then(function(result) {
          let pouchFolderArr = []
          if (result.rows.length > 0) {
            result.rows.map(folderArr => {
              pouchFolderArr[folderArr.id] = { id: folderArr.id, rev: folderArr.value.rev }
            })
          }
          for (let i in folderObjArr) {
            delete folderObjArr[i]['_id']
            let id = 'folder_' + folderObjArr[i].parent + '_' + folderObjArr[i]['id']
            folderObjArr[i]['_id'] = id
            if (pouchFolderArr[id] != undefined) {
              if (action == 'delete' || action == 'restore') {
                folderObjArr[i]['_deleted'] = true
              }
              delete pouchFolderArr[id]
            }
          }

          folderDbObj
            .bulkDocs(folderObjArr)
            .then(function(result) {
              if (action == 'restore') {
                for (let i in folderObjArr) {
                  delete folderObjArr[i]['_rev']
                  delete folderObjArr[i]['_deleted']
                }
                pouchDbObj.updateFolderInPouchDb(folderObjArr, 'insert')
              }
            })
            .catch(function(err) {})
        })
        .catch(function(err) {})
    }
  }

  async updateFileInPouchDb(fileObj, parentId, action) {
    let currentParent = parentId
    if (this.selectedFolderObj.folderId != parentId) {
      currentParent = this.selectedFolderObj.folderId
    }
    let fileObjArr = []
    if (fileObj.documentId != undefined) {
      fileObjArr.push(fileObj)
    } else {
      fileObjArr = fileObj
    }
    this._globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    let pagesArr = []
    if (
      this.currentPage == 'dashboard' ||
      this.currentPage == 'recent' ||
      this.currentPage == 'starred' ||  this.currentPage == 'reviewer'
    ) {
      pagesArr.push('dashboard')
      pagesArr.push('recent')
      if (
        fileObjArr[0].star == 1 ||
        action == 'add star' ||
        action == 'remove star' ||
        this.currentPage == 'starred'
      )
        pagesArr.push('starred')
    } else {
      pagesArr.push(this.currentPage)
    }
    let pouchDbObj = this
    for (let page in pagesArr) {
      let documentDB = 'qr_' + pagesArr[page] + '_files_' + localStorage.getItem('userId')
      if (fileObjArr.length > 0) {
        let documentDBObj = new PouchDB(documentDB)
        let startkey = ''
        let endkey = ''
        if (fileObjArr.length == 1) {
          startkey = 'file_' + currentParent + '_' + fileObjArr[0].documentId
          endkey = 'file_' + currentParent + '_' + fileObjArr[0].documentId
        } else {
          startkey = 'file_' + currentParent + '_'
          endkey = 'file_' + currentParent + '_' + '\ufff0'
        }
        await documentDBObj
          .allDocs({
            include_docs: true,
            startkey: startkey,
            endkey: endkey,
          })
          .then(async function(result) {
            let pouchFolderArr = []
            if (result.rows.length > 0) {
              await result.rows.map(folderArr => {
                pouchFolderArr[folderArr.id] = { id: folderArr.id, rev: folderArr.value.rev }
              })
            }
            let removeStar = []
            for (let i in fileObjArr) {
              let id = 'file_' + currentParent + '_' + fileObjArr[i]['documentId']
              fileObjArr[i]['_id'] = id
              if (pouchFolderArr[id] != undefined) {
                fileObjArr[i]['_rev'] = pouchFolderArr[id].rev
                if (pouchDbObj.selectedFolderObj.folderId != parentId) {
                  fileObjArr[i]['_deleted'] = true
                } else {
                  delete fileObjArr[i]['_deleted']
                }
                if (
                  (action == 'remove star' && pagesArr[page] == 'starred') ||
                  action == 'remove'
                ) {
                  removeStar[i] = {
                    _id: fileObjArr[i]._id,
                    _rev: fileObjArr[i]._rev,
                    _deleted: true,
                  }
                }
              } else {
                delete fileObjArr[i]['_rev']
              }
            }
            let dataToUpdate = fileObjArr
            if (removeStar.length > 0 || action == 'remove') {
              dataToUpdate = removeStar
            }
            let returnTrue = false
            if (result.rows.length == 0 && result.total_rows == 0) {
              returnTrue = true
            }
            if (pagesArr[page] == 'dashboard' && action == 'new') {
              returnTrue = false
            }
            if (!returnTrue) {
              await documentDBObj
                .bulkDocs(dataToUpdate)
                .then(async function(result) {
                  if (
                    pouchDbObj.selectedFolderObj.folderId != parentId &&
                    action != 'remove star' &&
                    pagesArr[page] != 'starred'
                  ) {
                    for (let i in fileObjArr) {
                      delete fileObjArr[i]['_id']
                      delete fileObjArr[i]['_rev']
                      delete fileObjArr[i]['_deleted']
                      let id = 'file_' + parentId + '_' + fileObjArr[i]['documentId']
                      fileObjArr[i]['_id'] = id
                    }
                    await pouchDbObj.updateFileData(fileObjArr, pagesArr[page])
                  }
                })
                .catch(function(err) {})
            }
          })
          .catch(function(err) {})
      }
    }
  }

  async updateNewFile(fileObj, action, pageName = null) {
    let parentId = this.selectedFolderObj.folderId
    let fileObjArr = []
    if (fileObj.documentId != undefined) {
      fileObjArr.push(fileObj)
    } else {
      fileObjArr = fileObj
    }
    let pagesArr
    if (action == 'new' || action == 'update share count') {
      pagesArr = ['dashboard', 'recent', 'shared']
    } else if (action == 'delete') {
      pagesArr = ['trash']
    } else if (action == 'update share' && pageName.length > 0) {
      pagesArr = pageName
    } else {
      pagesArr = ['dashboard', 'recent', 'starred', 'shared']
    }

    let pouchDbObj = this
    let currentUser = parseInt(localStorage.getItem('userId'))
    for (let page in pagesArr) {
      let documentDB = 'qr_' + pagesArr[page] + '_files_' + localStorage.getItem('userId')
      let documentDBObj = new PouchDB(documentDB)
      if (fileObjArr.length > 0) {
        for (let j in fileObjArr) {
          let docSharedWithUsersArr = fileObjArr[j].docSharedWithUsersArr
          if (
            docSharedWithUsersArr.includes(currentUser) ||
            action == 'delete' ||
            action == 'move'
          ) {
            parentId = fileObjArr[j].folderId
            if (
              action == 'new' &&
              parentId == 0 &&
              currentUser == fileObjArr[j].userId &&
              (pagesArr[page] == 'shared' || pagesArr[page] == 'starred')
            ) {
              continue
            }
            let id = 'file_' + parentId + '_' + fileObjArr[j].documentId
            fileObjArr[j]['_id'] = id
            let startkey, endkey
            startkey = 'file_' + parentId + '_' + fileObjArr[j].documentId
            endkey = 'file_' + parentId + '_' + fileObjArr[j].documentId
            if ((parentId > 0 && action == 'new') || action == 'move add' || action == 'share') {
              if (action == 'share') {
                if (fileObjArr[j].userWiseShare.length > 0) {
                  for (var l = 0; l < fileObjArr[j].userWiseShare.length; l++) {
                    if (fileObjArr[j].userWiseShare[l].userId == currentUser) {
                      fileObjArr[j].showInRoot = fileObjArr[j].userWiseShare[l].shareType
                        ? true
                        : false
                    }
                  }
                }
                parentId = fileObjArr[j].showInRoot == true ? 0 : parentId
              }
              startkey = 'file_' + parentId + '_'
              endkey = 'file_' + parentId + '_' + '\ufff0'
            } else if (action == 'move') {
              startkey = 'file_' + fileObjArr[j].oldParent + '_' + fileObjArr[j].documentId
              endkey = 'file_' + fileObjArr[j].oldParent + '_' + fileObjArr[j].documentId
            }
            await documentDBObj
              .allDocs({
                include_docs: true,
                startkey: startkey,
                endkey: endkey,
              })
              .then(async function(result) {
                let isFirstDoc = result.rows.length == 0 ? true : false
                let docToUpdate = []
                let docToTrash = []
                let docOwner = fileObjArr[j].docOwner
                if (result.rows.length > 0) {
                  for (let i in result.rows) {
                    if (fileObjArr[j].documentId == result.rows[i].doc.documentId) {
                      if (
                        action == 'new' ||
                        action == 'move add' ||
                        action == 'share' ||
                        action == 'update share'
                      ) {
                        let oldData = result.rows[i].doc
                        result.rows[i].doc = fileObjArr[j]
                        result.rows[i].doc._rev = oldData._rev
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'rename') {
                        result.rows[i].doc.documentTitle = fileObjArr[j].document_title
                        result.rows[i].doc.modifiedOn = fileObjArr[j].last_modified_on
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'set expiry') {
                        result.rows[i].doc.expiryDate = fileObjArr[j].expiry_date
                        result.rows[i].doc.neverExpire = fileObjArr[j].never_expire
                        result.rows[i].doc.expiryDate = fileObjArr[j].expiry_date
                        result.rows[i].doc.modifiedOn = fileObjArr[j].last_modified_on
                        result.rows[i].doc.reminderSetting = fileObjArr[j].reminderSetting
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'revision') {
                        let rev = result.rows[i].doc._rev
                        result.rows[i].doc = fileObjArr[j]
                        result.rows[i].doc._rev = rev
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'trash') {
                        docToUpdate.push({
                          _id: result.rows[i].doc._id,
                          _rev: result.rows[i].doc._rev,
                          _deleted: true,
                        })
                        delete result.rows[i].doc._rev
                        result.rows[i].doc.showInRoot = true
                        docToTrash.push(result.rows[i].doc)
                      } else if (
                        action == 'delete' ||
                        action == 'move' ||
                        action == 'delete share'
                      ) {
                        docToUpdate.push({
                          _id: result.rows[i].doc._id,
                          _rev: result.rows[i].doc._rev,
                          _deleted: true,
                        })
                      } else if (action == 'update status') {
                        result.rows[i].doc.wfStatusName = fileObjArr[j].wfStatusName
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'approve status') {
                        result.rows[i].doc.approveStatus = fileObjArr[j].approveStatus
                        result.rows[i].doc.reworkStatus = fileObjArr[j].reworkStatus
                        result.rows[i].doc.approveStatusTitle = fileObjArr[j].approveStatusTitle
                        result.rows[i].doc.reworkStatusTitle = fileObjArr[j].reworkStatusTitle
                        result.rows[i].doc.wfStatusName = fileObjArr[j].wfStatusName
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'update comment') {
                        result.rows[i].doc.totalComment = fileObjArr[j].totalComment
                        result.rows[i].doc.totalUserComment = fileObjArr[j].totalUserComment
                        result.rows[i].doc.versionNo = fileObjArr[j].versionNo
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'update share count') {
                        result.rows[i].doc.docSharedWith = fileObjArr[j].docSharedWith
                        result.rows[i].doc.totalShare = fileObjArr[j].totalShare
                        docToUpdate.push(result.rows[i].doc)
                      }
                    }
                  }
                }
                if (docToUpdate.length == 0 && action == 'new') {
                  if (
                    parentId == 0 &&
                    parseInt(localStorage.getItem('userId')) == fileObjArr[j].userId &&
                    pagesArr[page] != 'shared'
                  ) {
                    docToUpdate.push(fileObjArr[j])
                  } else if (
                    parseInt(localStorage.getItem('userId')) == fileObjArr[j].userId &&
                    pagesArr[page] == 'recent'
                  ) {
                    docToUpdate.push(fileObjArr[j])
                  } else if (parentId > 0) {
                    let isFolderExist = await pouchDbObj.checkFolderExist(pagesArr[page], parentId)
                    if (isFolderExist) {
                      docToUpdate.push(fileObjArr[j])
                    }
                  }
                }
                if (
                  (docToUpdate.length == 0 && action == 'move add' && !isFirstDoc) ||
                  (docToUpdate.length == 0 &&
                    action == 'share' &&
                    fileObjArr[j].userId != currentUser &&
                    pagesArr[page] == 'shared' &&
                    !isFirstDoc)
                ) {
                  docToUpdate.push(fileObjArr[j])
                }
                if (
                  docToUpdate.length == 0 &&
                  fileObjArr[j].userId != currentUser &&
                  action == 'share' &&
                  parentId > 0
                ) {
                  let isFolderExist = await pouchDbObj.checkFolderExist(pagesArr[page], parentId)
                  if (isFolderExist) {
                    docToUpdate.push(fileObjArr[j])
                  }
                }
                if (docToUpdate.length > 0) {
                  await pouchDbObj.updateInDB(documentDBObj, docToUpdate)
                  if (
                    action == 'trash' &&
                    docToTrash.length > 0 &&
                    pagesArr[page] == 'dashboard' &&
                    docOwner == currentUser
                  ) {
                    let documentDB = 'qr_trash_files_' + docOwner
                    let trashDBObj = new PouchDB(documentDB)
                    await pouchDbObj.updateInDB(trashDBObj, docToTrash)
                  }
                }
              })
              .catch(function(err) {})
          }
        }
      }
    }
  }

  async checkFolderExist(page, folderId) {
    let folderDB = 'qr_' + page + '_folders_' + localStorage.getItem('userId')
    return new Promise((resolve, reject) => {
      let folderDbObj = new PouchDB(folderDB)
      folderDbObj
        .allDocs({
          include_docs: true,
        })
        .then(function(result) {
          if (result.rows.length > 0) {
            let isFolderExist = false
            result.rows.map(folderArr => {
              if (folderArr.doc.id == folderId) {
                isFolderExist = true
              }
            })
            resolve(isFolderExist)
          } else {
            reject(false)
          }
        })
        .catch(function(err) {
          reject(false)
        })
    })
  }

  async updateInDB(documentDBObj, docToUpdate) {
    await documentDBObj
      .bulkDocs(docToUpdate)
      .then(async function(result) {
        // console.log('-------------------result', result);
      })
      .catch(function(err) {
        // console.log('-------------------err', err);
      })
  }

  async updateNewFolder(folderObj, action) {
    let parentId = this.selectedFolderObj.folderId
    let folderObjArr = folderObj
    let pagesArr
    if (action == 'delete') {
      pagesArr = ['trash']
    } else {
      pagesArr = ['dashboard', 'shared']
    }
    let pouchDbObj = this
    let currentUser = parseInt(localStorage.getItem('userId'))
    for (let page in pagesArr) {
      let folderDB = 'qr_' + pagesArr[page] + '_folders_' + localStorage.getItem('userId')
      let folderDBObj = new PouchDB(folderDB)
      if (folderObjArr.length > 0) {
        for (let j in folderObjArr) {
          let docSharedWithUsersArr = folderObjArr[j].docSharedWithUsersArr
          if (docSharedWithUsersArr.includes(currentUser) || action == 'delete') {
            parentId = folderObjArr[j].parent
            if (
              action == 'new' &&
              parentId == 0 &&
              currentUser == folderObjArr[j].userId &&
              pagesArr[page] == 'shared'
            ) {
              continue
            }
            if (
              action == 'share' &&
              folderObjArr[j].isWorkspace == 1 &&
              pagesArr[page] == 'shared'
              // || (action == 'share' &&
              //   folderObjArr[j].isWorkspace == 0 &&
              //   pagesArr[page] == 'dashboard')
            ) {
              continue
            }

            let id = 'folder_' + parentId + '_' + folderObjArr[j].id
            folderObjArr[j]['_id'] = id
            let startkey, endkey
            startkey = 'folder_' + parentId + '_' + folderObjArr[j].id
            endkey = 'folder_' + parentId + '_' + folderObjArr[j].id
            if ((parentId > 0 && action == 'new') || action == 'share') {
              startkey = 'folder_' + parentId + '_'
              endkey = 'folder_' + parentId + '_' + '\ufff0'
            }
            await folderDBObj
              .allDocs({
                include_docs: true,
                startkey: startkey,
                endkey: endkey,
              })
              .then(async function(result) {
                let docToUpdate = []
                let docToTrash = []
                let docOwner = folderObjArr[j].userId
                if (result.rows.length > 0) {
                  for (let i in result.rows) {
                    if (folderObjArr[j].id == result.rows[i].doc.id) {
                      if (action == 'new' || action == 'share') {
                        let oldData = result.rows[i].doc
                        result.rows[i].doc = folderObjArr[j]
                        result.rows[i].doc._rev = oldData._rev
                        if (action == 'share' && folderObjArr[j].showUserWise != undefined) {
                          for (let k in folderObjArr[j].showUserWise) {
                            if (
                              folderObjArr[j].showUserWise[k].userId == currentUser &&
                              folderObjArr[j].showUserWise[k].folderId == folderObjArr[j].id
                            )
                              result.rows[i].doc.showInRoot =
                                folderObjArr[j].showUserWise[k].showInRoot == 1
                                  ? true
                                  : result.rows[i].doc.showInRoot
                          }
                        }
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'rename') {
                        result.rows[i].doc.name = folderObjArr[j].name
                        result.rows[i].doc.lastModified = folderObjArr[j].lastModified
                        docToUpdate.push(result.rows[i].doc)
                      } else if (action == 'trash') {
                        docToUpdate.push({
                          _id: result.rows[i].doc._id,
                          _rev: result.rows[i].doc._rev,
                          _deleted: true,
                        })
                        delete result.rows[i].doc._rev
                        result.rows[i].doc.showInRoot = true
                        docToTrash.push(result.rows[i].doc)
                      } else if (action == 'delete' || action == 'delete share') {
                        docToUpdate.push({
                          _id: result.rows[i].doc._id,
                          _rev: result.rows[i].doc._rev,
                          _deleted: true,
                        })
                      }
                    }
                  }
                }

                if (docToUpdate.length == 0 && action == 'new') {
                  if (
                    parentId == 0 &&
                    parseInt(localStorage.getItem('userId')) == folderObjArr[j].userId &&
                    pagesArr[page] != 'shared'
                  ) {
                    docToUpdate.push(folderObjArr[j])
                  } else if (
                    parseInt(localStorage.getItem('userId')) == folderObjArr[j].userId &&
                    pagesArr[page] == 'dashboard'
                  ) {
                    docToUpdate.push(folderObjArr[j])
                  } else if (parentId > 0) {
                    let isFolderExist = await pouchDbObj.checkFolderExist(pagesArr[page], parentId)
                    if (isFolderExist) {
                      docToUpdate.push(folderObjArr[j])
                    }
                  }
                }

                if (
                  docToUpdate.length == 0 &&
                  action == 'share' &&
                  folderObjArr[j].userId != currentUser
                ) {
                  if (folderObjArr[j].showUserWise != undefined) {
                    for (let k in folderObjArr[j].showUserWise) {
                      if (
                        folderObjArr[j].showUserWise[k].userId == currentUser &&
                        folderObjArr[j].showUserWise[k].folderId == folderObjArr[j].id
                      )
                        folderObjArr[j].showInRoot =
                          folderObjArr[j].showUserWise[k].showInRoot == 1
                            ? true
                            : folderObjArr[j].showInRoot
                    }
                  }
                  if (
                    (parentId == 0 && pagesArr[page] == 'shared') ||
                    (folderObjArr[j].showInRoot && pagesArr[page] == 'shared') ||
                    (folderObjArr[j].isWorkspace == 1 && pagesArr[page] == 'dashboard')
                  ) {
                    docToUpdate.push(folderObjArr[j])
                  } else if (parentId > 0) {
                    let isFolderExist = await pouchDbObj.checkFolderExist(pagesArr[page], parentId)
                    if (isFolderExist) {
                      docToUpdate.push(folderObjArr[j])
                    }
                  }
                }
                // if(docToUpdate.length == 0 && fileObjArr[j].userId != currentUser && action == 'share' && parentId > 0) {
                //   let isFolderExist = await pouchDbObj.checkFolderExist(pagesArr[page], parentId)
                //     if (isFolderExist) {
                //       docToUpdate.push(fileObjArr[j])
                //     }
                // }

                if (docToUpdate.length > 0) {
                  await pouchDbObj.updateInDB(folderDBObj, docToUpdate)
                  if (
                    action == 'trash' &&
                    docToTrash.length > 0 &&
                    pagesArr[page] == 'dashboard' &&
                    docOwner == currentUser
                  ) {
                    let folderDB = 'qr_trash_folders_' + docOwner
                    let trashDBObj = new PouchDB(folderDB)
                    await pouchDbObj.updateInDB(trashDBObj, docToTrash)
                  }
                }
              })
              .catch(function(err) {})
          }
        }
      }
    }
  }

  async updateSidebarNodes(folderObjArr, action) {
    let sidebarNodesObj = new PouchDB('qr_nodes_' + localStorage.getItem('userId'))
    let pouchDbObj = this
    let currentUser = parseInt(localStorage.getItem('userId'))
    if (folderObjArr.length > 0) {
      for (let j in folderObjArr) {
        let docSharedWithUsersArr =
          folderObjArr[j].docSharedWithUsersArr != undefined
            ? folderObjArr[j].docSharedWithUsersArr
            : []
        if (docSharedWithUsersArr.includes(currentUser)) {
          let id = 'node_' + folderObjArr[j].id
          folderObjArr[j]['_id'] = id
          let startkey, endkey
          startkey = 'node_' + folderObjArr[j].id
          endkey = 'node_' + folderObjArr[j].id

          await sidebarNodesObj
            .allDocs({
              include_docs: true,
              startkey: startkey,
              endkey: endkey,
            })
            .then(async function(result) {
              let docToUpdate = []
              let docToTrash = []
              let docOwner = folderObjArr[j].userId
              if (result.rows.length > 0) {
                for (let i in result.rows) {
                  if (folderObjArr[j].id == result.rows[i].doc.id) {
                    if (action == 'new') {
                      result.rows[i].doc = folderObjArr[j]
                      docToUpdate.push(result.rows[i].doc)
                    } else if (action == 'rename') {
                      result.rows[i].doc.name = folderObjArr[j].name
                      result.rows[i].doc.lastModified = folderObjArr[j].lastModified
                      docToUpdate.push(result.rows[i].doc)
                    } else if (action == 'trash') {
                      docToUpdate.push({
                        _id: result.rows[i].doc._id,
                        _rev: result.rows[i].doc._rev,
                        _deleted: true,
                      })
                      delete result.rows[i].doc._rev
                      result.rows[i].doc.showInRoot = true
                      docToTrash.push(result.rows[i].doc)
                    } else if (action == 'delete') {
                      docToUpdate.push({
                        _id: result.rows[i].doc._id,
                        _rev: result.rows[i].doc._rev,
                        _deleted: true,
                      })
                    }
                  }
                }
              }
              if (docToUpdate.length == 0 && action == 'new') {
                docToUpdate.push(folderObjArr[j])
              }
              if (docToUpdate.length > 0) {
                await pouchDbObj.updateInDB(sidebarNodesObj, docToUpdate)
              }
            })
            .catch(function(err) {})
        }
      }
    }
  }

  // isFolderExist(folderId, pageName) {
  //   let folderDB = 'qr_' + pageName + '_folders_' + localStorage.getItem('userId');
  //   return new Promise((resolve, reject) => {
  //     let folderDbObj = new PouchDB(folderDB);
  //     folderDbObj.allDocs({
  //       include_docs: true,
  //     }).then(function (result) {
  //       if(result.rows.length > 0) {
  //         result.rows.map( folderArr => {
  //           if(folderArr.doc.id == folderId ) {
  //             resolve(true);
  //           }
  //         })
  //       } else {
  //         resolve(false);
  //       }
  //     }).catch(function (err) {
  //       resolve(false);
  //     });
  //   });
  // }
  getAllDocumentDataFromPouchDB(): Promise<any[]> {
    let documentDB = 'qr_' + this.currentPage + '_files_' + localStorage.getItem('userId')
    const db = new PouchDB(documentDB);
    return db.allDocs({ include_docs: true })
      .then(result => {
        const docs = result.rows.map(row => row.doc);
        return docs;
      });
  }
}
