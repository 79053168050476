import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { filter } from 'rxjs/operators'
import * as _ from 'lodash'
import { select, Store } from '@ngrx/store'
import { MenuService } from 'src/app/services/menu'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'
import { NzModalService } from 'ng-zorro-antd/modal'
import { CreateFolderComponent } from 'src/app/pages/dashboard/modal-components/create-folder/create-folder.component'
import { GlobalService } from 'src/app/services/common/global.service'
import { ConfirmationDialogComponent } from 'src/app/pages/confirmationDialogModule/confirmation-dialog/confirmation-dialog.component'
import { DataService } from 'src/app/services/data.service'
import { trigger, state, style, animate, transition, group } from '@angular/animations'
import { DocumentService } from 'src/app/services/document.service'
import { PouchDataService } from 'src/app/services/pouch-data.service'
import { FolderService } from 'src/app/services/folder.service'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { AuthService } from 'src/app/services/common/auth.service'
import { NzFormatEmitEvent } from 'ng-zorro-antd/tree'
import { Folder, Node } from 'src/app/Model/document'
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown'
import { RemoveFileComponent } from 'src/app/pages/dashboard/modal-components/remove-file/remove-file.component'
import { TreeComponent, TreeNode, ITreeOptions, TreeModel } from '@circlon/angular-tree-component'
import { CreateWebproofComponent } from 'src/app/pages/dashboard/modal-components/create-webproof/create-webproof.component'
import { UploadService } from 'src/app/services/upload.service'
import { FileUploaderOptions } from 'ng2-file-upload'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'
import { log } from 'console'
import * as bytes from 'bytes'
import { DeleteFileDialogComponent } from 'src/app/pages/delete-file-dialog/delete-file-dialog.component'
// import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'qr-classic-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
})
export class MenuLeftComponent implements OnInit {
  menuColor: string
  isMenuShadow: Boolean
  isMenuUnfixed: Boolean
  isSidebarOpen: Boolean
  isMobileView: Boolean
  leftMenuWidth: Number
  isMenuCollapsed: Boolean
  logo: string
  menuData: any[]
  canUploadToSharedWorkspace: boolean = true
  menuDataActivated: any[]
  role: string
  currentPage: string
  docInfo: Array<any>
  folderObj: Array<any>
  selectedFolderObj: { folderId: number; folderName: string; workspaceId: number }
  @ViewChild('fileInput') fileInput: ElementRef
  userPermission: any = {}
  newNode = []
  nodes = []
  currentNode: Node
  currentTreeNode: NzFormatEmitEvent
  folderIdBeforeLoading = null
  isCustomUrlOwner: boolean
  currentUser: any
  uploader
  src: string
  isCustomUrl: boolean
  customUrlLogo = true
  showSupport = false
  hostName: string = window.location.host
  userSetting: any = {}
  isAuthenticatedDropBox: boolean
  apiUrl = environment.apiUrl
  dynamicDarkLogoNumber: number
  dynamicLogoNumber: number
  darkLogo: string = ''
  darkSrc: string
  trashDocInfo = false
  trashFolderInfo = false
  isUserSetting: number = 0
  isFolderId: number
  $selectedIndex: Observable<string>
  selectIndex: number
  selectedTreeID: any
  isTeamUrl: boolean
  customAppSetting: any
  teamAllowedSpace: boolean
  isReviewer: boolean
  isAuthenticatedYoutube: boolean = false
  isAuthenticatedBox: boolean = false
  totalSpace: any
  settings: any = {}
  currentUrl: string = window.location.origin
  usedSpace: any
  popupFileSize: any
  loginType: string = null
  constructor(
    // public dialog: MatDialog,
    private menuService: MenuService,
    private _folderService: FolderService,
    private store: Store<any>,
    private _docService: DocumentService,
    private router: Router,
    private notification: NzNotificationService,
    private modalService: NzModalService,
    private globalService: GlobalService,
    public _dataService: DataService,
    private _pouchDataService: PouchDataService,
    private _authService: AuthService,
    private nzContextMenuService: NzContextMenuService,
    private _uploadService: UploadService,
  ) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.role
    })
    this.menuService.getMenuData().subscribe(menuData => {
      this.menuData = menuData
    })
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.menuColor = state.menuColor
      this.isMenuShadow = state.isMenuShadow
      this.isMenuUnfixed = state.isMenuUnfixed
      this.isSidebarOpen = state.isSidebarOpen
      this.isMobileView = state.isMobileView
      this.leftMenuWidth = state.leftMenuWidth
      this.isMenuCollapsed = state.isMenuCollapsed
      this.logo = state.logo
      this.settings = state
    })
    this.globalService.canUploadToSharedWorkspace.subscribe(
      canUploadToSharedWorkspace => (this.canUploadToSharedWorkspace = canUploadToSharedWorkspace),
    )
  }

  ngOnInit(): void {
    this._dataService.dropBoxCheck()
    this.activateMenu(this.router.url)
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.activateMenu(event.url ? event.url : null)
      })

    this.globalService.selectedFolderObj.subscribe(
      selectedFolderObj => (this.selectedFolderObj = selectedFolderObj),
    )
    this.globalService.isTeamUrl.subscribe(isTeamUrl => {
      this.isTeamUrl = isTeamUrl
    })

    this.globalService.teamAllowedSpace.subscribe(
      teamAllowedSpace => (this.teamAllowedSpace = teamAllowedSpace),
    )
    this.globalService.isReviewer.subscribe(isReviewer => (this.isReviewer = isReviewer))

    this.globalService.customAppSetting.subscribe(customAppSetting => {
      if (Object.keys(customAppSetting).length > 0) {
        this.customAppSetting = customAppSetting
        this.src = customAppSetting.logo
        this.darkSrc = customAppSetting.darkLogo
        if (customAppSetting.appUrlType != 'default' && customAppSetting.loginType == 'owner') {
          this.loginType == 'owner'
        }
      }
    })

    this.globalService.selectedFolderTreeId.subscribe(
      selectedFolderTreeId => (this.selectedTreeID = selectedFolderTreeId),
    )

    this.globalService.selectedIndex.subscribe(
      selectedIndex => (this.selectIndex = selectedIndex),
      // this.IndexOfTreeView = IndexOfTreeView),
    )

    // this.uploader = this._uploadService.uploader
    // const uo: FileUploaderOptions = {}
    // uo.headers = [{ name: 'Authorization', value: `AUTH ${this._authService.getToken()}` }] /// will changed
    // this.uploader.setOptions(uo)

    // this.nodes = [
    //   {
    //     id: 0,
    //     name: 'My Documents',
    //     hasChildren: true,
    //     isExpanded: false,
    //     lastModified: null,
    //     userName: null,
    //     userId: parseInt(localStorage.getItem('userId')),
    //     star: 0,
    //     parent: 0,
    //     data: [],
    //   },
    // ]
    // this.globalService.currentNode.next(this.nodes[0])

    this.globalService.currentPage.subscribe(currentPage => (this.currentPage = currentPage))
    this.globalService.newNode.subscribe((newNode: Array<Folder>) => {
      // this.appendChildren(newNode)
    })

    this.globalService.userSetting.subscribe(userSetting => {
      if (userSetting && Object.keys(userSetting).length > 0) {
        if (userSetting.user_id) {
          this.isUserSetting = 1
        } else {
          this.isUserSetting = 2
        }
        this.darkLogo =
          userSetting.setting_obj && userSetting.setting_obj.dark_logo
            ? userSetting.setting_obj.dark_logo
            : ''
        this.userSetting = userSetting
        this.generateRandomNumber()
      } else {
        // this.globalService.updateUserSettings.next(true)
      }
    })

    this.globalService.userPermission.subscribe(userPermission => {
      this.userPermission = userPermission
    })

    // const userId = localStorage.getItem('userId')
    // this._authService.getUserPermission().subscribe(servicesData => {
    //   this.userPermission = servicesData
    //   this.globalService.userPermission.next(servicesData)
    // })
    // this.globalService.treeObj.next(this.tree)
    // this.globalService.folderTreeContextMenu.subscribe(resp => {
    //   this.folderTreeContextMenu = resp
    // })

    this.globalService.isCustomUrlOwner.subscribe(isCustomUrlOwner => {
      this.isCustomUrlOwner = isCustomUrlOwner
    })

    this.globalService.isCustomUrl.subscribe(isCustomUrl => {
      this.isCustomUrl = isCustomUrl
    })

    this.globalService.isAuthenticatedDropBox.subscribe(
      isAuthenticatedDropBox => (this.isAuthenticatedDropBox = isAuthenticatedDropBox),
    )
    this.globalService.isAuthenticatedYoutube.subscribe(isAuthenticatedYoutube => {
      this.isAuthenticatedYoutube = isAuthenticatedYoutube
    })

    this.globalService.isAuthenticatedBox.subscribe((isAuthenticatedBox: boolean) => {
      this.isAuthenticatedBox = isAuthenticatedBox
    })
    if (!this.isCustomUrl) {
      this.showSupport = true
      this.customUrlLogo = false
    }

    // if (this.customUrlLogo || this.isTeamUrl) {
    //   this.src = (this.customAppSetting.logo) ? this.customAppSetting.logo : environment.apiUrl + '/logo/0/0/' + this.hostName
    //   this.darkSrc = (this.customAppSetting.darkLogo) ? this.customAppSetting.darkLogo : environment.apiUrl + '/logo-dark/0/0' + this.hostName
    // }

    this.globalService.userInfo.subscribe(userInfo => (this.currentUser = userInfo))
    this.generateRandomNumber()

    this.globalService.trashDocInfo.subscribe(docInfo => {
      this.trashDocInfo = docInfo
    })
    this.globalService.trashFolderInfo.subscribe(folderInfo => {
      this.trashFolderInfo = folderInfo
    })

    this.globalService.totalSpace.subscribe(totalSpace => {
      this.totalSpace = totalSpace
    })

    this.globalService.usedSpace.subscribe(usedSpace => {
      this.usedSpace = usedSpace
    })
  }

  generateRandomNumber() {
    this.dynamicLogoNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1
    this.dynamicDarkLogoNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1
  }

  errorHandler(event) {
    // this.src = (this.customAppSetting.logo) ? this.customAppSetting.logo : environment.apiUrl + '/other-logo/qr'
  }

  errorDarkImageHandler(event) {
    // this.darkSrc = (this.customAppSetting.darkLogo) ? this.customAppSetting.darkLogo : environment.apiUrl + '/other-logo/qr-dark'
  }

  // appendChildren(nodes: Array<Folder>) {
  //   if (!this.nodes.length) {
  //     this.nodes = nodes.map(node => {
  //       const nodeData = Object.assign(node, {}) as Folder
  //       const treeNode = {
  //         id: nodeData.id,
  //         title: nodeData.name,
  //         key: nodeData.id,
  //         children: nodeData.hasChildren ? null : undefined,
  //         expanded: false,
  //         isLeaf: !nodeData.hasChildren ? true : undefined,
  //         parent: nodeData.parent,
  //         isWorkspace: nodeData.isWorkspace,
  //         userId: nodeData.userId,
  //       }
  //       return treeNode
  //     })
  //   }
  // }

  emptyTrash(e): void {
    e.stopPropagation()
    const confirmationDialog = this.modalService.create({
      nzTitle: 'Empty Trash',
      nzContent: ConfirmationDialogComponent,
      nzComponentParams: {
        message:
          'This is an irreversible action. Are you sure you want to delete the documents permanently?',
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null,
    })
    confirmationDialog.afterClose.subscribe(result => {
      if (result) {
        this.emptyFile(e)
      }
    })
  }

  clickOutsideCard() {
    this._dataService.resetAllObservable()
  }

  isWorksapce() {
    if (localStorage.getItem('finder-selected-folder')) {
      this.isFolderId = parseInt(localStorage.getItem('finder-selected-folder'))
    } else {
      this.isFolderId = 0
    }
  }

  emptyFile(e) {
    this.globalService.showLoader.next(true)
    this._docService.emptyTrash().subscribe(
      res => {
        this.globalService.showLoader.next(false)
        this._pouchDataService.emptyFileData('trash')
        if (this.currentPage === 'trash') {
          this.docInfo = []
          this.globalService.docInfo.next(this.docInfo)
        }
        this.globalService.trashDocInfo.next(false)
        this._dataService.storagedocs()
      },
      err => {
        console.log('err', err)
        this.globalService.showLoader.next(false)
      },
    )
    this._folderService.emptyTrash().subscribe(
      res => {
        this.globalService.showLoader.next(false)
        this._pouchDataService.emptyData('trash')
        if (this.currentPage === 'trash') {
          this.folderObj = []
          this.globalService.folderObj.next(this.folderObj)
        }
        this.globalService.trashFolderInfo.next(false)
        this._dataService.storagedocs()
        // this.snackBar.open('Trash emptied.', '', { duration: 3000 });
        this.notification.create('success', null, 'Trash emptied.', { nzPlacement: 'bottomRight' })
      },
      err => {
        this.globalService.showLoader.next(false)
        this.notification.create('error', null, err.error.failed, { nzPlacement: 'bottomRight' })
      },
    )
  }
  activateMenu(url: any, menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData))
    const pathWithSelection = this.getPath({ url: url }, menuData, (entry: any) => entry, 'url')
    if (pathWithSelection) {
      pathWithSelection.pop().selected = true
      _.each(pathWithSelection, (parent: any) => (parent.open = true))
    }
    this.menuDataActivated = menuData.slice()
  }

  getTreePath(treeNode) {
    return new Promise((resolve, reject) => {
      const lineage = []
      // add clicked node as first item
      lineage.push(treeNode.data)

      // grab parent of clicked node
      let parent: any = treeNode.parent

      // loop through parents until the root of the tree is reached

      while (parent !== null) {
        lineage.push(parent.data)
        parent = parent.parent
        if (parent == null) {
          resolve(lineage)
        }
      }
    })
  }

  getPath(
    element: any,
    source: any,
    property: any,
    keyProperty = 'key',
    childrenProperty = 'children',
    path = [],
  ) {
    let found = false
    const getElementChildren = (value: any) => _.get(value, childrenProperty)
    const getElementKey = (value: any) => _.get(value, keyProperty)
    const key = getElementKey(element)
    return (
      _.some(source, (e: any) => {
        if (getElementKey(e) === key) {
          path.push(e)
          return true
        } else {
          return (found = this.getPath(
            element,
            getElementChildren(e),
            property,
            keyProperty,
            childrenProperty,
            path.concat(e),
          ))
        }
      }) &&
      (found || _.map(path, property))
    )
  }

  // async getChildren(node: any) {
  //   this.globalService.currentNode.next(node)
  //   // if (node.data.hasChildren) {
  //   //   if (node.data.children == undefined) {
  //   //     this.getChildrenCount(node.data.id);
  //   //   }
  //   //   else {
  //   //     this.position = node.data.children.length + 1;
  //   //   }

  //   // }
  //   if (node.data.id > 0 && node.data.parent == 0 && node.data.isWorkspace == 1) {
  //     localStorage.setItem('isWorkspace', '1')
  //     localStorage.setItem('workspaceId', node.data.id)
  //     localStorage.setItem('workspaceUserId', node.data.userId)
  //   } else if (node.data.id > 0 && node.data.parent == 0 && node.data.isWorkspace == 0) {
  //     localStorage.removeItem('isWorkspace')
  //     localStorage.removeItem('workspaceId')
  //     localStorage.removeItem('workspaceUserId')
  //   }

  //   // this._pouchDataService.destroyUserNodes();
  //   const isNewNode = await this._pouchDataService
  //     .getUserNodesPouchDb(node.data.id, '')
  //     .then(nodeArr => {
  //       this.globalService.newNode.next(nodeArr)
  //       return this.newNode
  //     })
  //     .catch(err => {
  //       return false
  //     })

  //   if (isNewNode) {
  //     return isNewNode
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       // this._folderService.getChildren({ folderId: node.data.id }).subscribe(res => {
  //       this._folderService.getDeepChildren({ folderId: 0 }).subscribe(
  //         async res => {
  //           if (res) {
  //             this._pouchDataService.setUserNodesPouchDb(res, 'insert')
  //             const nodeObj = await res.filter(function(element) {
  //               return element.parent == node.data.id
  //             })
  //             this.globalService.newNode.next(nodeObj)
  //             resolve(this.newNode)
  //           }
  //         },
  //         err => {},
  //       )
  //     })
  //   }
  // }

  // getChildrenOnSelect(node: any) {
  //   this.globalService.searchItemPathArr.next([])
  //   this.globalService.searchItemName.next(null)
  //   this.globalService.searchFlag.next(false)
  //   if (document.querySelector('#searchText') != null) {
  //     const searchText = <HTMLInputElement>document.querySelector('#searchText')
  //     searchText.value = ''
  //   }
  //   this.globalService.currentNode.next(node)
  //   node.toggleActivated()

  //   if (node.data.id > 0 && node.data.parent == 0 && node.data.isWorkspace == 1) {
  //     localStorage.setItem('isWorkspace', '1')
  //     localStorage.setItem('workspaceId', node.data.id)
  //     localStorage.setItem('workspaceUserId', node.data.userId)
  //   } else if (node.data.id > 0 && node.data.parent == 0 && node.data.isWorkspace == 0) {
  //     localStorage.removeItem('isWorkspace')
  //     localStorage.removeItem('workspaceId')
  //     localStorage.removeItem('workspaceUserId')
  //   }
  //   return new Promise(async (resolve, reject) => {
  //     const workspaceId = node.data.isWorkspace == 1 ? node.data.id : node.data.workspaceId
  //     this.globalService.selectedFolderObj.next({
  //       folderId: node.data.id,
  //       folderName: node.data.name,
  //       workspaceId: workspaceId == undefined ? 0 : workspaceId,
  //     })

  //     const folderCacheData = await this._pouchDataService
  //       .getFoldersFromPouchDb(node.data.id)
  //       .then(folderArrObj => {
  //         this.globalService.folderObj.next(folderArrObj)
  //         return true
  //       })
  //       .catch(err => {
  //         this.globalService.folderObj.next([])
  //         return false
  //       })
  //     const fileCacheData = await this._pouchDataService
  //       .getDocumentsFromPouchDb(node.data.id)
  //       .then(fileArrObj => {
  //         this.globalService.docInfo.next(fileArrObj)
  //         return true
  //       })
  //       .catch(err => {
  //         this.globalService.docInfo.next([])
  //         return false
  //       })
  //     if (folderCacheData || fileCacheData) {
  //       resolve(this.newNode)
  //     } else {
  //       this.folderIdBeforeLoading = this.selectedFolderObj.folderId
  //       this._folderService.getChildren({ folderId: node.data.id }).subscribe(
  //         res => {
  //           // If the user changes the folder before the response comes back from the server, do nothing.
  //           if (res.length > 0) {
  //             this.globalService.folderObj.next(res)
  //           } else {
  //             this.globalService.folderObj.next([])
  //           }
  //           this._pouchDataService.setFoldersInPouchDb(res, node.data.id, 'dashboard')
  //           resolve(this.newNode)
  //         },
  //         err => {},
  //       )
  //       this._docService.activeDocuments({ folderId: node.data.id }).subscribe(
  //         res => {
  //           if (
  //             this.folderIdBeforeLoading &&
  //             this.folderIdBeforeLoading != this.selectedFolderObj.folderId
  //           ) {
  //             this.folderIdBeforeLoading = null
  //             return
  //           }
  //           if (res.length > 0) {
  //             this.globalService.docInfo.next(res)
  //           } else {
  //             this.globalService.docInfo.next([])
  //           }
  //           this._pouchDataService.setDocumentsInPouchDb(res, node.data.id, 'dashboard')
  //         },
  //         err => console.log('err', err),
  //       )
  //     }
  //   })
  // }

  // closeMenu = () => {
  //   this.folderTreeContextMenu = null
  // }

  toggleSettings() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isSidebarOpen: !this.isSidebarOpen,
      }),
    )
  }

  onCollapse(value: any) {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: value,
      }),
    )
  }

  createFolder(type: string, isWorkspace) {
    if (
      ((this.currentPage == 'dashboard' && this.teamAllowedSpace) ||
        (this.currentPage == 'dashboard' && this.selectedFolderObj.folderId > 0)) &&
      !this.isReviewer
    ) {
      const folderId = this.selectedFolderObj.folderId
      const addWorkspaceFolderRef = this.modalService.create({
        nzTitle: type === 'workspace' ? 'New Workspace' : 'New Folder',
        nzContent: CreateFolderComponent,
        nzComponentParams: {
          data: {
            name: type === 'workspace' ? 'Untitled Workspace' : 'Untitled Folder',
            type: type,
            folderId: folderId,
            workspaceId: this.selectedFolderObj.workspaceId,
            isWorkspace: isWorkspace,
          },
        },
        nzMaskClosable: false,
        nzClosable: false,
        nzFooter: null,
        nzWidth: 500,
      })
    } else {
      this._dataService.showAlertMessage(
        'Permission required',
        'file',
        'You do not have permission to create folder here, Please contact your administrator.',
      )
    }
  }

  accessWorkspace(checkMembership) {
    if (
      checkMembership &&
      (this.currentPage !== 'dashboard' ||
        (this.currentPage === 'dashboard' && this.selectedFolderObj.folderId === 0))
    ) {
      return false
    } else {
      return true
    }
  }

  // getSelectedNode(node: NzFormatEmitEvent) {
  //   this.globalService.searchItemPathArr.next([])
  //   this.globalService.searchItemName.next(null)
  //   this.globalService.searchFlag.next(false)
  //   if (document.querySelector('#searchText') != null) {
  //     const searchText = <HTMLInputElement>document.querySelector('#searchText')
  //     searchText.value = ''
  //   }
  //   this.globalService.currentNode.next(node)
  //   // node.toggleActivated()

  //   if (
  //     node.node.origin.id > 0 &&
  //     node.node.origin.parent === 0 &&
  //     node.node.origin.isWorkspace === 1
  //   ) {
  //     localStorage.setItem('isWorkspace', '1')
  //     localStorage.setItem('workspaceId', node.node.origin.id)
  //     localStorage.setItem('workspaceUserId', node.node.origin.userId)
  //   } else if (
  //     node.node.origin.id > 0 &&
  //     node.node.origin.parent === 0 &&
  //     node.node.origin.isWorkspace === 0
  //   ) {
  //     localStorage.removeItem('isWorkspace')
  //     localStorage.removeItem('workspaceId')
  //     localStorage.removeItem('workspaceUserId')
  //   }
  //   return new Promise(async (resolve, reject) => {
  //     const workspaceId =
  //       node.node.origin.isWorkspace === 1 ? node.node.origin.id : node.node.origin.workspaceId
  //     this.globalService.selectedFolderObj.next({
  //       folderId: node.node.origin.id,
  //       folderName: node.node.origin.title,
  //       workspaceId: workspaceId === undefined ? 0 : workspaceId,
  //     })

  //     const folderCacheData = await this._pouchDataService
  //       .getFoldersFromPouchDb(node.node.origin.id)
  //       .then(folderArrObj => {
  //         this.globalService.folderObj.next(folderArrObj)
  //         return true
  //       })
  //       .catch(err => {
  //         this.globalService.folderObj.next([])
  //         return false
  //       })
  //     const fileCacheData = await this._pouchDataService
  //       .getDocumentsFromPouchDb(node.node.origin.id)
  //       .then(fileArrObj => {
  //         this.globalService.docInfo.next(fileArrObj)
  //         return true
  //       })
  //       .catch(err => {
  //         this.globalService.docInfo.next([])
  //         return false
  //       })
  //     if (folderCacheData || fileCacheData) {
  //       resolve(this.nodes)
  //     } else {
  //       let folderIdBeforeLoading = this.selectedFolderObj.folderId
  //       this._folderService.getChildren({ folderId: node.node.origin.id }).subscribe(
  //         res => {
  //           // const folderBCObjArr = []
  //           // folderBCObjArr.push({ folderId: node.node.origin.id, folderName: node.node.origin.title })
  //           // for (const v of res) {
  //           //   folderBCObjArr.push({
  //           //     folderId: v.id,
  //           //     folderName: v.name,
  //           //   })
  //           // }
  //           // this.globalService.folderBCObj.next(folderBCObjArr)
  //           // If the user changes the folder before the response comes back from the server, do nothing.
  //           if (res.length > 0) {
  //             this.globalService.folderObj.next(res)
  //           } else {
  //             this.globalService.folderObj.next([])
  //           }
  //           this._pouchDataService.setFoldersInPouchDb(res, node.node.origin.id, 'dashboard')
  //           resolve(this.nodes)
  //         },
  //         err => {},
  //       )
  //       this._docService.activeDocuments({ folderId: node.node.origin.id }).subscribe(
  //         res => {
  //           if (
  //             folderIdBeforeLoading &&
  //             folderIdBeforeLoading !== this.selectedFolderObj.folderId
  //           ) {
  //             folderIdBeforeLoading = null
  //             return
  //           }
  //           if (res.length > 0) {
  //             this.globalService.docInfo.next(res)
  //           } else {
  //             this.globalService.docInfo.next([])
  //           }
  //           this._pouchDataService.setDocumentsInPouchDb(res, node.node.origin.id, 'dashboard')
  //         },
  //         err => console.log('err', err),
  //       )
  //     }
  //   })
  // }

  // nodeExpanded(node: NzFormatEmitEvent) {
  //   this.globalService.currentNode.next(node)

  //   if (
  //     node.node.origin.id > 0 &&
  //     node.node.origin.parent === 0 &&
  //     node.node.origin.isWorkspace === 1
  //   ) {
  //     localStorage.setItem('isWorkspace', '1')
  //     localStorage.setItem('workspaceId', node.node.origin.id)
  //     localStorage.setItem('workspaceUserId', node.node.origin.userId)
  //   } else if (
  //     node.node.origin.id > 0 &&
  //     node.node.origin.parent === 0 &&
  //     node.node.origin.isWorkspace === 0
  //   ) {
  //     localStorage.removeItem('isWorkspace')
  //     localStorage.removeItem('workspaceId')
  //     localStorage.removeItem('workspaceUserId')
  //   }

  //   // this._pouchDataService.destroyUserNodes();
  //   const isNewNode = this._pouchDataService
  //     .getUserNodesPouchDb(node.node.origin.id, '')
  //     .then((nodeArr: any) => {
  //       const newNode = nodeArr.map(node => {
  //         const nodeData = Object.assign(node, {}) as Folder
  //         const treeNode = {
  //           id: nodeData.id,
  //           title: nodeData.name,
  //           key: nodeData.id,
  //           children: nodeData.hasChildren ? null : undefined,
  //           expanded: false,
  //           isLeaf: !nodeData.hasChildren ? true : undefined,
  //           parent: nodeData.parent,
  //           isWorkspace: nodeData.isWorkspace,
  //           userId: nodeData.userId,
  //         }
  //         return treeNode
  //       })
  //       node.node.addChildren(newNode)
  //       this.globalService.newNode.next(nodeArr)
  //       return true
  //     })
  //     .catch(err => {
  //       return false
  //     })

  //   if (isNewNode) {
  //     return isNewNode
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       this._folderService.getDeepChildren({ folderId: 0 }).subscribe(
  //         res => {
  //           if (res) {
  //             this._pouchDataService.setUserNodesPouchDb(res, 'insert')
  //             const nodeObj = res.filter(function(element) {
  //               return element.parent === node.node.origin.id
  //             })
  //             this.globalService.newNode.next(nodeObj)
  //             resolve(this.nodes)
  //           }
  //         },
  //         err => {},
  //       )
  //     })
  //   }
  // }

  // openContextDialog(
  //   event: NzFormatEmitEvent,
  //   mouseEvent: MouseEvent,
  //   folderMenu: NzDropdownMenuComponent,
  //   isMyDocument,
  // ) {
  //   let node: Node
  //   if (!isMyDocument) {
  //     node = {
  //       expanded: false,
  //       children: null,
  //       id: event.node.origin.id,
  //       isLeaf: event.node.origin.isLeaf,
  //       isWorkspace: event.node.origin.isWorkspace,
  //       key: event.node.origin.id,
  //       parent: event.node.origin.parent,
  //       title: event.node.origin.title,
  //       userId: event.node.origin.userId,
  //     }
  //   } else {
  //     node = {
  //       expanded: false,
  //       children: null,
  //       id: this.myDocument.id,
  //       isLeaf: this.myDocument.isLeaf,
  //       isWorkspace: this.myDocument.isWorkspace,
  //       key: this.myDocument.id,
  //       parent: this.myDocument.parent,
  //       title: this.myDocument.title,
  //       userId: this.myDocument.userId,
  //     }
  //   }

  //   this.currentNode = node
  //   this.currentTreeNode = event
  //   this.nzContextMenuService.create(mouseEvent, folderMenu)
  // }

  addFolder() {
    const addWorkspaceFolderRef = this.modalService.create({
      nzTitle: null,
      nzContent: CreateFolderComponent,
      nzComponentParams: {
        data: {
          name: 'Untitled Folder',
          type: 'folder',
          folderId: this.currentNode.id,
          workspaceId: this.selectedFolderObj.workspaceId,
          isWorkspace: 0,
        },
      },
      nzFooter: null,
      nzWidth: 500,
    })
    addWorkspaceFolderRef.afterClose.subscribe(result => {
      if (result) {
        const treeNode: any = [
          {
            id: result.id,
            title: result.name,
            key: result.id,
            children: result.hasChildren ? null : undefined,
            expanded: false,
            isLeaf: !result.hasChildren ? true : undefined,
            parent: result.parent,
            isWorkspace: result.isWorkspace,
            userId: result.userId,
          },
        ]
        this.currentTreeNode.node.addChildren(treeNode)
        this.currentTreeNode = null
      }
    })
  }

  editFolder() {
    // this._dataService.renameElement(element)
    this.currentTreeNode.node.clearChildren()
  }

  removeFolder() {
    // this._dataService.trashElement(element)
    const sourceNode = this.currentTreeNode.node.origin.parent
    const folderId = this.currentTreeNode.node.origin.id

    const removeWorkspaceFolderRef = this.modalService.create({
      nzTitle: null,
      nzContent: RemoveFileComponent,
      nzComponentParams: {
        data: {
          title: 'Delete File',
          content: `Are you sure you wish to delete this document. When you delete this document, it will be removed from dashboard of all users that have access to this document?`,
        },
      },
    })
    removeWorkspaceFolderRef.afterClose.subscribe(result => {
      if (result) {
        const folderObj = { folderId: [folderId] }
        this._folderService.trashFolder(folderObj).subscribe(
          res => {
            this.currentTreeNode.node.remove()
            this.currentTreeNode.node.clearChildren()
            this.notification.create('success', null, 'Folder trashed.', {
              nzPlacement: 'bottomRight',
            })
          },
          err => {},
        )
      }
    })
  }

  createWebproof() {
    if (
      ((this.currentPage == 'dashboard' && this.teamAllowedSpace) ||
        (this.currentPage == 'dashboard' && this.selectedFolderObj.folderId > 0)) &&
      !this.isReviewer
    ) {
      const currentUserId =
        localStorage.getItem('workspaceUserId') != null &&
        localStorage.getItem('workspaceUserId') != undefined
          ? parseInt(localStorage.getItem('workspaceUserId'))
          : this.currentUser.userId
      const docObj = { userId: currentUserId }
      this._docService.getWeblinkCount(docObj).subscribe(res => {
        const count = res.count
        const limitBreached = res.limitBreached

        if (limitBreached) {
          const removeWorkspaceFolderRef = this.modalService.create({
            nzTitle: 'New Webproof',
            nzFooter: null,
            nzMaskClosable: false,
            nzContent: RemoveFileComponent,
            nzComponentParams: {
              data: {
                title: 'Limit reached',
                content: `You have reached the maximum limit of ${count} websites allowed in your account. Delete trashed websites or the ones in 'My Documents' to free up website slots.`,
              },
            },
          })
        } else {
          const createWebproof = this.modalService.create({
            nzTitle: 'New Webproof',
            nzFooter: null,
            nzContent: CreateWebproofComponent,
            nzMaskClosable: false,
            nzComponentParams: {
              data: {
                value: 'https://',
              },
            },
          })
          // this.dialog.open(CreateWebproofComponent, {
          //   width: '500px',
          //   maxWidth: '95vw',
          //   panelClass: 'webLinks',
          //   disableClose: true,
          //   data: { value: 'http://' }
          // })
        }
      })
    } else {
      this._dataService.showAlertMessage(
        'Permission required',
        'file',
        'You do not have permission to create webproof here, Please contact your administrator.',
      )
    }
  }

  // async addFileToUploadQueue(e) {
  //   let totalFileSize = 0
  //   if (e.length > 0) {
  //     this.globalService.uploadContainer.next('sidebar-file')
  //     this.globalService.uploadFolder.next(this.selectedFolderObj)
  //     for (var i = 0; i < e.length; i++) {
  //       let fileSize = bytes((e[i].size / (1024 * 1024)).toFixed(2))
  //       totalFileSize += fileSize
  //       this.popupFileSize = String(totalFileSize)
  //       let remainingSize = Number(
  //         Number(this.totalSpace.match(/(\d+)/)[0]) - Number(this.usedSpace.match(/(\d+)/)[0]),
  //       )
  //       if (remainingSize >= totalFileSize) {
  //         if (i == e.length - 1) {
  //           await this._uploadService.resumableUpload.addFile(e[i])
  //           await this.globalService.sleep(250)
  //           this.fileInput.nativeElement.value = ''
  //         }
  //       } else {
  //         if (i == e.length - 1) {
  //           this.dialog.open(DeleteFileDialogComponent, {
  //             width: '500px',
  //             maxWidth: '95vw',
  //             disableClose: true,
  //             data: {
  //               title: `Not enough space available`,
  //               content: !this.canUploadToSharedWorkspace
  //                 ? `Not enough space available in shared workspace.`
  //                 : `Cannot upload this file. The file size is ${bytes(this.popupFileSize, {
  //                     unit: 'MB',
  //                     decimalPlaces: 2,
  //                     unitSeparator: ' ',
  //                   })} MB and you have ${bytes(bytes(this.totalSpace) - bytes(this.usedSpace), {
  //                     unit: 'MB',
  //                     decimalPlaces: 0,
  //                     unitSeparator: ' ',
  //                   })} available.`,
  //               cancel: 'off',
  //             },
  //           })
  //         }
  //       }
  //     }
  //   }
  // }

  async addFileToUploadQueue(e) {
    if (e.length > 0) {
      this.globalService.uploadContainer.next('sidebar-file')
      this.globalService.uploadFolder.next(this.selectedFolderObj)
      for (var i = 0; i < e.length; i++) {
        await this._uploadService.resumableUpload.addFile(e[i])
        await this.globalService.sleep(250)
      }
      this.fileInput.nativeElement.value = ''
    }
  }
}
