import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'qr-move-file-folder',
  templateUrl: './move-file-folder.component.html',
  styleUrls: ['./move-file-folder.component.scss']
})
export class MoveFileFolderComponent {
  @Input() data: { title: string; content: string }

  constructor(
    public modal: NzModalRef, // private _folderService: FolderService, // private globalService: GlobalService, // private notification: NzNotificationService, // private dataService: DataService,
  ) {}

  ngOnInit(): void {}

  onDeleteAction(event) {
    if (event.keyCode === 13) {
      this.modal.destroy(true)
    }
  }
}
