<nz-layout > 
  <nz-content *ngIf="showLoginPage">
    <!-- <vb-sidebar></vb-sidebar> -->
    <!-- <vb-support-chat></vb-support-chat> -->
    <div class="authContainer" [ngClass]="{
        'vb__layout__squaredBorders': isSquaredBorders,
        'vb__layout__cardsShadow': isCardShadow,
        'vb__layout__borderless': isBorderless,
        'white': authPagesColor === 'white', 
        'gray': authPagesColor === 'gray'
        
      }"  [style.background-image]="'url(' + backgroundImage + ')'">
      <!-- <div   class="topbar" *ngIf="currentUrl!=='/auth/signup'" [ngClass]="{
        'topbarGray': isGrayTopbar
      }">
        <div class="logoContainer">
          <div class="logo">
            <div *ngIf="!showLeftSpace" class="logo">
               
              <a href="">
                <img  (error)="errorHandler($event)"   src="{{src}}" alt="{{hostName}}"  style="max-width: 100%;max-height: 100%;">
              </a>
           
            </div>
            <div *ngIf="showLeftSpace">
            <img  src="{{icon}}" alt="{{hostName}}"
              style="max-width: 100%;max-height: 100%;">
              </div>
          </div>
        </div>
        <div *ngIf="showLeftSpace">
        <ng-container *ngIf="currentUrl=='/auth/login'">
        <div class="d-none d-sm-block">
          <span class="mr-2">Don't have an account?</span>
          <a routerLink="/auth/register" class="font-size-16 vb__utils__link">
            Sign up
          </a>
        </div>
        </ng-container>
        <ng-container *ngIf="currentUrl=='/auth/register'">
          <div class="d-none d-sm-block">
            <span class="mr-2">Already have an account?</span>
            <a routerLink="/auth/login" class="font-size-16 vb__utils__link">
              Sign in
            </a>
          </div>
        </ng-container>
        </div>
      </div> -->
      <div class="authContent">
        <div 
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')">
          <!-- ToDo if needed in future -->
          <!-- [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')" -->
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
      <!-- <div class="mt-auto pb-5 pt-5">
        <ul class="footerNav list-unstyled d-flex mb-0 flex-wrap justify-content-center">
          <li>
            <a href="javascript: void(0);">Terms of Use</a>
          </li>
          <li class="active list-inline-item">
            <a href="javascript: void(0);">Compliance</a>
          </li>
          <li>
            <a href="javascript: void(0);">Support</a>
          </li>
          <li>
            <a href="javascript: void(0);">Contacts</a>
          </li>
          <li>
            <a href="javascript: void(0);">About</a>
          </li>
          <li>
            <a href="javascript: void(0);">Advertising</a>
          </li>
        </ul>
        <div class="text-center">
          Copyright © {{ date }}
          <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer">
            Sellpixels.com
          </a> |
          <a href="https://www.sellpixels.org/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
      </div> -->
    </div>
  </nz-content>
</nz-layout>
<qr-loader *ngIf="showLoader"></qr-loader>