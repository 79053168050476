import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'qr-subscription-alert',
  templateUrl: './subscription-alert.component.html',
  styleUrls: ['./subscription-alert.component.scss']
})
export class SubscriptionAlertComponent implements OnInit{

  constructor(public modal: NzModalRef){}

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

}
