import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatSort } from '@angular/material/sort'
import { merge, Observable, of as observableOf } from 'rxjs'
import { catchError, map, startWith, switchMap } from 'rxjs/operators'
import { AuthService } from '../../services/common/auth.service'
import { environment } from '../../../environments/environment'
import { GlobalService } from 'src/app/services/common/global.service'
import { CookieService } from 'ngx-cookie-service'
import { Router, ActivatedRoute } from '@angular/router'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { NzTableQueryParams } from 'ng-zorro-antd/table'

@Component({
  selector: 'qr-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements AfterViewInit {
  displayedColumns: string[] = ['display_name', 'email']
  dataSource: any[] = []
  resultsLength = 0
  isRateLimitReached = false
  cookieExpireTime = environment.cookieExpireTime
  currentSort = { column: 'email', sortDirection: 'asc' }
  @ViewChild(MatPaginator, {}) paginator: MatPaginator
  @ViewChild(MatSort, {}) sort: MatSort
  pageIndex = 1
  dropboxToken: any
  isAuthenticated: boolean
  isAuthenticatedDropBox: boolean
  constructor(
    private _authService: AuthService,
    private globalService: GlobalService,
    private _cookieService: CookieService,
    private _router: Router,
  ) {
    this.globalService.isAuthenticatedDropBox.subscribe(
      isAuthenticatedDropBox => (this.isAuthenticatedDropBox = isAuthenticatedDropBox),
    )
  }

  ngAfterViewInit() {
    this.updateTable('')
  }
  filterUser(userName) {
    console.log(userName)
    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))

    this.updateTable(userName)
  }
  updateTable(userName) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          let userObj = {
            user_id: parseInt(localStorage.getItem('userId')),
            sort: !this.currentSort.column ? 'email' : this.currentSort.column,
            page: this.pageIndex,
            order: !this.currentSort.sortDirection ? 'asc' : this.currentSort.sortDirection,
            search: userName,
          }
          return this._authService.getUsersList(userObj)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isRateLimitReached = false
          this.resultsLength = data.count
          return data.rows
        }),
        catchError(() => {
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true
          return observableOf([])
        }),
      )
      .subscribe(data => {
        this.dataSource = data
        console.log(this.dataSource, 'hv')
      })
  }

  getUserAccount(userId) {
    let userObj = {
      user_id: userId,
    }
    this._authService.loginAsUser(userObj).subscribe(
      res => {

        localStorage.removeItem('qrtoken')
        localStorage.removeItem('userId')
        localStorage.removeItem('userEmail')
        localStorage.removeItem('userPassword')
        localStorage.removeItem('dropBoxAccessToken')
        localStorage.removeItem('isSocial')
        this._cookieService.delete('token')

        localStorage.setItem('qrtoken', res.accessToken)
        localStorage.setItem('userEmail', res.userEmail)
        localStorage.setItem('userPassword', res.userPassword)
        localStorage.setItem('userId', res.userId)
        this.globalService.userInfo.next(res)

        this.globalService.userInfo.next(res)
        this.globalService.updateAppSettings.next(true)
        this.globalService.updateUserPermission.next(true)
        this.globalService.updateUserInfo.next(false)
        if (res.otherTokens.dropbox != null) {
          this.dropboxToken = res.otherTokens.dropbox
          localStorage.setItem('dropBoxAccessToken', this.dropboxToken)
          this.globalService.isAuthenticated.next(true)
          this.globalService.isAuthenticatedDropBox.next(true)
          this.isAuthenticated = true
          this.isAuthenticatedDropBox = true
        }

        if (res.view == 'grid') {
          this.globalService.gridView.next(true)
          this.globalService.listView.next(false)
        } else {
          this.globalService.gridView.next(false)
          this.globalService.listView.next(true)
        }
        var d = new Date()
        d.setTime(d.getTime() + this.cookieExpireTime * 24 * 60 * 60 * 1000)
        this._cookieService.set('token', res.accessToken, d)
        // this._router.navigate(['/dashboard/documents']);
        window.location.replace(
          window.location.protocol + '//' + window.location.host + '/#/auth/login',
        )
        // window.open(window.location.protocol + '//' + window.location.host+"/dashboard/documents", '_blank');
      },
      err => console.log('err', err),
    )
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const currentSort = params.sort.filter(sort => sort.value)[0]
    this.pageIndex = params.pageIndex
    if (currentSort) {
      if (this.currentSort.column !== currentSort.key) {
        this.pageIndex = 1
      }
      this.currentSort.column = currentSort.key
      this.currentSort.sortDirection = currentSort.value === 'ascend' ? 'asc' : 'desc'
    }
    this.updateTable('')
  }
}
