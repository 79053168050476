import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as auth0 from 'auth0-js'
import { AuthService as authSer } from './common/auth.service'
import { environment } from '../../environments/environment'
import { GlobalService } from './common/global.service'
import { AuthService } from './common/auth.service'
import { CookieService } from 'ngx-cookie-service'
import { AlertService } from './common/alert.service'
import { LoaderService } from './loader.service'

/*
{
  "domain": "dev-upgcw-0w.us.auth0.com",
  "clientID": "niIX8bBwgYBpuNR9YNc0hldkOBoJd5IS",
  "audience": "https://dev-upgcw-0w.us.auth0.com/api/v2/"
}
*/
@Injectable({
  providedIn: 'root',
})
export class AuthZeroService {
  auth0: auth0.WebAuth
  authOptions: auth0.AuthOptions
  dropboxToken: any
  isAuthenticated: boolean
  isAuthenticatedDropBox: boolean
  cookieExpireTime = environment.cookieExpireTime
  userInfo: any
  onSubmitLoader: boolean = true
  isLoading: boolean = true

  constructor(
    private _router: Router,
    private _auth: authSer,
    private globalService: GlobalService,
    private _cookieService: CookieService,
    private _alertService: AlertService,
    public loaderService: LoaderService,
  ) {
    this.authOptions =
      localStorage.getItem('auth0') != null && localStorage.getItem('auth0') != ''
        ? JSON.parse(localStorage.getItem('auth0'))
        : {
            domain: '',
            clientID: '',
            audience: '',
          }
    this.globalService.isLoading.next(true)
    // this.auth0 = new auth0.WebAuth(this.authOptions)
    this.globalService.isAuthenticatedDropBox.subscribe(
      isAuthenticatedDropBox => (this.isAuthenticatedDropBox = isAuthenticatedDropBox),
    )
    this.globalService.userInfo.subscribe(userInfo => {
      this.userInfo = userInfo
    })
  }

  public authorise(data) {
    this.authOptions = data
    this.auth0 = new auth0.WebAuth(this.authOptions)

    this.auth0.authorize({
      redirectUri: window.location.origin,
      responseType: 'token id_token',
    })
  }

  public authorised() {
    this.auth0.authorize({
      redirectUri: window.location.origin,
      responseType: 'token id_token',
    })
  }

  public makeRandom() {
    let lengthOfCode = 20
    let possible = '1234567890'
    let text = ''
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  public parseAccessToken() {
    this.auth0.parseHash((err, authResult) => {
      let providerId = this.makeRandom()
      let userObj = {
        userName: authResult.idTokenPayload.name,
        userEmail: authResult.idTokenPayload.email,
        provider: 'auth0',
        photoUrl: authResult.idTokenPayload.picture,
        providerId: providerId,
      }
      this._auth.socialLogin(userObj).subscribe(async res => {
        this.globalService.userInfo.next(res)
        localStorage.setItem('qrtoken', res.accessToken)
        localStorage.setItem('userEmail', res.userEmail)
        localStorage.setItem('userPassword', res.userPassword)
        localStorage.setItem('userId', res.userId)
        localStorage.setItem('apiKey',res.apiKey);
        if (res.view == 'grid') {
          this.globalService.gridView.next(true)
          this.globalService.listView.next(false)
        } else {
          this.globalService.gridView.next(false)
          this.globalService.listView.next(true)
        }
        // await this._authService.dropboxAccessToken({}).subscribe(dropboxDetails => {
        //   if (dropboxDetails.dropbox_access_token != null) {
        //     this.dropboxToken = dropboxDetails.dropbox_access_token;
        //     this.globalService.isAuthenticated.next(true);
        //     this.globalService.isAuthenticatedDropBox.next(true);
        //     this.isAuthenticated = true;
        //     this.isAuthenticatedDropBox = true;
        //     this._authService.getProfile().subscribe(res => {
        //       localStorage.setItem('permission', res[0].membership_id);
        //     });
        //   }
        // }, err => console.log('err', err));
        var d = new Date()
        d.setTime(d.getTime() + this.cookieExpireTime * 24 * 60 * 60 * 1000)
        this._cookieService.set('token', res.accessToken, d)
        this.globalService.isLoading.next(false)
        let sId = localStorage.getItem('sharedDocId')
        localStorage.removeItem('sharedDirId')
        this.globalService.sharedDocId.next(parseInt(sId))
        let page = localStorage.getItem('redirectPage')
        localStorage.removeItem('redirectPage')
        if (page && page == 'm') {
          let userId = localStorage.getItem('userId')
          let emailId = localStorage.getItem('userEmail')
          let password = localStorage.getItem('userPassword')
          let membershipId = localStorage.getItem('membershipId')
          localStorage.removeItem('membershipId')
          window.open(
            window.location.origin +
              '/membership/prev.php?user_id=' +
              userId +
              '&email=' +
              emailId +
              '&password=' +
              password +
              '&autopay=true&membership_id=' +
              membershipId,
            '_self',
          )
        } else if (page && (page == 's' || page == 'f' || page == 'd')) {
          this._router.navigate(['/dashboard/shared'])
        } else {
          if (this.globalService.iFramed == true && this.userInfo.membershipId == 1) {
            this._alertService.error('You will need to sign up for a paid plan to use this feature.')
          } else {
            this._router.navigate(['/dashboard/documents'])
          }
        }
      })
    })
  }

  public logout() {
    this.auth0.logout({ returnTo: window.location.origin})
  }
}
