import { DOCUMENT } from '@angular/common'
import { Inject } from '@angular/core'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, ParamMap, Router, RouterOutlet } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { AuthService } from 'src/app/services/common/auth.service'
import { GlobalService } from 'src/app/services/common/global.service'
import * as Reducers from 'src/app/store/reducers'
import { environment } from 'src/environments/environment'
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations'
import * as SettingsActions from 'src/app/store/settings/actions'
import { AuthZeroService as AuthZero } from '../../services/auth-zero.service'
import { OktaService } from 'src/app/services/okta.service'
@Component({
  selector: 'layout-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutAuthComponent implements OnInit {
  currentUrl: string = ''
  isCustomUrl: boolean
  isGrayTopbar: Boolean
  isCardShadow: Boolean
  logo: string
  showLeftSpace: boolean = false
  isSquaredBorders: Boolean
  isBorderless: Boolean
  authPagesColor: String
  routerAnimation: string
  imageShow: boolean
  date = new Date().getFullYear()
  
  hostName: string = window.location.host
  icon: string = 'assets/images/logo/qr-product-logo-black.png'
  src: string = environment.apiUrl + '/logo/0/0/' + window.location.host
  backgroundImage = ''
  showLoader: boolean = false;
  showLoginPage=false
  apiUrls = window.location.origin + '/api/oktalogin'
  constructor(
    private store: Store<any>,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private _authService: AuthService,
    private authZero: AuthZero,
    private _oktaService : OktaService
  ) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.logo = state.logo
      this.isGrayTopbar = state.isGrayTopbar
      this.isCardShadow = state.isCardShadow
      this.isSquaredBorders = state.isSquaredBorders
      this.isBorderless = state.isBorderless
      this.authPagesColor = state.authPagesColor
      this.routerAnimation = state.routerAnimation
    })
  }

  errorHandler(event) {
    this.imageShow = false
    this.src = 'assets/images/logo/qr-product-logo-black.png'
  }
  ngOnInit() {
    this._authService.getAppSettings({ cname: window.location.host }).subscribe(async setting => {
      if (setting.authZero != '' && setting.authZero != null && !this.router.url.includes('auth/profile')) {
        localStorage.setItem('auth0', JSON.stringify(setting.authZero))
        await this.authZero.authorise(setting.authZero)
      }
      else {
        if(setting.okta && setting.okta != '' && setting.okta != null)
        if(setting.okta.type){
            const oktaData = setting.okta;
            oktaData.logo = setting.logo
            this.globalService.OktaInfo.next(oktaData)
            this.router.navigate(
              ['auth/okta'],
              { queryParams: { clientId: oktaData.clientID, baseUrl: oktaData.baseUrl } }
            );
       
        }else{
          this.redirectToLogin()
        }
           
        //  else{
        //    this.router.navigate(['auth/login']);
        //  }
        this.showLoginPage = true
        //localStorage.removeItem('auth0')
      }
    })
    this.currentUrl = this.router.url
    this.router.events.subscribe(resp => {
      this.currentUrl = this.router.url
    })
    this.globalService.isCustomUrl.subscribe(isCustomUrl => {
      this.isCustomUrl = isCustomUrl
    })
    if (!this.isCustomUrl) {
      this.showLeftSpace = true
    }
    this.globalService.customAppSetting.subscribe(data => {
      if(Object.keys(data).length > 0) {
        if(this.router.url != '/auth/signup')
          this.backgroundImage = data.backgroundImage
      }
    })

    // this.globalService.customAppSetting.subscribe(data => {
    //   if(Object.keys(data).length > 0) {
    //     if(this.router.url != '/auth/signup')
    //       this.backgroundImage = data.backgroundImage
    //     const head = this.document.getElementsByTagName('head')[0]
    //     const style = this.document.createElement('link')
    //     style.id = 'custom-css'
    //     style.rel = 'stylesheet'
    //     style.type = 'text/css'
    //     style.href = data.customCss != null ? data.customCss : 'custom-style.css'
    //     // style.href = 'custom-style.css'
    //     const customFavIcon = this.document.getElementById('custom-fav-icon')
    //     if (customFavIcon) {
    //       head.removeChild(customFavIcon)
    //     }
    //     head.appendChild(style)
    //     const favIcon = this.document.createElement('link')
    //     favIcon.rel = 'icon'
    //     favIcon.type = 'image/*'
    //     favIcon.id = 'custom-fav-icon'
    //     favIcon.href = data.favicon
    //     head.appendChild(favIcon)
    //     this.titleService.setTitle(data.title)
    //     this.store.dispatch(
    //       new SettingsActions.SetStateAction({
    //         logo: data.logo,
    //       }),
    //     )
    //   }
    // })
    // this.auth.urlData.subscribe((resp) => {
    //   this.href=resp
    //  console.log(resp)
    // });
    this.globalService.showLoader.subscribe(showLoader => {
      this.showLoader = showLoader
    })
  }

  routeAnimation(outlet: RouterOutlet, animation: string) {
    // const mapAnimation = {
    //   'slide-fadein-up': 'slideFadeinUp',
    //   'slide-fadein-right': 'slideFadeinRight',
    //   fadein: 'zoomFadein',
    //   'zoom-fadein': 'fadein',
    // }
    // if (animation === mapAnimation[this.routerAnimation]) {
    return outlet.isActivated && outlet.activatedRoute.routeConfig.path
    // }
  }
  redirectToLogin() {
    window.location.replace(this.apiUrls);
  }
}
