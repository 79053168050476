import { Component, Input, OnInit } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { FolderService } from 'src/app/services/folder.service'
import { GlobalService } from 'src/app/services/common/global.service'

@Component({
  selector: 'qr-rename-folder',
  templateUrl: './rename-folder.component.html',
  styleUrls: ['./rename-folder.component.scss'],
})
export class RenameFolderComponent implements OnInit {
  @Input() data: {
    name: string
    id: string
    workspace: number
  }
  disableSave = false
  constructor(
    public modal: NzModalRef,
    private _folderService: FolderService,
    private notification: NzNotificationService,
    private globalService: GlobalService,
  ) {}

  ngOnInit(): void {}

  renameFolder() {
    if (!this.data.name?.trim()) {
      return
    }

    if (this.disableSave) {
      return
    }
    this.globalService.showLoader.next(true)
    const folderObj = {
      folderId: this.data.id,
      folderName: this.data.name,
    }
    this.disableSave = true
    this._folderService.rnFolder(folderObj).subscribe(
      res => {
        this.globalService.showLoader.next(false)
        this.globalService.renameNode.next(folderObj)
        this.modal.destroy(this.data.name)
        this.notification.create(
          'success',
          null,
          this.data.workspace ? 'Workspace renamed' : 'Folder renamed.',
          { nzPlacement: 'bottomRight' },
        )
      },
      err => {
        this.modal.destroy(false)
      },
    )
  }
}
