import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AuthService } from 'src/app/services/common/auth.service'
import { GlobalService } from 'src/app/services/common/global.service'
import { ThemeService } from 'src/app/services/theme.service'
import * as Reducers from 'src/app/store/reducers'
import * as SettingsActions from 'src/app/store/settings/actions'

@Component({
  selector: 'qr-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  themePreferenceView: string = 'off'
  switchValue = false
  settings: any = {}
  currentUser: any
  isMobileView: boolean

  constructor(
    private store: Store<any>,
    private _authService: AuthService,
    private themeService: ThemeService,
    private globalService: GlobalService,
  ) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.settings = state
      this.isMobileView = state.isMobileView
      // this.theme = state.theme === 'dark'
      this.switchValue = this.settings.theme === 'default' ? false : true
    })
    this.globalService.userInfo.subscribe(userInfo => {
      this.currentUser = userInfo
      // if (this.currentUser) {
      //   const currentTheme =
      //     this.currentUser.theme == 'default' || this.currentUser.theme == 'light'
      //       ? 'default'
      //       : 'dark'
      //   this.themeService.themeName.next(currentTheme)
      //   this.themePreferenceView = 'off'
      //   this.store.dispatch(
      //     new SettingsActions.SetStateAction({
      //       theme: currentTheme,
      //     }),
      //   )
      //   // this.settings.theme = this.currentUser.theme == 'default' ? 'default' : 'dark'
      // }
    })
  }

  ngOnInit(): void {}

  clickSwitch() {
    const themeName = this.settings.theme === 'default' ? 'dark' : 'default'
    this.switchValue = themeName === 'default' ? false : true
    this.setTheme(themeName)
    localStorage.setItem(`${this.currentUser.userId}-theme`, themeName)
  }

  setTheme(nextTheme) {
    this._authService.setTheme({ theme: nextTheme }).subscribe(
      res => {},
      err => {
        console.log(err)
      },
    )
    this.globalService.themeName.next(nextTheme)
    this.themePreferenceView = 'off'
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        theme: nextTheme,
      }),
    )
  }
}
