import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/services/common/auth.service'
import { GlobalService } from 'src/app/services/common/global.service'
import { DashboardService } from 'src/app/services/dashboard.service'
import { DataService } from 'src/app/services/data.service'
import { ThemeService } from 'src/app/services/theme.service'

@Component({
  selector: 'qr-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
  notification: any = []
  isVisible = false
  constructor(
    private titleService: Title,
    private _dataService: DataService,
    private _authService: AuthService,
    private _themeService: ThemeService,
    private globalService: GlobalService,
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    public dashboardService: DashboardService,
  ) {
    // start script for google analytics
    // end script for google analytics
  }

  ngOnInit(): void {
    let user_id = localStorage.getItem('userId')
    let sendUser = {
      user_id: user_id,
    }
    this.dashboardService.getNotification(sendUser).subscribe((noti: any) => {
      this.notification = noti
      if (this.notification.length) {
        this.isVisible = true
      }
    })
  }
  handleOk(): void {
    this.isVisible = false
  }

  handleCancel(): void {
    this.isVisible = false
  }
  updateNotification() {
    this.isVisible = false
    let latestNotificationId = this.notification[this.notification.length - 1].id
    let notificationObj = { notificationId: latestNotificationId }
    this.dashboardService.sendNotificationId(notificationObj).subscribe((res: any) => {})
  }
}
