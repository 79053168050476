<div class="topbar">
  <!-- <div class="mr-4"> -->
  <!-- </div> -->
  <div class="mr-auto mr-md-1">
    <qr-topbar-search></qr-topbar-search>
  </div>
  <div class="mr-4 d-none d-md-block">
  </div>
  <div class="mr-auto d-xl-block d-none">
  </div>
  <div class="mr-3 d-none d-sm-block ml-auto">
  </div>
  <div class="mr-3 d-none d-sm-block">
    <qr-topbar-actions></qr-topbar-actions>
  </div>
  <div class="mr-3 d-none d-sm-none">
    <qr-topbar-language-switcher></qr-topbar-language-switcher>
  </div>
  <div class="d-sm-block">
    <nz-switch nzTooltipTitle="{{ !isMobileView ? (switchValue ? 'Switch Light Theme' :'Switch Dark Theme') : ''}}"
      nzTooltipPlacement="bottom" nz-tooltip [(ngModel)]="switchValue" (click)="clickSwitch()"></nz-switch>
  </div>
  <div>
    <qr-topbar-user-menu></qr-topbar-user-menu>
  </div>
</div>