import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import * as socketIo from 'socket.io-client'
import { Observable } from 'rxjs'
import { Event } from './model/event';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket;

  constructor() { }

  public initSocket(): void {
    try {
      if (this.socket == undefined) {
        this.socket = socketIo.connect(environment.socUrl, { transports: ['websocket'], path: '/apisock' });
      } else if (this.socket) {
        this.socket.disconnect();
        this.socket = socketIo.connect(environment.socUrl, { transports: ['websocket'], path: '/apisock' });
      }

    } catch (e) {
      // this.socket = socketIo.connect(environment.socUrl, { transports : ['websocket'], path: '/apisock' });
      // //Give socket some time to connect. Otherwise, double connections are made
      // this.sleep(1000);
    }
  }

  public onMessage(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('message', (data: any) => observer.next(data))
    })
  }

  public onShare(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('share', (data: any) => observer.next(data))
    })
  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, () => observer.next())
    })
  }

  public onUpdate(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('update', (data: any) => observer.next(data))
    })
  }

  public onlineUserInfo(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('isOnline', (data: any) => observer.next(data))
    })
  }

  public offlineUserInfo(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('isOffline', (data: any) => observer.next(data))
    })
  }

  public refreshWebLinkCount(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('refreshWebLinkCount', (data: any) => observer.next(data))
    })
  }

}
