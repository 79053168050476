<div class="createFolder">
<!-- <div>
    <h5>
        {{data.type === 'workspace' ? 'New Workspace' : 'New Folder'}}
    </h5>
</div> -->
<p *ngIf="showMsg" style="color :red">Folder already exists.</p>
<div>
    <nz-form-control nzErrorTip="Please input name!">
        <input type="text" nz-input placeholder="Enter new name" id="folderName" (click)="$event.target.select()" [(ngModel)]="data.name" (keyup.enter)="submitName()">
    </nz-form-control>
</div>

<div class="text-right mt-3">
    <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
    <button type="button" class="ant-btn ant-btn-primary" (click)="submitName()" [disabled]="!data.name?.trim() || isDisabled">Create</button>
</div>
</div>