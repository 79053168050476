<div>
  <div class="mb-3">
    <div style="width: 50%;display:  inline-block;">
    </div>
    <div style="width:50%; display:  inline-flex; justify-content: flex-end;">
      <div class="pSwitch ">
        <span [ngClass]="isYearly === false ? 'isBold' : '' "> Monthly </span>
        <nz-switch [ngModel]="isYearly" (click)="switchMembershipPlan()">
        </nz-switch>
        <span [ngClass]="isYearly ? 'isBold' : '' "> Annual </span>
      </div>
    </div>
  </div>
  <div class="planContainer">
    <div class="d-flex flex-wrap mb-3" *ngIf="!isLoader">
      <ng-container *ngIf="isYearly === true">
        <div class="subscrib-plan border" [class.active]="currentMembership.id==plan.id" *ngFor="let plan of plansArr;">
          <h5 class="text-center  text-uppercase">{{plan.title}}</h5>
          <div class="d-flex justify-content-between ">
            <span>Storage</span>
            <span><strong>{{plan.alloted_space + ' ' + plan.space_in | uppercase}}</strong></span>
          </div>
          <div class="d-flex justify-content-between ">
            <span>Plan Duration</span>
            <span><strong>{{plan.days}}&nbsp;<span *ngIf="plan.period === 'Y'">Year</span><span
                  *ngIf="plan.period === 'M'">Month</span><span *ngIf="plan.period === 'D'">Days</span></strong></span>
          </div>
          <div class="d-flex justify-content-between ">
            <span>Total Amount</span>
            <span><strong>${{plan.price}}</strong></span>
          </div>
          <div class="mt-3">
            <button *ngIf="currentMembership.id!==plan.id" nz-button nzType="primary" [value]="plan.id"
              (click)="setmembershipid(plan.id)"
              
              [disabled]="currentMembership.id==plan.id"
              class="text-center w-100">Select </button>
            <div *ngIf="currentMembership.id==plan.id">
              <h6 class="text-center  text-uppercase">Your Current Plan</h6>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isYearly === false">
        <div class="subscrib-plan border" *ngFor="let plan of plansArrMon;">
          <h5 class="text-center  text-uppercase">{{plan.title}}</h5>
          <div class="d-flex justify-content-between ">
            <span>Storage</span>
            <span><strong>{{plan.alloted_space + ' ' + plan.space_in | uppercase}}</strong></span>
          </div>
          <div class="d-flex justify-content-between ">
            <span>Plan Duration</span>
            <span><strong>{{plan.days}}&nbsp;<span *ngIf="plan.period === 'Y'">Year</span><span
                  *ngIf="plan.period === 'M'">Month</span><span *ngIf="plan.period === 'D'">Days</span></strong></span>
          </div>
          <div class="d-flex justify-content-between ">
            <span>Total Amount</span>
            <span><strong>${{plan.price}}</strong></span>
          </div>
          <div class="mt-3">
            <button
              [disabled]="currentMembership.id==plan.id"
              [value]="plan.id" (click)="setmembershipid(plan.id)" nz-button nzType="primary"
              class="text-center w-100">Select</button>
          </div>
        </div>
      </ng-container>
    </div>

    <h1 mat-dialog-title class=" mt-4 mb-3 ml-4" *ngIf="topupsArr.length>0">Add Top ups</h1>
    <div class="d-flex flex-wrap mb-3" *ngIf="!isLoader">
      <div class="subscrib-plan border" [class.active]="topup.IsSelected" *ngFor="let topup of topupsArr;let i=index;">
        <h5 class="text-center  text-uppercase"> {{topup.title}}</h5>
        <div class="d-flex justify-content-between ">
          <span>Details</span>
          <span><strong><span *ngIf="topup.alloted_space >0">{{topup.alloted_space}}</span> &nbsp;{{topup.spaceType ==
              'Weblink' ? 'Weblinks' : topup.spaceType}}</strong></span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Plan Duration</span>
          <span><strong>{{topup.days}}&nbsp;<span *ngIf="topup.period === 'Y'">Year</span><span
                *ngIf="topup.period === 'M'">Month</span><span *ngIf="topup.period === 'D'">Days</span></strong></span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Total Amount</span>
          <span><strong>${{topup.price}}</strong></span>
        </div>
        <div class="mt-3">
          <button *ngIf="!topup.IsSelected" nz-button nzType="primary" [disabled]="currentTopup.includes(topup.id.toString())"
            (click)="setmembershipid('TOP_'+topup.id)" [value]="'TOP_'+topup.id"
            class="text-center w-100">Add</button>
          <div *ngIf="topup.IsSelected">
            <h6 class="text-center  text-uppercase">aready active</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>