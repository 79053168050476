import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { DataService } from '../data.service'
import { DocumentService } from '../document.service'
import { GlobalService } from './global.service'
import { saveAs } from 'file-saver'
import { LoaderService } from '../loader.service'

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  docInfo
  apiUrl: string = environment.apiUrl
  abortArr = []
  documentVersionsArr: any = []
  abortVersionArr = []
  controllerVersion = new AbortController()
  signal: any = this.controllerVersion.signal
  constructor(
    private globalService: GlobalService,
    private _docService: DocumentService,
    private dataService: DataService,
    public loaderService: LoaderService,
  ) {
    this.globalService.docInfo.subscribe(docInfo => (this.docInfo = docInfo))
    this.globalService.documentVersionsArr.subscribe(
      documentVersionsArr => (this.documentVersionsArr = documentVersionsArr),
    )
  }

  async downloadFile(element, field) {
    this.dataService.manageLoader('bar', element.documentId, 1)
    let docObj = {
      userId: element.userId,
      documentId: element.documentId,
      versionId: element.versionId,
    }
    let index = this.docInfo.findIndex(id => id.documentId == element.documentId)
    if (environment.useawss3) {
      this._docService.getDocumentToDownloadS3(docObj).subscribe(async (data: any) => {
        //This is the only reliable cross browser method that I could find which is supported on all browsers
        //And also give some sort of feedback to the user about the download.
        try {
          const response = await fetch(data.url)
          const reader = response.body.getReader()
          const contentLength = +response.headers.get('Content-Length')
          let receivedLength = 0 // received that many bytes at the moment
          let chunks = [] // array of received binary chunks (comprises the body)
          while (true) {
            const { done, value } = await reader.read()
            if (done) break
            chunks.push(value)
            receivedLength += value.length
            this.dataService.manageLoader(
              'bar',
              element.documentId,
              1,
              (receivedLength / contentLength) * 100 + '%',
            )
            if (!this.docInfo[index].cancelDownload) {
              this.dataService.manageLoader('bar', element.documentId, 0, '0%')
              this.globalService.showCancelDownload.next(false)

              if (index > -1) this.docInfo[index].cancelDownload = false
              return
            }
          }
          let blob = new Blob(chunks)
          // Extract filename from header
          // const filename = response.headers.get('content-disposition').split(';').find(n => n.includes('filename=')).replace('filename=', '').replace(/"/g, '').trim();
          // Download the file
          const filename = element.documentFileName
          saveAs(blob, filename)
          this.dataService.manageLoader('both', element.documentId, 0)
          this.globalService.showCancelDownload.next(false)
          this.docInfo[index].cancelDownload = false
        } catch (e) {
          this.dataService.manageLoader('both', element.documentId, 0)
          this.globalService.showCancelDownload.next(false)
          this.docInfo[index].cancelDownload = false
        }
      })
    } else {
      const controller = new AbortController()
      const abortSignal = controller.signal
      this.abortArr.push({ controller: controller, id: element.documentId })
      try {
        var response = await fetch(
          this.apiUrl +
            '/document/doc-download-path/' +
            docObj.userId +
            '/' +
            docObj.documentId +
            '/' +
            docObj.versionId,
          { signal: abortSignal },
        )

        const reader = response.body.getReader()
        const contentLength = +response.headers.get('Content-Length')
        let receivedLength = 0 // received that many bytes at the moment
        let chunks = [] // array of received binary chunks (comprises the body)
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          chunks.push(value)
          receivedLength += value.length
          this.dataService.manageLoader(
            'bar',
            element.documentId,
            1,
            (receivedLength / contentLength) * 100 + '%',
          )
          if (!this.docInfo[index].cancelDownload) {
            this.dataService.manageLoader('bar', element.documentId, 0, '0%')
            this.globalService.showCancelDownload.next(false)
            let index = this.docInfo.findIndex(id => id.documentId == element.documentId)
            if (index > -1) this.docInfo[index].cancelDownload = false
            this.abortArr.splice(index, 1)
            return
          }
        }
        let blob = new Blob(chunks)
        let filename = response.headers.get('Content-Disposition')
        filename = filename.split('filename=')[1].replace(/['"]/g, '')
        if (!filename) filename = element.documentFileName
        // Download the file
        saveAs(blob, filename)
        this.dataService.manageLoader('bar', element.documentId, 0)
        this.globalService.showCancelDownload.next(false)
        this.docInfo[index].cancelDownload = false
      } catch (e) {
        this.dataService.manageLoader('bar', element.documentId, 0)
        this.globalService.showCancelDownload.next(false)
        this.docInfo[index].cancelDownload = false
      }
    }
  }
  downloadElementMulti() {
    this.dataService
      .parseArr()
      .then(async docObjArr => {
        for (var i = 0; i < docObjArr['docIdArr'].length; i++) {
          if (docObjArr['docTypeArr'][i] == 'weblink' || docObjArr['docTypeArr'][i] == 'webproof')
            continue
          let docObj = {
            userId: docObjArr['userIdArr'][i],
            documentId: docObjArr['docIdArr'][i],
            versionId: docObjArr['verIdArr'][i],
            documentTitle: docObjArr['docTitleArr'][i],
          }
          let index = this.docInfo.findIndex(id => id.documentId == docObj.documentId)
          if (index > -1) this.docInfo[index].cancelDownload = true

          this.dataService.manageLoader('both', docObj.documentId, 1)
          if (environment.useawss3) {
            this._docService.getDocumentToDownloadS3(docObj).subscribe(async (data: any) => {
              try {
                //This is the only reliable cross browser method that I could find which is supported on all browsers
                //And also give some sort of feedback to the user about the download.
                const response = await fetch(data.url, { signal: this.signal })
                const reader = response.body.getReader()
                const contentLength = +response.headers.get('Content-Length')
                let receivedLength = 0 // received that many bytes at the moment
                let chunks = [] // array of received binary chunks (comprises the body)
                while (true) {
                  const { done, value } = await reader.read()
                  if (done) break
                  chunks.push(value)
                  receivedLength += value.length
                  this.dataService.manageLoader(
                    'bar',
                    docObj.documentId,
                    1,
                    (receivedLength / contentLength) * 100 + '%',
                  )
                }
                if (!this.docInfo[index].cancelDownload) {
                  this.dataService.manageLoader('bar', docObj.documentId, 0, '0%')
                  this.globalService.showCancelDownload.next(false)
                  let index = this.docInfo.findIndex(id => id.documentId == docObj.documentId)
                  if (index > -1) this.docInfo[index].cancelDownload = false
                  return
                }
                let blob = new Blob(chunks)
                // Extract filename from header
                const filename = response.headers
                  .get('content-disposition')
                  .split(';')
                  .find(n => n.includes('filename='))
                  .replace('filename=', '')
                  .replace(/"/g, '')
                  .trim()
                // Download the file
                saveAs(blob, filename)
                this.dataService.manageLoader('both', docObj.documentId, 0)
                this.docInfo[index].cancelDownload = false
              } catch (e) {
                this.dataService.manageLoader('both', docObj.documentId, 0)
                this.docInfo[index].cancelDownload = false
              }
            })
          } else {
            try {
              const controller = new AbortController()
              const abortSignal = controller.signal
              let abortIndex = this.abortArr.findIndex(id => id.id == docObj.documentId)
              if (abortIndex == -1)
                this.abortArr.push({ controller: controller, id: docObj.documentId })
              console.groupCollapsed(this.signal)
              var response = await fetch(
                this.apiUrl +
                  '/document/doc-download-path/' +
                  docObj.userId +
                  '/' +
                  docObj.documentId +
                  '/' +
                  docObj.versionId,
                { signal: abortSignal },
              )
              const reader = response.body.getReader()
              const contentLength = +response.headers.get('Content-Length')
              let receivedLength = 0 // received that many bytes at the moment
              let chunks = [] // array of received binary chunks (comprises the body)
              while (true) {
                const { done, value } = await reader.read()
                if (done) break
                chunks.push(value)
                receivedLength += value.length
                this.dataService.manageLoader(
                  'bar',
                  docObj.documentId,
                  1,
                  (receivedLength / contentLength) * 100 + '%',
                )
              }
              if (!this.docInfo[index].cancelDownload) {
                this.dataService.manageLoader('bar', docObj.documentId, 0, '0%')
                this.globalService.showCancelDownload.next(false)
                let index = this.docInfo.findIndex(id => id.documentId == docObj.documentId)
                if (index > -1) this.docInfo[index].cancelDownload = false
                return
              }
              let blob = new Blob(chunks)
              let filename = response.headers.get('Content-Disposition')
              filename = filename.split('filename=')[1].replace(/['"]/g, '')
              if (!filename) filename = docObj.documentTitle
              // Download the file
              saveAs(blob, filename)
              this.dataService.manageLoader('bar', docObj.documentId, 0)
              this.docInfo[index].cancelDownload = false
            } catch (e) {
              this.dataService.manageLoader('bar', docObj.documentId, 0)
              this.docInfo[index].cancelDownload = false
            }
          }
        }
      })
      .catch(err => console.log(err))
    this.loaderService.hide()
    this.globalService.menu.next('off')
    this.globalService.moreActions.next('off')
  }
  cancelDownloadF(element, field) {
    let index = this.abortArr.findIndex(id => id.id == element.documentId)
    this.abortArr[index].controller.abort()
    console.groupCollapsed(index)
    this.abortArr.splice(index, 1)
  }
  async downloadElementVesion(element: any) {
    let index = this.documentVersionsArr.findIndex(id => id.version_id == element.version_id)
    // this.globalService.showCancelDownload.next([element.documentId])
    if (index > -1) this.documentVersionsArr[index].cancelDownload = true
    // //TBD we should use this._dataService.downloadElement. But that is creating a circular reference with DataService. So we need to figure out how that would be resolved.
    // let progressElement = <HTMLElement>(
    //   document.querySelector('#versionprogress_' + element.version_id)
    // )
    let docObj = {
      userId: element.created_by,
      documentId: element.document_id,
      versionId: element.version_id,
    }
    // progressElement.style.width = '10%'
    // progressElement.style.display = 'block'
    if (environment.useawss3) {
      this._docService.getDocumentToDownloadS3(docObj).subscribe(async (data: any) => {
        //This is the only reliable cross browser method that I could find which is supported on all browsers
        //And also give some sort of feedback to the user about the download.

        const response = await fetch(data.url)
        const reader = response.body.getReader()
        const contentLength = +response.headers.get('Content-Length')
        let receivedLength = 0 // received that many bytes at the moment
        let chunks = [] // array of received binary chunks (comprises the body)
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          chunks.push(value)
          receivedLength += value.length
          this.documentVersionsArr[index].progressValue = (receivedLength / contentLength) * 100
          if (!this.documentVersionsArr[index].cancelDownload) {
            this.documentVersionsArr[index].progressValue = 0
            this.documentVersionsArr[index].cancelDownload = false
            return
          }
        }
        let blob = new Blob(chunks)
        // Extract filename from header
        const filename = response.headers
          .get('content-disposition')
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .replace(/"/g, '')
          .trim()
        // Download the file
        // saveAs(blob, filename);
        // progressElement.style.display = ''
        this.documentVersionsArr[index].progressValue = 0
        this.documentVersionsArr[index].cancelDownload = false
      })
    } else {
      const controller = new AbortController()
      const abortSignal = controller.signal
      this.abortVersionArr.push({ controller: controller, id: element.version_id })
      var response = await fetch(
        this.apiUrl +
          '/document/doc-download-path/' +
          docObj.userId +
          '/' +
          docObj.documentId +
          '/' +
          docObj.versionId,
        { signal: abortSignal },
      )
      const reader = response.body.getReader()
      const contentLength = +response.headers.get('Content-Length')
      let receivedLength = 0 // received that many bytes at the moment
      let chunks = [] // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        chunks.push(value)
        receivedLength += value.length
        this.documentVersionsArr[index].progressValue = (receivedLength / contentLength) * 100

        if (!this.documentVersionsArr[index].cancelDownload) {
          this.documentVersionsArr[index].progressValue = 0

          this.documentVersionsArr[index].cancelDownload = false
          return
        }
      }
      let blob = new Blob(chunks)
      let filename = response.headers.get('Content-Disposition')
      filename = filename.split('filename=')[1].replace(/['"]/g, '')

      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = filename
      a.click()
      URL.revokeObjectURL(objectUrl)
      this.documentVersionsArr[index].progressValue = 0
      this.documentVersionsArr[index].cancelDownload = false
      //progressElement.style.display = ''
    }
  }

  cancelDownloadVersion(element) {
    // this.documentVersionsArr.map((value, key) => {
    //   this.documentVersionsArr[key].cancelDownload = false
    //   this.documentVersionsArr[key].progressValue = 0
    // })
    // this.controllerVersion.abort()
    // this.controllerVersion = new AbortController();
    // this.signal = this.controllerVersion.signal
    let index = this.abortVersionArr.findIndex(id => id.id == element.version_id)
    this.abortVersionArr[index].controller.abort()
    console.groupCollapsed(index)
    this.abortVersionArr.splice(index, 1)
  }
}
