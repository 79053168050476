import { Component, OnInit, EventEmitter } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { GlobalService } from 'src/app/services/common/global.service'
import { FolderService } from 'src/app/services/folder.service'
import { PouchDataService } from 'src/app/services/pouch-data.service'

@Component({
  selector: 'qr-move-file',
  templateUrl: './move-file.component.html',
  styleUrls: ['./move-file.component.scss'],
})
export class MoveFileComponent implements OnInit {
  moveBtn: boolean = false
  fileElements: any = []
  objectKeys=Object.keys
  canNavigateUp: boolean = false
  path: string
  navigatedDown = new EventEmitter<any>()
  elementMoved = new EventEmitter<{ element: any; moveTo: any }>()
  navigatedUp = new EventEmitter()
  moveFolderId: Number
  hasChildren: boolean = false
  selectedFolderItem: any = null
  selectedFolder
  Obj: any
  curDir: any = false
  selectedFolderObj: any
  selectedDocumentArr: any

  constructor(
    public modal: NzModalRef,
    private _folderService: FolderService,
    private globalService: GlobalService,
    private _pouchDataService: PouchDataService,
  ) {}

  ngOnInit() {
    this.globalService.selectedFolderObj.subscribe(selectedFolderObj => {
      this.selectedFolderObj = selectedFolderObj
    })
    this.globalService.selectedDocumentArr.subscribe(selectedDocumentArr => {
      this.selectedDocumentArr = selectedDocumentArr
    })
    if (this.selectedFolderObj.folderName != 'My Documents') {
      this.getFolderInfo(this.selectedFolderObj.folderId)
        .then((res: any) => {
          res.folder_id = res.folder_id != undefined ? res.folder_id : res.id
          this.fileElements = res
          if (res.isWorkspace == 1) this.canNavigateUp = false
          this._pouchDataService
            .getUserNodesPouchDb(res.folder_id, '')
            .then(async nodeArr => {
              if (nodeArr[0]) {
                this.fileElements = nodeArr
              } else {
                this._folderService.getDeepChildren({ folderId: 0 }).subscribe(async res1 => {
                  if (res1.length > 0) {
                    this._pouchDataService.setUserNodesPouchDb(res1, 'insert')
                    let nodeObj = await res1.filter(function(element) {
                      return element.parent == res.folder_id
                    })
                    this.fileElements = nodeObj.length > 0 ? nodeObj : res
                  } 
                })
              }
            })
            .catch(async err => {
              this._folderService.getDeepChildren({ folderId: 0 }).subscribe(async res1 => {
                if (res1.length > 0) {
                  this._pouchDataService.setUserNodesPouchDb(res1, 'insert')
                  let nodeObj = await res1.filter(function(element) {
                    return element.parent == res.folder_id
                  })
                  this.fileElements = nodeObj.length > 0 ? nodeObj : res
                } else {
                }
              })
            })
        })
        .catch(err => {
          console.log(err)
        })
      this.moveBtn = false
      this.canNavigateUp = true
    } else {
      this._pouchDataService
        .getUserNodesPouchDb(0, '')
        .then(async nodeArr => {
          if (nodeArr[0]) {
            this.fileElements = nodeArr
          } else {
            this._folderService.getDeepChildren({ folderId: 0 }).subscribe(async res => {
              if (res.length > 0) {
                this._pouchDataService.setUserNodesPouchDb(res, 'insert')
                let nodeObj = await res.filter(function(element) {
                  return element.parent == 0
                })
                this.fileElements = nodeObj
              }
            }),
              err => {
                console.log('------------errq', err)
              }
          }
        })
        .catch(async err => {
          this._folderService.getDeepChildren({ folderId: 0 }).subscribe(async res => {
            if (res.length > 0) {
              this._pouchDataService.setUserNodesPouchDb(res, 'insert')
              let nodeObj = await res.filter(function(element) {
                return element.parent == 0
              })
              this.fileElements = nodeObj
            }
          }),
            err => {
              console.log('------------errqq', err)
            }
        })
    }
  }

  navigate(element: any) {
    this.moveBtn = false
    this.canNavigateUp = true
    this._pouchDataService
      .getUserNodesPouchDb(element.id, '')
      .then(async nodeArr => {
        this.path = element.name
        // console.log('------------------nodeArr', nodeArr, element);
        this.getChildren(element.id)
        if (nodeArr[0]) {
          // this.path = element.name;
          // this.getChildren(element.id);
        } else {
          this.curDir = element
          // this.path = element.name;
          this.fileElements = []
          this.canNavigateUp = true
        }
        this.moveBtn = true
        this.moveFolderId = element.id
      })
      .catch(err => {
        this.path = element.name
        this.getChildren(element.id)
        this.curDir = element
        this.fileElements = []
        this.canNavigateUp = true
        this.moveBtn = true
        this.moveFolderId = element.id
        return false
      })
    this.selectedFolderItem = -1
  }

  navigateUp() {
    this.moveBtn = false
    this.selectedFolderItem = -1
    let folderId = 0
    let isLast = false
    if (this.fileElements.length == 0 && this.curDir) {
      folderId = this.curDir.parent == undefined ? this.fileElements.parent_id : this.curDir.parent
      this.curDir = false
      isLast = true
    } else {
      isLast = false
      this.curDir = false
      folderId = Array.isArray(this.fileElements)
        ? this.fileElements[0].parent
        : this.fileElements.folder_id
    }
    if (folderId != 0 && !isLast) {
      this.getFolderInfo(folderId)
        .then((res: any) => {
          this.getChildren(res.parent_id)
          this.moveFolderId = res.parent_id
          this.moveBtn = true
          if (res.workspaceId > 0 && this.selectedDocumentArr[0].workspaceId > 0) {
            this.getFolderInfo(res.parent_id)
              .then((res: any) => {
                if (res.isWorkspace == 1) this.canNavigateUp = false
              })
              .catch(err => {})
          }
        })
        .catch(err => {})
    } else {
      this.getChildren(folderId)
    }
  }

  getFolderInfo(folderId) {
    return new Promise((resolve, reject) => {
      this._pouchDataService
        .getUserNodesPouchDb(folderId, 'detail')
        .then(folderDetails => {
          if (Object.keys(folderDetails).length > 0) {
            folderDetails[0].parent_id = folderDetails[0].parent
            resolve(folderDetails[0])
          } else {
            this._folderService.getFolderInfo({ folderId: folderId }).subscribe(
              res => {
                resolve(res)
              },
              err => {
                reject(err)
              },
            )
          }
        })
        .catch(err => {
          this._folderService.getFolderInfo({ folderId: folderId }).subscribe(
            res => {
              resolve(res)
            },
            err => {
              reject(err)
            },
          )
        })
    })
  }

  moveElement(element: any, moveTo: any) {
    this.elementMoved.emit({ element: element, moveTo: moveTo })
  }

  openMenu(event: MouseEvent, element: any, viewChild: any) {
    event.preventDefault()
    viewChild.openMenu()
  }

  async getChildren(folderId: any) {
    await this._pouchDataService
      .getUserNodesPouchDb(folderId, '')
      .then(async nodeArr => {
        // if (nodeArr[0]) {
        if (Object.keys(nodeArr).length > 0) {
          if (nodeArr[0].parent != 0) {
            await this._pouchDataService
              .getUserNodesPouchDb(nodeArr[0].parent, 'detail')
              .then(async nodeArr1 => {
                // console.log('--------------------nodeArr1',nodeArr1);
                if (nodeArr1[0]) {
                  if (nodeArr1[0].isWorkspace == 1) {
                    this.canNavigateUp = false
                  }
                  this.fileElements = nodeArr
                  this.path = nodeArr1[0].name
                } else {
                  this.getFolderInfo(nodeArr[0].parent)
                    .then((res2: any) => {
                      this.fileElements = nodeArr
                      this.path = res2.folder_name
                      if (res2.isWorkspace == 1) {
                        this.canNavigateUp = false
                      }
                    })
                    .catch(err => {
                      console.log(err)
                    })
                }
              })
              .catch(err => {
                this.getFolderInfo(nodeArr[0].parent)
                  .then((res2: any) => {
                    this.fileElements = nodeArr
                    this.path = res2.folder_name
                    if (res2.isWorkspace == 1) {
                      this.canNavigateUp = false
                    }
                  })
                  .catch(err => {
                    console.log(err)
                  })
              })
          }

          if (nodeArr[0].parent == 0) {
            this.canNavigateUp = false
            this.moveBtn = true
            this.path = 'My Documents'
            this.moveFolderId = 0
            await this._pouchDataService.getUserNodesPouchDb(0, '').then(async nodeArr => {
              this.fileElements = nodeArr
            })
          }
        } else {
          this._folderService.getDeepChildren({ folderId: 0 }).subscribe(
            async res => {
              if (res.length > 0) {
                this._pouchDataService.setUserNodesPouchDb(res, 'insert')
                let nodeObj = await res.filter(function(element) {
                  return element.parent == folderId
                })
                if (Object.keys(nodeArr).length > 0) {
                  if (nodeObj.parent != 0) {
                    this.getFolderInfo(folderId)
                      .then((res2: any) => {
                        this.fileElements = nodeObj
                        this.path = res2.folder_name
                      })
                      .catch(err => {
                        console.log(err)
                      })
                  }
                  if (nodeObj[0].parent == 0) {
                    this.canNavigateUp = false
                    this.moveBtn = true
                    this.path = 'My Documents'
                    this.moveFolderId = 0
                    await this._pouchDataService.getUserNodesPouchDb(0, '').then(async nodeArr => {
                      this.fileElements = nodeArr
                    })
                  }
                }
              }
            },
            err => {},
          )
        }
      })
      .catch(err => {
        this._folderService.getDeepChildren({ folderId: 0 }).subscribe(
          async res => {
            if (res.length > 0) {
              this._pouchDataService.setUserNodesPouchDb(res, 'insert')
              let nodeObj = await res.filter(function(element) {
                return element.parent == folderId
              })
              this.fileElements = nodeObj
              if (nodeObj.length > 0) {
                if (nodeObj[0].parent != 0) {
                  this.getFolderInfo(folderId)
                    .then((res2: any) => {
                      this.path = res2.folder_name
                    })
                    .catch(err => {
                      console.log(err)
                    })
                }
                if (nodeObj[0].parent == 0) {
                  this.canNavigateUp = false
                  this.moveBtn = true
                  this.path = 'My Documents'
                  this.moveFolderId = 0
                  await this._pouchDataService.getUserNodesPouchDb(0, '').then(async nodeArr => {
                    this.fileElements = nodeArr
                  })
                }
              }
            }
          },
          err => {},
        )
      })
  }
  onMoveAction(event) {
    if (event.keyCode === 13) {
      this.modal.destroy(this.moveFolderId)
    }
  }

  onSelect(element: any, i) {
    this.moveBtn = true
    this.moveFolderId = element.id
    if (this.selectedFolderItem == i) {
      this.selectedFolderItem = -1
    } else {
      this.selectedFolderItem = i
    }
  }
}
