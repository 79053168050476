import { Component, OnInit } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { AuthorizedUser } from 'src/app/Model/common-model'
import { StatusList } from 'src/app/Model/status-list'
import { GlobalService } from 'src/app/services/common/global.service'
import { DocumentService } from 'src/app/services/document.service'
import { Document as DocumentRes } from 'src/app/Model/document'
import { ConsoleSqlOutline } from '@ant-design/icons-angular/icons'

@Component({
  selector: 'qr-workflow-dialog',
  templateUrl: './workflow-dialog.component.html',
  styleUrls: ['./workflow-dialog.component.scss'],
})
export class WorkflowDialogComponent implements OnInit {
  customAppSetting: any
  selectedWorkflowStatus: StatusList = null
  workflowStatus: Array<StatusList>
  currentUser: AuthorizedUser
  selectedDocumentArr: Array<DocumentRes>
  currentPage: string
  filterData: string
  docDetail: any
  wf_status_name: any
  docDetailInfo: any

  constructor(
    private globalService: GlobalService,
    private _docService: DocumentService,
    public modal: NzModalRef,
  ) {}

  ngOnInit(): void {
    this.globalService.selectedDocumentArr.subscribe(
      selectedDocumentArr => (this.selectedDocumentArr = selectedDocumentArr),
    )
    this.globalService.userInfo.subscribe(userInfo => {
      this.currentUser = userInfo
    })
    this.globalService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage
    })
    this.globalService.customAppSetting.subscribe(customAppSetting => {
      this.customAppSetting = customAppSetting
    })
    this.globalService.docDetailInfo.subscribe(resp => {
      this.docDetailInfo = resp
      this.wf_status_name = this.docDetailInfo
    })
    if (this.currentPage === 'shared') {
      this.wfStatus(this.selectedDocumentArr[0]['userId'])
    } else {
      this.wfStatus(this.currentUser.userId)
    }
  }

  wfStatus(userId) {
    const ownerId = this.customAppSetting.userId !== undefined ? this.customAppSetting.userId : 0
    this._docService.getWFStatus({ userId: userId, ownerId: ownerId }).subscribe(
      res => {
        this.workflowStatus = res
        this.selectedWorkflowStatus = this.workflowStatus.find(
          resp => resp.status_name == this.wf_status_name,
        )
      },
      err => console.log('err', err),
    )
  }
  onChangeAction(event) {
    if (event.keyCode === 13) {
      this.modal.destroy(this.selectedWorkflowStatus)
    }
  }
}
