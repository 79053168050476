import { Component, OnInit, HostListener, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'

export interface DeleteInteface {
  title: string
  content: string
  cancel: string
}

@Component({
  selector: 'qr-delete-file-dialog',
  templateUrl: './delete-file-dialog.component.html',
  styleUrls: ['./delete-file-dialog.component.css'],
})
export class DeleteFileDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteInteface,
  ) {}

  ngOnInit() {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.dialogRef.close(false)
    }
  }

  ok() {
    this.dialogRef.close({
      ok: true,
    })
  }

  cancel() {
    this.dialogRef.close()
  }
}
