import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { AuthService } from 'src/app/services/common/auth.service'
import { GlobalService } from 'src/app/services/common/global.service'
import { DataService } from 'src/app/services/data.service'
@Component({
  selector: 'qr-topbar-search',
  templateUrl: './topbar-search.component.html',
  styleUrls: ['./topbar-search.component.scss'],
})
export class TopbarSearchComponent implements OnInit {
  @ViewChild('liveSearchInput') liveSearchInput: ElementRef

  showSearch: boolean = false
  searchVal: string = ''
  currentPage: string

  constructor(
    private globalService: GlobalService,
    private _dataService: DataService,
    private _authService: AuthService,
  ) {
    document.addEventListener('keydown', this.handleKeyDown.bind(this), false)
  }

  ngOnInit(): void {
    this.globalService.currentPage.subscribe(currentPage => {
      this.currentPage = currentPage
      if (this.currentPage !== 'dashboard') {
        this.searchVal = ''
      }
    })
  }

  setVisible() {
    this.showSearch = true
    setTimeout(() => {
      this.liveSearchInput.nativeElement.focus()
    }, 100)
  }

  setHidden() {
    this.showSearch = false
  }

  handleKeyDown(event: any) {
    if (this.showSearch) {
      const key = event.keyCode.toString()
      if (key === '27') {
        this.setHidden()
      }
    }
  }

  resetSearch() {
    this.searchVal = ''
    this.onSearch()
  }

  onSearch() {
    this.globalService.showLoader.next(true)
    if (this.searchVal.trim() !== '') {
      this.globalService.searchFlag.next(true)
      this.globalService.folderBCObj.next([])
    } else {
      this.globalService.folderNodes.next([])
      this.globalService.searchFlag.next(false)
      this.globalService.searchItemPathArr.next([])
      this.globalService.folderBCObj.next([])
      this.globalService.searchItemName.next(null)
      this.globalService.showLoader.next(false)
    }
    if (this.currentPage === 'dropbox') {
      this._dataService.searchInDropbox(this.searchVal)
    } else {
      if (this.searchVal === '') {
        if (this.currentPage === 'dashboard') {
          this._dataService.dashboardDocument()
        } else if (this.currentPage === 'shared') {
          this._dataService.sharedWithMe()
        } else if (this.currentPage === 'recent') {
          this._dataService.getRecent()
        } else if (this.currentPage === 'starred') {
          this._dataService.starredDocument()
        } else if (this.currentPage === 'trash') {
          this._dataService.trashDocument()
        }
      } else {
        this._authService
          .search({ searchText: this.searchVal, pageName: this.currentPage })
          .subscribe(
            res => {
              if (this.currentPage == 'youtube') {
                this.globalService.youTubeFileObj.next(res.docInfo)
              }if (this.currentPage == 'box') {
                this.globalService.boxFileObj.next(res.docInfo)
                this.globalService.boxFolderObj.next(res.folderObj)
              } else {
                this.globalService.docInfo.next(res.docInfo)
                this.globalService.folderObj.next(res.folderObj)
                this.globalService.folderNodes.next(res.folderObj)
              }
              this.globalService.showLoader.next(false)
              this._dataService.updateThumbnailForDropbox()
            },
            err => {},
          )
      }
    }
  }
}
