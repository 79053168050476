<!-- <div>
    <h5>
        Rename
    </h5>
</div> -->
<div>
    <nz-form-control nzErrorTip="Please input name!">
        <input  nz-input [value]="data.name" #editFolderName class="mt-3" (keydown)="triggerFunction($event)"  [(ngModel)]="data.name" placeholder="Enter new name" (click)="$event.target.select()">
    </nz-form-control>
</div>
<div class="text-right mt-3">
    <button type="button" class="ant-btn ant-btn-secondary mr-3" (click)="modal.destroy(false)">Cancel</button>
    <button type="button" class="ant-btn ant-btn-primary" (click)="modal.destroy(data.name)" [disabled]="!data.name?.trim()">OK</button>
</div>