<div>
    <div nz-dropdown nzTrigger="click" nzPlacement="bottomRight" class="dropdown" [nzDropdownMenu]="menu">
        <div class="flag">
            <img [src]="mapFlags[language]" [alt]="language" />
            <span class="text-uppercase">{{language}}</span>
        </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="changeLanguage('en-US')">
                <span class="menuIcon">
                    <img [src]="mapFlags.en" alt="English" />
                </span>
                English
            </li>
            <li nz-menu-item (click)="changeLanguage('fr-FR')">
                <span class="menuIcon">
                    <img [src]="mapFlags.fr" alt="English" />
                </span>
                French
            </li>
            <li nz-menu-item (click)="changeLanguage('ru-RU')">
                <span class="menuIcon">
                    <img [src]="mapFlags.ru" alt="English" />
                </span>
                Русский
            </li>
            <li nz-menu-item (click)="changeLanguage('zh-CN')">
                <span class="menuIcon">
                    <img [src]="mapFlags.zh" alt="English" />
                </span>
                简体中文
            </li>
        </ul>
    </nz-dropdown-menu>
</div>