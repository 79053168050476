<div class="ant-modal" style="top: 0;">
  <div class="ant-modal-content">
    <div class="ant-modal-header">
      <div class="ant-modal-title">{{data.title}}</div>
    </div>
    <div class="ant-modal-body">
      <!-- <div mat-dialog-content><p style="white-space: pre-line;">{{data.content}}</p></div> -->
      <p>{{data.content}}</p>
      <!-- <div mat-dialog-actions>
        <button *ngIf="data.cancel!='off'" mat-stroked-button color="warn" [mat-dialog-close]="false" (click)="cancel()">Cancel</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="ok()">OK</button>
      </div> -->
      <div class="text-right mt-3">
        <button type="button" class="ant-btn ant-btn-secondary mr-3" *ngIf="data.cancel!='off'" color="warn" [mat-dialog-close]="false" (click)="cancel()">Cancel</button>
        <button type="button" class="ant-btn ant-btn-primary" [mat-dialog-close]="true" (click)="ok()">OK</button>
      </div>
    </div>
  </div>
</div>
