import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private apiUrl = environment.apiUrl
  private _dashboardUrl = this.apiUrl + '/documents'
  private _notificationUrl = this.apiUrl + '/get-notification'
  private _sendNotificationIdUrl = this.apiUrl + '/set-notification-id'

  constructor(private http: HttpClient) {}

  dashboard(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this._dashboardUrl, httpOptions)
  }

  getNotification(sendUser): Observable<any> {
    return this.http.get<any>(this._notificationUrl, httpOptions)
  }
  sendNotificationId(notificationData): Observable<HttpResponse<any>> {
    return this.http.post<any>(this._sendNotificationIdUrl, notificationData, httpOptions)
  }
}
