import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { FolderActivityLog, FolderDetail } from '../Model/document'
import { GlobalService } from './common/global.service'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}
@Injectable({
  providedIn: 'root',
})
export class FolderService {
  private apiUrl = environment.apiUrl
  private _createFolderUrl = this.apiUrl + '/folder/create'
  private _deleteFolderUrl = this.apiUrl + '/folder/delete'
  private _trashFolderUrl = this.apiUrl + '/folder/trash'
  private _restoreFolderUrl = this.apiUrl + '/folder/restore'
  private _emptyFolderUrl = this.apiUrl + '/folder/empty'
  private _activeFolderUrl = this.apiUrl + '/folders'
  private _recentFolderUrl = this.apiUrl + '/recent-folder'
  private _sharedWithMeFolderUrl = this.apiUrl + '/shared-with-me-folder'
  private _sharedFolderUrl = this.apiUrl + '/shared-folder'
  private _starredFolderUrl = this.apiUrl + '/starred-folder'
  private _trashedFolderUrl = this.apiUrl + '/trashed-folder'
  private _nodeFolderUrl = this.apiUrl + '/folder/getnode'
  private _nodeFolderBoxUrl = this.apiUrl + '/folder/getnode/box'
  private _sharedNodeFolderUrl = this.apiUrl + '/folder/get-shared-node'
  private _mkFolderUrl = this.apiUrl + '/folder/mknode'
  private _mkBoxFolderUrl = this.apiUrl + '/folder/mknode/box'
  private _mvFolderUrl = this.apiUrl + '/folder/mvnode'
  private _rnFolderUrl = this.apiUrl + '/folder/rnnode'
  private _folderDetailUrl = this.apiUrl + '/folder/folder-detail'
  private _folderLogActivityUrl = this.apiUrl + '/folder/log'
  private _folderSharedUser = this.apiUrl + '/folder/shared-user'
  private _updateFolderShareAction = this.apiUrl + '/folder/change-share-action'
  private _updateAllFolderShareAction = this.apiUrl + '/folder/change-folder-share-action'
  private _deleteFolderShare = this.apiUrl + '/folder/delete-share'
  private _addStarFolderUrl = this.apiUrl + '/folder/add-star'
  private _removeStarFolderUrl = this.apiUrl + '/folder/remove-star'
  private _shareFolderUrl = this.apiUrl + '/folder/share'
  private _folderInfoUrl = this.apiUrl + '/folder/get-folder-info'
  private _folderPathUrl = this.apiUrl + '/folder/item-path'
  private _folderTokenUrl = this.apiUrl + '/folder/folder-token'
  private _folderInfoWUrl = this.apiUrl + '/folder/get-folder-info-w'
  private _nodeFolderWUrl = this.apiUrl + '/folder/getnodew'
  private _getWorkspaceOwner = this.apiUrl + '/folder/getWorkspaceOwner'
  private _deepNodeFolderUrl = this.apiUrl + '/folder/getdeepnodes'
  private _folderSettingUrl = this.apiUrl + '/folder/folder-share-setting';
  private _moveFolderUrl = this.apiUrl +"/folder/moveFolder"

  selectedFolderObj: any

  constructor(private http: HttpClient, private _globalService: GlobalService) {
    this._globalService.selectedFolderObj.subscribe(
      selectedFolderObj => (this.selectedFolderObj = selectedFolderObj),
    )
  }

  activeFolder() {
    return this.http.get<any>(this._activeFolderUrl, httpOptions)
  }
  recentFolder(folderObj) {
    return this.http.post<any>(this._recentFolderUrl, folderObj, httpOptions)
  }

  sharedWithMeFolder() {
    return this.http.get<any>(this._sharedWithMeFolderUrl, httpOptions)
  }

  sharedFolder() {
    return this.http.get<any>(this._sharedFolderUrl, httpOptions)
  }

  starredFolder(folderObj) {
    return this.http.post<any>(this._starredFolderUrl, folderObj, httpOptions)
  }

  trashedFolder(folderObj) {
    return this.http.post<any>(this._trashedFolderUrl, folderObj, httpOptions)
  }

  createFolder(folderObj) {
    return this.http.post<any>(this._createFolderUrl, folderObj, httpOptions)
  }

  mkFolder(folderObj) {
    return this.http.post<any>(this._mkFolderUrl, folderObj, httpOptions).toPromise();
  }

  mkBoxFolderUrl(folderObj) {
    return this.http.post<any>(this._mkBoxFolderUrl, folderObj, httpOptions)
  }

  getChildren(folderObj) {
    if (folderObj.folderId > 0) {
      const isWorkspace = parseInt(localStorage.getItem('isWorkspace'))
      folderObj.isWorkspace = isWorkspace === 1 ? 1 : 0
    } else if (this.selectedFolderObj.workspaceUserId == 0) {
      localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }
    return this.http.post<any>(this._nodeFolderUrl, folderObj, httpOptions)
  }
  getChildrenBox(folderObj) {
    if (folderObj.folderId > 0) {
      const isWorkspace = parseInt(localStorage.getItem('isWorkspace'))
      folderObj.isWorkspace = isWorkspace === 1 ? 1 : 0
    } else if (this.selectedFolderObj.workspaceUserId == 0) {
      localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }
    return this.http.get<any>(this._nodeFolderBoxUrl, httpOptions)
  }

  getSharedChildren(folderObj) {
    return this.http.post<any>(this._sharedNodeFolderUrl, folderObj, httpOptions)
  }

  mvFolder(folderObj) {
    return this.http.post<any>(this._mvFolderUrl, folderObj, httpOptions)
  }

  rnFolder(folderObj) {
    return this.http.post<any>(this._rnFolderUrl, folderObj, httpOptions)
  }

  trashFolder(folderObj) {
    return this.http.post<any>(this._trashFolderUrl, folderObj, httpOptions)
  }

  restoreFolder(folderObj) {
    return this.http.post<any>(this._restoreFolderUrl, folderObj, httpOptions)
  }

  emptyTrash() {
    return this.http.delete<any>(this._emptyFolderUrl, httpOptions)
  }

  deleteFolder(folderObj) {
    return this.http.post<any>(this._deleteFolderUrl, folderObj, httpOptions)
  }

  folderDetail(folderObj) {
    if (folderObj.folderId > 0) {
      return this.http.post<FolderDetail>(this._folderDetailUrl, folderObj, httpOptions)
    }
  }

  folderLogActivity(folderObj) {
    return this.http.post<Array<FolderActivityLog>>(
      this._folderLogActivityUrl,
      folderObj,
      httpOptions,
    )
  }

  folderSharedUser(folderObj) {
    return this.http.post<any>(this._folderSharedUser, folderObj, httpOptions)
  }

  updateFolderShareAction(folderObj) {
    return this.http.post<any>(this._updateFolderShareAction, folderObj, httpOptions)
  }

  deleteFolderShare(folderObj) {
    return this.http.post<any>(this._deleteFolderShare, folderObj, httpOptions)
  }

  addStarFolder(folderObj) {
    return this.http.post<any>(this._addStarFolderUrl, folderObj, httpOptions)
  }

  removeStarFolder(folderObj) {
    return this.http.post<any>(this._removeStarFolderUrl, folderObj, httpOptions)
  }

  shareFolder(folderObj) {
    return this.http.post<any>(this._shareFolderUrl, folderObj, httpOptions)
  }

  getFolderInfo(folderObj) {
    return this.http.post<any>(this._folderInfoUrl, folderObj, httpOptions)
  }

  getFolderPath(folderObj) {
    return this.http.post<any>(this._folderPathUrl, folderObj, httpOptions)
  }

  getFolderToken(folderObj) {
    return this.http.post(this._folderTokenUrl, folderObj, httpOptions)
  }
  getFolderInfoW(folderObj) {
    return this.http.post<any>(this._folderInfoWUrl, folderObj, httpOptions)
  }
  getChildrenW(folderObj) {
    return this.http.post<any>(this._nodeFolderWUrl, folderObj, httpOptions)
  }
  getWorkspaceOwner(folderId) {
    return this.http.post<any>(this._getWorkspaceOwner, folderId, httpOptions)
  }

  updateAllFolderShareAction(folderObj) {
    return this.http.post<any>(this._updateAllFolderShareAction, folderObj, httpOptions)
  }

  getDeepChildren(folderObj) {
    if (folderObj.folderId > 0) {
      const isWorkspace = parseInt(localStorage.getItem('isWorkspace'))
      folderObj.isWorkspace = isWorkspace == 1 ? 1 : 0
    } else if (this.selectedFolderObj.workspaceUserId == 0) {
      if (this.selectedFolderObj) localStorage.removeItem('isWorkspace')
      localStorage.removeItem('workspaceId')
      localStorage.removeItem('workspaceUserId')
    }
    return this.http.post<any>(this._deepNodeFolderUrl, folderObj, httpOptions)
  }

  folderShareSetting(folderObj) {
    return this.http.post<any>(this._folderSettingUrl, folderObj, httpOptions)
  }

  setSelectedIndex() {}
  moveFolder(docObj) {
    return this.http.post<any>(this._moveFolderUrl, docObj, httpOptions)
  }
}
