import { Component, Input, OnInit } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { GlobalService } from 'src/app/services/common/global.service'
import { DataService } from 'src/app/services/data.service'
import { FolderService } from 'src/app/services/folder.service'
import $ from 'jquery'

@Component({
  selector: 'qr-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
})
export class CreateFolderComponent implements OnInit {
  @Input() data: {
    name: string
    type: string
    folderId: number
    workspaceId: number
    isWorkspace: number
  }
  isDisabled = false
  showMsg : any = false;
  constructor(
    public modal: NzModalRef,
    private _folderService: FolderService,
    private globalService: GlobalService,
    private notification: NzNotificationService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      $('#folderName').focus()
      $('#folderName').select()
    }, 500)
  }

  async submitName() {
    this.showMsg = false;
    if (!this.data.name) return
    if (this.isDisabled) return
    this.isDisabled = true;
    this.globalService.showLoader.next(true);
    const folderObj = {
      folderId: this.data.folderId,
      folderName: this.data.name,
      isWorkspace: this.data.isWorkspace,
      workspaceId: this.data.workspaceId,
    }

    let res: any = await this._folderService.mkFolder(folderObj);
    if(res.status==false) {
    this.globalService.showLoader.next(false);
      this.showMsg = true
      this.isDisabled =false
      return
    }
    this.modal.destroy(false);
    if (this.data.type === 'workspace') this.globalService.newFolderAdded.next(res);
    else this.globalService.newDocumentAdded.next(res);
    this.globalService.newSelectedFolder.next(res)
    this.modal.destroy(res);
    this.globalService.showLoader.next(false);
    const message = this.data.type === 'workspace' ? 'Workspace Created.' : 'Folder Created.';
    this.notification.create('success', null, message, { nzPlacement: 'bottomRight' });
  }
}
