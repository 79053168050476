import { Component, Input, OnInit } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'

@Component({
  selector: 'qr-rename-file',
  templateUrl: './rename-file.component.html',
  styleUrls: ['./rename-file.component.scss'],
})
export class RenameFileComponent implements OnInit {
  @Input() data: { name: string }

  constructor(public modal: NzModalRef) {}

  ngOnInit(): void {}

  triggerFunction(event) {
    if (event.key === 'Enter') {
      this.modal.destroy(this.data.name)
    }
  }
}
