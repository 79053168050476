import { Component, HostListener, Input, OnInit } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'

@Component({
  selector: 'qr-trash-alert',
  templateUrl: './trash-alert.component.html',
  styleUrls: ['./trash-alert.component.scss'],
})
export class TrashAlertComponent implements OnInit {
  @Input() data: { message: string; type: string }
  constructor(public modal: NzModalRef) {}

  ngOnInit(): void {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.data.type === 'cookie') {
      if (event.keyCode === 13) {
        this.modal.destroy(true)
      }
    }
  }
}
