<div class="qrPSContainer">
  <div class="qrPStatus">
    <div class="qrPStatus-head">
      <div class="title">
        <span class="ps-heading">User List</span>
      </div>
      <ng-container>
        <div fxLayout fxLayoutAlign="center center">
          <input type="text" nz-input (keyup.enter)="filterUser($event.target.value)" placeholder="Filter">
        </div>
      </ng-container>
    </div>
    <div class="qrPStatus-body">
      <div class="example-table-container">
        <nz-table [nzScroll]="{ x: '900px', y: '340px' }" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)" [nzData]="dataSource" [nzFrontPagination]="false"
          [nzPageIndex]="pageIndex" [nzTotal]="resultsLength" [nzPageSize]="10">
          <thead>
            <tr>
              <th nzWidth="40vh">Name</th>
              <th nzWidth="50vh" nzColumnKey="Email" [nzSortFn]="true">Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of dataSource">
              <td>{{ data.display_name }}</td>
              <td (click)="getUserAccount(data.user_id)" style="cursor: pointer;">{{ data.email }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <!-- <mat-paginator [length]="resultsLength" [pageSize]="10"></mat-paginator> -->
    </div>
  </div>
</div>