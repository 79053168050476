import { Injectable, Inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { DOCUMENT } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import * as SettingsActions from 'src/app/store/settings/actions'
import { GlobalService } from './common/global.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  theme = {
    name: 'default',
  }
  favIconRandomNumber = 2
  // themeName: string
  preLoginUrls = ['/auth/signup', '/auth/login', '/auth/forgot-password', '/auth/reset-password', '/auth/register']

  // themeName = new BehaviorSubject(this.theme.name)


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private store: Store<any>,
    private _globalService: GlobalService,
    private router: Router
  ) {
    // this._globalService.themeName.subscribe(themeName => this.themeName = themeName)
  }

  updateTheme(data) {
    const head = this.document.getElementsByTagName('head')[0]
    if(Object.keys(data).length > 0) {
      this.generateRandomNumber()
      const customStyleElement = this.document.getElementById('custom-css')
      if (customStyleElement) {
        head.removeChild(customStyleElement)
      }
      const customFavIcon = this.document.getElementById('custom-fav-icon')
      if (customFavIcon) {
        head.removeChild(customFavIcon)
      }
      const style = this.document.createElement('link')
      style.id = 'custom-css'
      style.rel = 'stylesheet'
      style.type = 'text/css'
      style.href = data.customCss != null ? data.customCss : 'custom-style.css'
      head.appendChild(style)
      const favIcon = this.document.createElement('link')
      favIcon.rel = 'icon'
      favIcon.type = 'image/*'
      favIcon.id = 'custom-fav-icon'
      favIcon.href = data.favicon + '?' + this.favIconRandomNumber
      head.appendChild(favIcon)
      this.titleService.setTitle(data.title)
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          logo: data.logo,
        }),
      )
      if(data.userId > 0) {
        localStorage.getItem(`${data.userId}-theme`)
      }
      let themeName = (data.userId > 0 && localStorage.getItem(`${data.userId}-theme`)!=undefined) ? localStorage.getItem(`${data.userId}-theme`) : data.theme
      this.changeTheme(themeName)
    }
  }
  generateRandomNumber() {
    this.favIconRandomNumber = Math.round(Math.random() * (10 - 1 + 1) + 1)
  }

  changeTheme(theme) {
    theme = (theme == 'dark') ? 'dark' : 'default'
    if(!this.preLoginUrls.includes(this.router.url)) {
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          theme: theme,
        }),
      )
    }
    // theme = this.preLoginUrls.includes(this.router.url) ? 'light' : theme
    // this.themeName = theme
    // this.store.dispatch(
    //   new SettingsActions.SetStateAction({
    //     theme: theme,
    //   }),
    // )
  }
}
