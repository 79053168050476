import { HostListener, Input } from '@angular/core'
import { Component, Inject, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { NzModalRef } from 'ng-zorro-antd/modal'

@Component({
  selector: 'qr-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() message: string
  constructor(
    public modal: NzModalRef, // public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) // @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string },
  {}

  ngOnInit(): void {}

  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   if (event.keyCode === 27) {
  //     this.dialogRef.close(false)
  //   }
  // }
  destroyModal(): void {
    this.modal.destroy(false)
  }
  onDeleteAction(event) {
    if (event.keyCode === 13) {
      this.modal.destroy(true)
    }
  }
}
